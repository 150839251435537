import PropTypes from 'prop-types';
import React from 'react';
import PayeeAccountNumberInput from './PayeeAccountNumberInput';
import PayeeBankAccountNumberInput from './PayeeBankAccountNumberInput';
import PayeeBankRoutingNumberInput from './PayeeBankRoutingNumberInput';
import PayeeTextInput from './PayeeTextInput';
import PayeeTextInputWithCounter from './PayeeTextInputWithCounter';
import StateDropdown from 'react/shared/components/state_dropdown';
import bindAll from 'react/shared/utils/bind_all';

export default class PayeeInputs extends React.Component {
  constructor(props) {
    super(props);
    if (!this.props.formData.attributes) {
      this.props.formData.attributes = this.props.formData;
    }
    this.state = {
      streetAddressFieldCharMax: 30,
      streetAddressOne: '',
      streetAddressTwo: '',
      payee: {
        clientAccountNumber: {
          value: this.props.formData.attributes.clientAccountNumber,
          currentLength: (this.props.formData.attributes.clientAccountNumber || '').length,
          maximumLength: 48,
        },
      },
      wireInstructions: this.props.formData.attributes.specialInstructions || '',
    };
    this.wireInstructionsCharMax = 156;

    bindAll(this);
  }

  updatePayeeClientAccountNumberLengths(e) {
    const newState = Object.assign({}, this.state);
    newState.payee.clientAccountNumber.value = e.target.value;
    newState.payee.clientAccountNumber.currentLength = e.target.value.length;
    return this.setState(newState);
  }

  setStreetAddressOne(e) {
    return this.setState({ streetAddressOne: e.target.value });
  }

  setStreetAddressTwo(e) {
    return this.setState({ streetAddressTwo: e.target.value });
  }

  check() {
    let address = this.props.formData.attributes.address_attributes;
    address = address ? address : {};
    return (
      <div>
        <PayeeTextInputWithCounter
          addError={this.props.addError}
          address={address}
          defaultValue={address.street1}
          label="Address Line 1"
          maxLength={this.state.streetAddressFieldCharMax}
          name="address_attributes.street1"
          onChange={this.setStreetAddressOne}
          parentError={{ address1: true }}
          removeError={this.props.removeError}
          type="payee_address1"
        />
        <PayeeTextInputWithCounter
          address={address}
          defaultValue={address.street2}
          label="Address Line 2"
          maxLength={this.state.streetAddressFieldCharMax}
          name="address_attributes.street2"
          onChange={this.setStreetAddressTwo}
          streetAddress={this.state.streetAddressTwo}
          type="payee_address2"
        />
        <PayeeTextInput
          addError={this.props.addError}
          defaultValue={address.city}
          label="City"
          name="address_attributes.city"
          parentError={{ city: true }}
          removeError={this.props.removeError}
          type="payee_city"
        />
        <div className="new-form__label">
          <label htmlFor="payee_state">State</label>
        </div>
        <div className="new-form__data">
          <StateDropdown
            defaultValue={address.state || ''}
            id="payee_state"
            name="address_attributes.state"
          />
        </div>
        <PayeeTextInput
          addError={this.props.addError}
          defaultValue={address.zip}
          helperText="Format #####-#### Accepted"
          label="Zip"
          maxLength={10}
          name="address_attributes.zip"
          parentError={{ zip: true }}
          removeError={this.props.removeError}
          type="payee_zip"
        />
        <PayeeAccountNumberInput
          addError={this.props.addError}
          label="Memo"
          lengths={this.state.payee.clientAccountNumber}
          maxLength={this.state.payee.clientAccountNumber.maximumLength}
          name="clientAccountNumber"
          onChange={this.updatePayeeClientAccountNumberLengths}
          parentError={{ accountNumber: true }}
          removeError={this.props.removeError}
          type="payee_client_account_number"
          value={this.state.payee.clientAccountNumber.value}
        />
      </div>
    );
  }

  externalCheck() {
    let address = this.props.formData.attributes.address_attributes;
    address = address ? address : {};
    return (
      <div>
        <PayeeTextInputWithCounter
          addError={this.props.addError}
          address={address}
          defaultValue={address.street1}
          label="Address Line 1"
          maxLength={this.state.streetAddressFieldCharMax}
          name="address_attributes.street1"
          onChange={this.setStreetAddressOne}
          parentError={{ address1: true }}
          removeError={this.props.removeError}
          streetAddress={this.state.streetAddressOne}
          type="payee_address1"
        />
        <PayeeTextInputWithCounter
          address={address}
          defaultValue={address.street2}
          label="Address Line 2"
          maxLength={this.state.streetAddressFieldCharMax}
          name="address_attributes.street2"
          onChange={this.setStreetAddressTwo}
          streetAddress={this.state.streetAddressTwo}
          type="payee_address2"
        />
        <PayeeTextInput
          addError={this.props.addError}
          defaultValue={address.city}
          label="City"
          name="address_attributes.city"
          parentError={{ city: true }}
          removeError={this.props.removeError}
          type="payee_city"
        />
        <div className="new-form__label">
          <label htmlFor="payee_state">State</label>
        </div>
        <div className="new-form__data">
          <StateDropdown
            defaultValue={address.state || ''}
            id="payee_state"
            name="address_attributes.state"
          />
        </div>
        <PayeeTextInput
          addError={this.props.addError}
          defaultValue={address.zip}
          helperText="Format #####-#### Accepted"
          label="Zip"
          maxLength={10}
          name="address_attributes.zip"
          parentError={{ zip: true }}
          removeError={this.props.removeError}
          type="payee_zip"
        />
        <PayeeAccountNumberInput
          addError={this.props.addError}
          label="Memo"
          lengths={this.state.payee.clientAccountNumber}
          maxLength={this.state.payee.clientAccountNumber.maximumLength}
          name="clientAccountNumber"
          onChange={this.updatePayeeClientAccountNumberLengths}
          parentError={{ accountNumber: true }}
          removeError={this.props.removeError}
          type="payee_client_account_number"
          value={this.state.payee.clientAccountNumber.value}
        />
        <PayeeTextInput
          addError={this.props.addError}
          defaultValue={this.props.formData.attributes.disposition}
          label="Disposition"
          name="disposition"
          parentError={{ disposition: true }}
          removeError={this.props.removeError}
          type="payee_disposition"
        />
        <PayeeTextInput
          defaultValue={this.props.formData.attributes.specialInstructions}
          label="Special Instructions"
          name="specialInstructions"
          type="payee_special_instructions"
        />
      </div>
    );
  }

  eft() {
    return (
      <div>
        <PayeeBankRoutingNumberInput
          addError={this.props.addError}
          defaultValue={this.props.formData.attributes.bankRoutingNumber}
          label="Routing Number"
          maxLength={9}
          name="bankRoutingNumber"
          parentError={{ bank_routing_number: true }}
          removeError={this.props.removeError}
          type="payee_bank_routing_number"
        />
        <PayeeBankAccountNumberInput
          addError={this.props.addError}
          defaultValue={this.props.formData.attributes.bankAccountNumber}
          label="Receiving Bank Account Number"
          maxLegnth={17}
          name="bankAccountNumber"
          onCopy={this.noCopyPaste}
          parentError={{ bank_account_number: true }}
          removeError={this.props.removeError}
          type="payee_bank_account_number"
        />
        <PayeeAccountNumberInput
          addError={this.props.addError}
          label="Memo"
          lengths={this.state.payee.clientAccountNumber}
          maxLength={this.state.payee.clientAccountNumber.maximumLength}
          name="clientAccountNumber"
          onChange={this.updatePayeeClientAccountNumberLengths}
          parentError={{ accountNumber: true }}
          removeError={this.props.removeError}
          type="payee_client_account_number"
          value={this.state.payee.clientAccountNumber.value}
        />
      </div>
    );
  }

  card() {
    return <div>{this.client_account_number()}</div>;
  }

  wire() {
    let address = this.props.formData.attributes.address_attributes;
    address = address ? address : {};
    return (
      <div>
        <PayeeBankRoutingNumberInput
          addError={this.props.addError}
          defaultValue={this.props.formData.attributes.bankRoutingNumber}
          label="Receiving Bank Routing Number"
          maxLength={9}
          name="bankRoutingNumber"
          parentError={{ bank_routing_number: true }}
          removeError={this.props.removeError}
          type="payee_bank_routing_number"
        />
        <PayeeBankAccountNumberInput
          addError={this.props.addError}
          defaultValue={this.props.formData.attributes.bankAccountNumber}
          label="Receiving Bank Account Number"
          maxLength={17}
          name="bankAccountNumber"
          onCopy={this.noCopyPaste}
          parentError={{ bank_account_number: true }}
          removeError={this.props.removeError}
          type="payee_bank_account_number"
        />
        <PayeeTextInputWithCounter
          addError={this.props.addError}
          address={address}
          defaultValue={address.street1}
          label="Address Line 1"
          maxLength={this.state.streetAddressFieldCharMax}
          name="address_attributes.street1"
          onChange={this.setStreetAddressOne}
          parentError={{ address1: true }}
          removeError={this.props.removeError}
          streetAddress={this.state.streetAddressOne}
          type="payee_address1"
        />
        <PayeeTextInputWithCounter
          address={address}
          defaultValue={address.street2}
          label="Address Line 2"
          maxLength={this.state.streetAddressFieldCharMax}
          name="address_attributes.street2"
          onChange={this.setStreetAddressTwo}
          streetAddress={this.state.streetAddressTwo}
          type="payee_address2"
        />
        <PayeeTextInput
          addError={this.props.addError}
          defaultValue={address.city}
          label="City"
          maxLength={35}
          name="address_attributes.city"
          parentError={{ city: true }}
          removeError={this.props.removeError}
          type="payee_city"
        />
        <div>
          <div className="new-form__label">
            <label htmlFor="payee_state">Payee State</label>
          </div>
          <div className="new-form__data">
            <StateDropdown
              allowNone
              defaultValue={address.state || ''}
              id="payee_state"
              name="address_attributes.state"
            />
          </div>
        </div>
        <PayeeTextInput
          addError={this.props.addError}
          defaultValue={address.zip}
          helperText="Format #####-#### Accepted"
          label="Zip"
          maxLength={10}
          name="address_attributes.zip"
          parentError={{ zip: true }}
          removeError={this.props.removeError}
          type="payee_zip"
        />
        <PayeeTextInputWithCounter
          addError={this.props.addError}
          defaultValue={this.props.formData.attributes.swiftCode}
          label="SWIFT Code"
          maxLength={11}
          minLength={8}
          name="swiftCode"
          parentError={{ swift_code: true }}
          removeError={this.props.removeError}
          requiredToSubmit={false}
          type="payee_swift_code"
        />
        <div>
          <div className="new-form__label">
            <label htmlFor="payee_special_instructions">Wire Instructions</label>
          </div>
          <div className="new-form__data">
            <textarea
              defaultValue={this.props.formData.attributes.specialInstructions}
              id="payee_special_instructions"
              maxLength={this.wireInstructionsCharMax}
              name="specialInstructions"
              onChange={this.setCounter}
            />
            {this.counter()}
          </div>
        </div>
      </div>
    );
  }

  client_account_number() {
    return (
      <div className="">
        <div className="new-form__label">
          <label htmlFor="payee_client_account_number">Memo</label>
        </div>
        <div className="new-form__data">
          <input
            defaultValue={this.props.formData.attributes.clientAccountNumber}
            id="payee_client_account_number"
            name="clientAccountNumber"
            type="text"
          />
        </div>
      </div>
    );
  }

  counter() {
    const wireInstructions = this.state.wireInstructions;
    const charsLeft = this.wireInstructionsCharMax - wireInstructions.length;
    const almostDone = Math.ceil(this.wireInstructionsCharMax / 25);

    return (
      <span className={charsLeft < almostDone ? 'red' : null} style={{ marginLeft: '10px' }}>
        {charsLeft} characters left
      </span>
    );
  }

  setCounter(e) {
    return this.setState({ wireInstructions: e.target.value });
  }

  render() {
    switch (this.props.paymentMethod) {
      case 'Check':
        return this.check();

      case 'EFT':
        return this.eft();

      case 'Card':
        return this.card();

      case 'External Check':
        return this.externalCheck();

      case 'Wire':
        return this.wire();

      default:
        return (
          <div className="">
            <span style={{ fontStyle: 'italic' }}>Please choose a payment type above</span>
          </div>
        );
    }
  }
}

PayeeInputs.propTypes = {
  formData: PropTypes.object,
  paymentMethod: PropTypes.string,
  addError: PropTypes.func,
  removeError: PropTypes.func,
};
