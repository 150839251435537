import PropTypes from 'prop-types';
import React from 'react';
import DepositList from 'react/shared/client/components/deposits/DepositList';

export default function BeneficiaryDeposits({ trustBeneficiaryId }) {
  return (
    <div>
      <div className="sub_section_header">
        <div style={{ float: 'left' }}>Deposits</div>
        <div style={{ clear: 'both' }} />
      </div>
      <DepositList trustBeneficiaryId={trustBeneficiaryId} />
    </div>
  );
}

BeneficiaryDeposits.propTypes = {
  trustBeneficiaryId: PropTypes.string.isRequired,
};
