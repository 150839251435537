/**
 * File generated by js-routes 2.2.0
 * Based on Rails 7.0.8.5 routes of Truelink::Application
 */
const __jsr = ((that) => {
    const hasProp = (value, key) => Object.prototype.hasOwnProperty.call(value, key);
    let NodeTypes;
    (function (NodeTypes) {
        NodeTypes[NodeTypes["GROUP"] = 1] = "GROUP";
        NodeTypes[NodeTypes["CAT"] = 2] = "CAT";
        NodeTypes[NodeTypes["SYMBOL"] = 3] = "SYMBOL";
        NodeTypes[NodeTypes["OR"] = 4] = "OR";
        NodeTypes[NodeTypes["STAR"] = 5] = "STAR";
        NodeTypes[NodeTypes["LITERAL"] = 6] = "LITERAL";
        NodeTypes[NodeTypes["SLASH"] = 7] = "SLASH";
        NodeTypes[NodeTypes["DOT"] = 8] = "DOT";
    })(NodeTypes || (NodeTypes = {}));
    const Root = that;
    const isBroswer = typeof window !== "undefined";
    const ModuleReferences = {
        CJS: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                module.exports = routes;
            },
            isSupported() {
                return typeof module === "object";
            },
        },
        AMD: {
            define(routes) {
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                define([], function () {
                    return routes;
                });
            },
            isSupported() {
                return typeof define === "function" && !!define.amd;
            },
        },
        UMD: {
            define(routes) {
                if (ModuleReferences.AMD.isSupported()) {
                    ModuleReferences.AMD.define(routes);
                }
                else {
                    if (ModuleReferences.CJS.isSupported()) {
                        try {
                            ModuleReferences.CJS.define(routes);
                        }
                        catch (error) {
                            if (error.name !== "TypeError")
                                throw error;
                        }
                    }
                }
            },
            isSupported() {
                return (ModuleReferences.AMD.isSupported() ||
                    ModuleReferences.CJS.isSupported());
            },
        },
        ESM: {
            define() {
                // Module can only be defined using ruby code generation
            },
            isSupported() {
                // Its impossible to check if "export" keyword is supported
                return true;
            },
        },
        NIL: {
            define(routes) {
                Utils.namespace(Root, null, routes);
            },
            isSupported() {
                return !null || !!Root;
            },
        },
        DTS: {
            // Acts the same as ESM
            define(routes) {
                ModuleReferences.ESM.define(routes);
            },
            isSupported() {
                return ModuleReferences.ESM.isSupported();
            },
        },
    };
    class ParametersMissing extends Error {
        constructor(...keys) {
            super(`Route missing required keys: ${keys.join(", ")}`);
            this.keys = keys;
            Object.setPrototypeOf(this, Object.getPrototypeOf(this));
            this.name = ParametersMissing.name;
        }
    }
    const UriEncoderSegmentRegex = /[^a-zA-Z0-9\-._~!$&'()*+,;=:@]/g;
    const ReservedOptions = [
        "anchor",
        "trailing_slash",
        "subdomain",
        "host",
        "port",
        "protocol",
    ];
    class UtilsClass {
        constructor() {
            this.configuration = {
                prefix: "",
                default_url_options: {},
                special_options_key: "_options",
                serializer: null || this.default_serializer.bind(this),
            };
        }
        default_serializer(value, prefix) {
            if (this.is_nullable(value)) {
                return "";
            }
            if (!prefix && !this.is_object(value)) {
                throw new Error("Url parameters should be a javascript hash");
            }
            prefix = prefix || "";
            const result = [];
            if (this.is_array(value)) {
                for (const element of value) {
                    result.push(this.default_serializer(element, prefix + "[]"));
                }
            }
            else if (this.is_object(value)) {
                for (let key in value) {
                    if (!hasProp(value, key))
                        continue;
                    let prop = value[key];
                    if (this.is_nullable(prop) && prefix) {
                        prop = "";
                    }
                    if (this.is_not_nullable(prop)) {
                        if (prefix) {
                            key = prefix + "[" + key + "]";
                        }
                        result.push(this.default_serializer(prop, key));
                    }
                }
            }
            else {
                if (this.is_not_nullable(value)) {
                    result.push(encodeURIComponent(prefix) + "=" + encodeURIComponent("" + value));
                }
            }
            return result.join("&");
        }
        serialize(object) {
            return this.configuration.serializer(object);
        }
        extract_options(number_of_params, args) {
            const last_el = args[args.length - 1];
            if ((args.length > number_of_params && last_el === 0) ||
                (this.is_object(last_el) &&
                    !this.looks_like_serialized_model(last_el))) {
                if (this.is_object(last_el)) {
                    delete last_el[this.configuration.special_options_key];
                }
                return {
                    args: args.slice(0, args.length - 1),
                    options: last_el,
                };
            }
            else {
                return { args, options: {} };
            }
        }
        looks_like_serialized_model(object) {
            return (this.is_object(object) &&
                !(this.configuration.special_options_key in object) &&
                ("id" in object || "to_param" in object || "toParam" in object));
        }
        path_identifier(object) {
            const result = this.unwrap_path_identifier(object);
            return this.is_nullable(result) || result === false ? "" : "" + result;
        }
        unwrap_path_identifier(object) {
            let result = object;
            if (!this.is_object(object)) {
                return object;
            }
            if ("to_param" in object) {
                result = object.to_param;
            }
            else if ("toParam" in object) {
                result = object.toParam;
            }
            else if ("id" in object) {
                result = object.id;
            }
            else {
                result = object;
            }
            return this.is_callable(result) ? result.call(object) : result;
        }
        partition_parameters(parts, required_params, default_options, call_arguments) {
            // eslint-disable-next-line prefer-const
            let { args, options } = this.extract_options(parts.length, call_arguments);
            if (args.length > parts.length) {
                throw new Error("Too many parameters provided for path");
            }
            let use_all_parts = args.length > required_params.length;
            const parts_options = {
                ...this.configuration.default_url_options,
            };
            for (const key in options) {
                const value = options[key];
                if (!hasProp(options, key))
                    continue;
                use_all_parts = true;
                if (parts.includes(key)) {
                    parts_options[key] = value;
                }
            }
            options = {
                ...this.configuration.default_url_options,
                ...default_options,
                ...options,
            };
            const keyword_parameters = {};
            const query_parameters = {};
            for (const key in options) {
                if (!hasProp(options, key))
                    continue;
                const value = options[key];
                if (this.is_reserved_option(key)) {
                    keyword_parameters[key] = value;
                }
                else {
                    if (!this.is_nullable(value) &&
                        (value !== default_options[key] || required_params.includes(key))) {
                        query_parameters[key] = value;
                    }
                }
            }
            const route_parts = use_all_parts ? parts : required_params;
            let i = 0;
            for (const part of route_parts) {
                if (i < args.length) {
                    const value = args[i];
                    if (!hasProp(parts_options, part)) {
                        query_parameters[part] = value;
                        ++i;
                    }
                }
            }
            return { keyword_parameters, query_parameters };
        }
        build_route(parts, required_params, default_options, route, absolute, args) {
            const { keyword_parameters, query_parameters, } = this.partition_parameters(parts, required_params, default_options, args);
            const missing_params = required_params.filter((param) => !hasProp(query_parameters, param) ||
                this.is_nullable(query_parameters[param]));
            if (missing_params.length) {
                throw new ParametersMissing(...missing_params);
            }
            let result = this.get_prefix() + this.visit(route, query_parameters);
            if (keyword_parameters.trailing_slash) {
                result = result.replace(/(.*?)[/]?$/, "$1/");
            }
            const url_params = this.serialize(query_parameters);
            if (url_params.length) {
                result += "?" + url_params;
            }
            result += keyword_parameters.anchor
                ? "#" + keyword_parameters.anchor
                : "";
            if (absolute) {
                result = this.route_url(keyword_parameters) + result;
            }
            return result;
        }
        visit(route, parameters, optional = false) {
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return this.visit(route[1], parameters, true);
                case NodeTypes.CAT:
                    return this.visit_cat(route, parameters, optional);
                case NodeTypes.SYMBOL:
                    return this.visit_symbol(route, parameters, optional);
                case NodeTypes.STAR:
                    return this.visit_globbing(route[1], parameters, true);
                case NodeTypes.LITERAL:
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        is_not_nullable(object) {
            return !this.is_nullable(object);
        }
        is_nullable(object) {
            return object === undefined || object === null;
        }
        visit_cat(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, left, right], parameters, optional) {
            const left_part = this.visit(left, parameters, optional);
            let right_part = this.visit(right, parameters, optional);
            if (optional &&
                ((this.is_optional_node(left[0]) && !left_part) ||
                    (this.is_optional_node(right[0]) && !right_part))) {
                return "";
            }
            // if left_part ends on '/' and right_part starts on '/'
            if (left_part[left_part.length - 1] === "/" && right_part[0] === "/") {
                // strip slash from right_part
                // to prevent double slash
                right_part = right_part.substring(1);
            }
            return left_part + right_part;
        }
        visit_symbol(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        [_type, key], parameters, optional) {
            const value = this.path_identifier(parameters[key]);
            delete parameters[key];
            if (value.length) {
                return this.encode_segment(value);
            }
            if (optional) {
                return "";
            }
            else {
                throw new ParametersMissing(key);
            }
        }
        encode_segment(segment) {
            return segment.replace(UriEncoderSegmentRegex, (str) => encodeURIComponent(str));
        }
        is_optional_node(node) {
            return [NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT].includes(node);
        }
        build_path_spec(route, wildcard = false) {
            let key;
            switch (route[0]) {
                case NodeTypes.GROUP:
                    return "(" + this.build_path_spec(route[1]) + ")";
                case NodeTypes.CAT:
                    return (this.build_path_spec(route[1]) + this.build_path_spec(route[2]));
                case NodeTypes.STAR:
                    return this.build_path_spec(route[1], true);
                case NodeTypes.SYMBOL:
                    key = route[1];
                    if (wildcard) {
                        return (key.startsWith("*") ? "" : "*") + key;
                    }
                    else {
                        return ":" + key;
                    }
                    break;
                case NodeTypes.SLASH:
                case NodeTypes.DOT:
                case NodeTypes.LITERAL:
                    return route[1];
                default:
                    throw new Error("Unknown Rails node type");
            }
        }
        visit_globbing(route, parameters, optional) {
            const key = route[1];
            let value = parameters[key];
            delete parameters[key];
            if (this.is_nullable(value)) {
                return this.visit(route, parameters, optional);
            }
            if (this.is_array(value)) {
                value = value.join("/");
            }
            const result = this.path_identifier(value);
            return false
                ? result
                : encodeURI(result);
        }
        get_prefix() {
            const prefix = this.configuration.prefix;
            return prefix.match("/$")
                ? prefix.substring(0, prefix.length - 1)
                : prefix;
        }
        route(parts_table, route_spec, absolute = false) {
            const required_params = [];
            const parts = [];
            const default_options = {};
            for (const [part, { r: required, d: value }] of Object.entries(parts_table)) {
                parts.push(part);
                if (required) {
                    required_params.push(part);
                }
                if (this.is_not_nullable(value)) {
                    default_options[part] = value;
                }
            }
            const result = (...args) => {
                return this.build_route(parts, required_params, default_options, route_spec, absolute, args);
            };
            result.requiredParams = () => required_params;
            result.toString = () => {
                return this.build_path_spec(route_spec);
            };
            return result;
        }
        route_url(route_defaults) {
            const hostname = route_defaults.host || this.current_host();
            if (!hostname) {
                return "";
            }
            const subdomain = route_defaults.subdomain
                ? route_defaults.subdomain + "."
                : "";
            const protocol = route_defaults.protocol || this.current_protocol();
            let port = route_defaults.port ||
                (!route_defaults.host ? this.current_port() : undefined);
            port = port ? ":" + port : "";
            return protocol + "://" + subdomain + hostname + port;
        }
        current_host() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.hostname)) || "";
        }
        current_protocol() {
            var _a, _b;
            return ((isBroswer && ((_b = (_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.protocol) === null || _b === void 0 ? void 0 : _b.replace(/:$/, ""))) || "http");
        }
        current_port() {
            var _a;
            return (isBroswer && ((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.port)) || "";
        }
        is_object(value) {
            return (typeof value === "object" &&
                Object.prototype.toString.call(value) === "[object Object]");
        }
        is_array(object) {
            return object instanceof Array;
        }
        is_callable(object) {
            return typeof object === "function" && !!object.call;
        }
        is_reserved_option(key) {
            return ReservedOptions.includes(key);
        }
        namespace(object, namespace, routes) {
            const parts = (namespace === null || namespace === void 0 ? void 0 : namespace.split(".")) || [];
            if (parts.length === 0) {
                return;
            }
            for (let index = 0; index < parts.length; index++) {
                const part = parts[index];
                if (index < parts.length - 1) {
                    object = object[part] || (object[part] = {});
                }
                else {
                    object[part] = routes;
                }
            }
        }
        configure(new_config) {
            this.configuration = { ...this.configuration, ...new_config };
            return this.configuration;
        }
        config() {
            return { ...this.configuration };
        }
        is_module_supported(name) {
            return ModuleReferences[name].isSupported();
        }
        ensure_module_supported(name) {
            if (!this.is_module_supported(name)) {
                throw new Error(`${name} is not supported by runtime`);
            }
        }
        define_module(name, module) {
            this.ensure_module_supported(name);
            ModuleReferences[name].define(module);
        }
    }
    const Utils = new UtilsClass();
    // We want this helper name to be short
    const __jsr = {
        r(parts_table, route_spec, absolute) {
            return Utils.route(parts_table, route_spec, absolute);
        },
    };
    const result = {
        ...__jsr,
        configure: (config) => {
            return Utils.configure(config);
        },
        config: () => {
            return Utils.config();
        },
        serialize: (object) => {
            return Utils.serialize(object);
        },
        ...{},
    };
    Utils.define_module("ESM", result);
    return result;
})(this);
export const configure = __jsr.configure;

export const config = __jsr.config;

export const serialize = __jsr.serialize;

/**
 * Generates rails route to
 * /enroll/create_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const able_act_programs_create_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"enroll"],[2,[7,"/"],[2,[6,"create_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/able_act_programs_dda_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const able_act_programs_dda_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"able_act_programs_dda_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /enroll(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const able_act_programs_enrollment_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"enroll"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/account_fees(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_account_fees_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/account_urgent_note(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_account_urgent_note_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_urgent_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_activations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_activation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_activations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_activations/:account_activation_id/pre_order(.:format)
 * @param {any} account_activation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_activation_update_pre_order_path = __jsr.r({"account_activation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_activations"],[2,[7,"/"],[2,[3,"account_activation_id"],[2,[7,"/"],[2,[6,"pre_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/trust_beneficiaries/:id/account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_api_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/card_transactions/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_card_transaction_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/card_transactions(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_card_transactions_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"card_transactions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/deactivate_card(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_deactivate_card_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"deactivate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/decisions/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_decision_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"decisions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/decisions(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_decisions_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"decisions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fee_periods/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fee_period_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fee_periods"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/account_fee_refunds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fee_refunds_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"account_fee_refunds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fee_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fee_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fee_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fee_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fee_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fee_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/fee_settings_section(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fee_settings_section_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"fee_settings_section"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/accounts/:account_id/fetch_receipts_by_month(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_fetch_receipts_by_month_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"fetch_receipts_by_month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/account_fee_settings/:id/account_info_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_info_update_dashboard_account_fee_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"account_fee_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"account_info_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/iso_messages/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_iso_message_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"iso_messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/iso_messages(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_iso_messages_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"iso_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/misc_notifications/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_misc_notification_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"misc_notifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/misc_notifications(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_misc_notifications_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"misc_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/nightly_posts/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_nightly_post_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"nightly_posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/nightly_posts(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_nightly_posts_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"nightly_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/official_statement/account/:account_id/:month(.:format)
 * @param {any} account_id
 * @param {any} month
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_official_statement_path = __jsr.r({"account_id":{"r":true},"month":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[3,"month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/accounts/:account_id/onboarding_flow(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_onboarding_flow_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"onboarding_flow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/order_a_new_card(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_order_a_new_card_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"order_a_new_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/reactivate_card(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_reactivate_card_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"reactivate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/transfers/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_transfer_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/transfers(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_transfers_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/undelete_account(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const account_undelete_account_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"undelete_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/accounts_list(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const accounts_list_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"accounts_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ach_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ach_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_files/:ach_file_id/download(/:file_name)(.:format)
 * @param {any} ach_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ach_file_download_path = __jsr.r({"ach_file_id":{"r":true},"file_name":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_files"],[2,[7,"/"],[2,[3,"ach_file_id"],[2,[7,"/"],[2,[6,"download"],[2,[1,[2,[7,"/"],[3,"file_name"]]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_files/:ach_file_id/submit(.:format)
 * @param {any} ach_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ach_file_submit_path = __jsr.r({"ach_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_files"],[2,[7,"/"],[2,[3,"ach_file_id"],[2,[7,"/"],[2,[6,"submit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ach_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/cards/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_api_v2_card_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/activate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const activate_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_disbursements/active(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const active_dashboard_recurring_disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[6,"active"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/add_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"add_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/spending_settings/:id/relationships/:relationship_name(.:format)
 * @param {any} id
 * @param {any} relationship_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const add_to_relationship_api_v2_spending_setting_path = __jsr.r({"id":{"r":true},"relationship_name":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"spending_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"relationships"],[2,[7,"/"],[2,[3,"relationship_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/confirmation_page/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const additional_card_confirmation_page_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"confirmation_page"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/request_additional_card/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const additional_card_form_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"request_additional_card"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/additional_card_shipping_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const additional_card_shipping_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"additional_card_shipping_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/additional_users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const additional_users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"additional_users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/addresses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"addresses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/address_change_requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const address_change_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"address_change_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/address_change_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const address_change_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"address_change_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id/address(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const address_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/addresses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const addresses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const admin_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/agreements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agreement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/agreements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const agreements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"agreements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/outbound_statement_requests/all_account_statements_by_month(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_account_statements_by_month_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"outbound_statement_requests"],[2,[7,"/"],[2,[6,"all_account_statements_by_month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_close_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_card_close_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_close_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/all_client_statements_by_month(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_client_statements_by_month_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"all_client_statements_by_month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:id/all_transfers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const all_transfers_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"all_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:id/analysis(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const analysis_api_v2_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/transfers/:id/analysis(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const analysis_api_v2_account_transfer_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trust_beneficiaries/:id/analysis(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const analysis_api_v2_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"analysis"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_path = __jsr.r({"path":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/calendar(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_calendar_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"calendar"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/client(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_client_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"client"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/disbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/disbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/trust_beneficiaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/cards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_card_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/card_account_trusts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_card_account_trust_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"card_account_trusts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/organizations/:organization_id/accounts(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_create_account_path = __jsr.r({"organization_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/accounts/:account_id/card_account_trusts(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_create_card_account_trust_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"card_account_trusts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/report_generation_requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_generation_request_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"report_generation_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/report_generation_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_report_generation_requests_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"report_generation_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/reports(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_reports_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"reports"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/signups/card_account_signups/:id/card_account_signup_logs(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_signups_card_account_signup_logs_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"card_account_signups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"card_account_signup_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/signups/card_account_signups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_signups_card_account_signups_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"card_account_signups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_user_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v1_users_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/access_codes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_access_code_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"access_codes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/access_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_access_codes_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"access_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/access_codes/validate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_access_codes_validate_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"access_codes"],[2,[7,"/"],[2,[6,"validate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/access_tokens(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_access_tokens_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"access_tokens"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/account_onboarding_flow(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_account_onboarding_flow_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"account_onboarding_flow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/auto_transfers/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_auto_transfer_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"auto_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/auto_transfers(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_auto_transfers_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"auto_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/bank_accounts(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_bank_accounts_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/transactions(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_transactions_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transactions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/transfers/:id(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_transfer_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/transfers(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_account_transfers_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_accounts_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/addresses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_address_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"addresses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/alerts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_alerts_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"alerts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/attachments/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_attachment_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"attachments"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/attachments(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_attachments_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"attachments"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/authorization_grants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_authorization_grants_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"authorization_grants"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/cards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_card_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_cards_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_destroy_account_by_card_reference_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/disability_designations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_disability_designations_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"disability_designations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/disbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_disbursement_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_login_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/logout(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_logout_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"logout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/merchant_clusters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_merchant_clusters_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"merchant_clusters"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/merchant_exceptions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_merchant_exception_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"merchant_exceptions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/merchant_exceptions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_merchant_exceptions_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"merchant_exceptions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_organization_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/organizations/:organization_id/accounts(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_organization_accounts_path = __jsr.r({"organization_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/organizations/:organization_id/trusts(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_organization_trusts_path = __jsr.r({"organization_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"trusts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_password_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/payees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_payee_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/people/login(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_people_login_index_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"login"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/people/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_person_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/promotions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_promotions_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"promotions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/referrals/:referral_slug/emails(.:format)
 * @param {any} referral_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_referral_referral_emails_path = __jsr.r({"referral_slug":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[3,"referral_slug"],[2,[7,"/"],[2,[6,"emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/reset_password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_reset_password_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"reset_password"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/rulesets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_ruleset_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"rulesets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/rulesets/:ruleset_id/semantic_categories(.:format)
 * @param {any} ruleset_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_ruleset_semantic_categories_path = __jsr.r({"ruleset_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"rulesets"],[2,[7,"/"],[2,[3,"ruleset_id"],[2,[7,"/"],[2,[6,"semantic_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/rulesets/:ruleset_id/semantic_categories/:id(.:format)
 * @param {any} ruleset_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_ruleset_semantic_category_path = __jsr.r({"ruleset_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"rulesets"],[2,[7,"/"],[2,[3,"ruleset_id"],[2,[7,"/"],[2,[6,"semantic_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/semantic_categories(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_semantic_categories_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"semantic_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/signups/card_account_signups(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_signups_card_account_signups_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"card_account_signups"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/spending_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_spending_setting_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"spending_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/spending_settings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_spending_settings_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"spending_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/transactions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_transaction_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"transactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trusts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trust_beneficiaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_beneficiaries_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trust_beneficiaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trust_beneficiaries/:trust_beneficiary_id/addresses(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_beneficiary_addresses_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trust_beneficiaries/:trust_beneficiary_id/deposits(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_beneficiary_deposits_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trust_beneficiaries/:trust_beneficiary_id/disbursements(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_beneficiary_disbursements_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trust_beneficiaries/:trust_beneficiary_id/payees(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_beneficiary_payees_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/trusts/:trust_id/trust_beneficiaries(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const api_v2_trust_trust_beneficiaries_path = __jsr.r({"trust_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/holiday_impacted_transfers/apply_guidance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const apply_guidance_dashboard_holiday_impacted_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"holiday_impacted_transfers"],[2,[7,"/"],[2,[6,"apply_guidance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/approve_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_all_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/approve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/portfolio_capital_changes/:id/approve(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approve_portfolio_capital_change_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"portfolio_capital_changes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/recurring_deposits/approved(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approved_dashboard_client_recurring_deposits_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/recurring_deposits/approved(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approved_dashboard_organization_recurring_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/recurring_deposits/approved(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approved_dashboard_portfolio_recurring_deposits_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_deposits/approved(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const approved_dashboard_recurring_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"approved"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/attach_payer(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const attach_payer_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"attach_payer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auto_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/auto_transfers/auto_transfer_agreement(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auto_transfer_agreement_dashboard_auto_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"auto_transfers"],[2,[7,"/"],[2,[6,"auto_transfer_agreement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const auto_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/balance_file_passwords(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const balance_file_passwords_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"balance_file_passwords"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/balance_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const balance_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"balance_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/generate_microdeposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_accounts_verify_microdeposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[6,"generate_microdeposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/bank_accounts/bank_name_for_routing(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bank_name_for_routing_dashboard_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[6,"bank_name_for_routing"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries/:trust_beneficiary_id/government_benefits/batch_update(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_update_dashboard_trust_beneficiary_government_benefits_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"government_benefits"],[2,[7,"/"],[2,[6,"batch_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/batch_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const batch_update_trust_beneficiaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[6,"batch_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/beneficiary/create_person_details(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const beneficiary_create_person_details_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"beneficiary"],[2,[7,"/"],[2,[6,"create_person_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/beneficiary/create_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const beneficiary_create_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"beneficiary"],[2,[7,"/"],[2,[6,"create_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/beneficiary/:resource_slug(.:format)
 * @param {any} resource_slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const beneficiary_signup_path = __jsr.r({"resource_slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"beneficiary"],[2,[7,"/"],[2,[3,"resource_slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/official_statement/:account_id/blocked_charges_report(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const blocked_charges_report_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"blocked_charges_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/transfers/bomorrow(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bomorrow_dashboard_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"bomorrow"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/breakpoint_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const breakpoint_line_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"breakpoint_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/breakpoint_lines(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const breakpoint_lines_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"breakpoint_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/budget_item_csvs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const budget_item_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"budget_item_csvs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/budget_item_csvs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const budget_item_csvs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"budget_item_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/shipment_informations/bulk_create(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_create_shipment_informations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"shipment_informations"],[2,[7,"/"],[2,[6,"bulk_create"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/bulk_notifications(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_notifications_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"bulk_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fee_settings/bulk_update(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_account_fee_settings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fee_settings"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/budget_item_csvs/:id/bulk_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_budget_item_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"budget_item_csvs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holding_csvs/:id/bulk_update(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const bulk_update_holding_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holding_csvs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"bulk_update"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/business_verifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const business_verifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"business_verifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/trust_beneficiaries/by_active_portfolio_id(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const by_active_portfolio_id_portfolio_trust_beneficiaries_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[6,"by_active_portfolio_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/calculate_fee(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calculate_fee_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"calculate_fee"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/calls/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const call_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calls"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/calls(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const calls_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calls"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/transfers/:id/cancel(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancel_api_v2_account_transfer_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/deposits/cancelled(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelled_dashboard_client_deposits_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cancelled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/deposits/cancelled(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelled_dashboard_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cancelled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/deposits/cancelled(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelled_dashboard_organization_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cancelled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/deposits/cancelled(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cancelled_dashboard_portfolio_deposits_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cancelled"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/activate_card(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_activate_card_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"activate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/official_statement/:month(.:format)
 * @param {any} card_id
 * @param {any} month
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_admin_official_statement_path = __jsr.r({"card_id":{"r":true},"month":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[3,"month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/bank_accounts(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_bank_accounts_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/card_batch_file_uploads/:import_file_id(.:format)
 * @param {any} organization_id
 * @param {any} import_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_batch_file_uploads_path = __jsr.r({"organization_id":{"r":true},"import_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"card_batch_file_uploads"],[2,[7,"/"],[2,[3,"import_file_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/card_close_requests/:id(.:format)
 * @param {any} card_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_card_close_request_path = __jsr.r({"card_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"card_close_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/card_close_requests/:card_close_request_id/update_to_completed(.:format)
 * @param {any} card_id
 * @param {any} card_close_request_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_card_close_request_update_to_completed_path = __jsr.r({"card_id":{"r":true},"card_close_request_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"card_close_requests"],[2,[7,"/"],[2,[3,"card_close_request_id"],[2,[7,"/"],[2,[6,"update_to_completed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/card_close_requests(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_card_close_requests_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"card_close_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_close_request/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_close_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_close_request"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/deactivate_card(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_deactivate_card_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"deactivate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/direct_deposit_admin_credit(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_direct_deposit_admin_credit_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"direct_deposit_admin_credit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_fees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_fee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_fees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_fee_defaults/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_fee_default_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_fee_defaults"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_fee_defaults(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_fee_defaults_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_fee_defaults"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_fee_refunds/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_fee_refund_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_fee_refunds"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_fee_refunds(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_fee_refunds_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_fee_refunds"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/issue_check(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_issue_check_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"issue_check"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/issue_special_ik(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_issue_special_ik_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"issue_special_ik"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/nightly_posts(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_nightly_posts_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"nightly_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/organizations/:id/card_program_questions(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_program_questions_api_v1_organization_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"card_program_questions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/provisional_transaction(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_provisional_transaction_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"provisional_transaction"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/reactivate_card(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_reactivate_card_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"reactivate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/refresh_status(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_refresh_status_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"refresh_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_reissues/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_reissue_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_reissues"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_reissues/:card_reissue_id/submit_reissue(.:format)
 * @param {any} card_reissue_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_reissue_submit_reissue_path = __jsr.r({"card_reissue_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_reissues"],[2,[7,"/"],[2,[3,"card_reissue_id"],[2,[7,"/"],[2,[6,"submit_reissue"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/reissue_transfer(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_reissue_transfer_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"reissue_transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_reissues(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_reissues_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_reissues"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_reissues/search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_reissues_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_reissues"],[2,[7,"/"],[2,[6,"search"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/report_lost_or_stolen(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_report_lost_or_stolen_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"report_lost_or_stolen"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_services_logs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_services_log_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_services_logs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_services_logs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_services_logs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_services_logs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/special_credit(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_special_credit_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"special_credit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_statements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_statement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_statements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_statements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_statements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_statements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_statuses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_statuses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/refresh_card_status/:card_id(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_status_pull_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"refresh_card_status"],[2,[7,"/"],[2,[3,"card_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_statuses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_transactions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_transaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_transactions/:id/toggle_hidden(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_transaction_toggle_hidden_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hidden"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_transactions/:id/toggle_hidden_from_statements(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_transaction_toggle_hidden_from_statements_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hidden_from_statements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_transactions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_transactions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_transactions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:card_id/undelete_card(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const card_undelete_card_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[6,"undelete_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /cardholder/bulk_transaction_report/:organization_id(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_bulk_transaction_report_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cardholder"],[2,[7,"/"],[2,[6,"bulk_transaction_report"],[2,[7,"/"],[2,[3,"organization_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cardholder/account/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_home_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cardholder"],[2,[7,"/"],[2,[6,"account"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cardholder/official_statement/:card_id/:month(.:format)
 * @param {any} card_id
 * @param {any} month
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_official_statement_path = __jsr.r({"card_id":{"r":true},"month":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cardholder"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[3,"month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/cardholder_registered(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_registered_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"cardholder_registered"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /cardholder/sign-in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cardholder"],[2,[7,"/"],[2,[6,"sign-in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cardholder/sign-in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cardholder"],[2,[7,"/"],[2,[6,"sign-in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cardholder/sign-in-with-card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_signin_with_card_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"cardholder"],[2,[7,"/"],[2,[6,"sign-in-with-card"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /cardholder/transaction_report/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cardholder_transaction_report_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"cardholder"],[2,[7,"/"],[2,[6,"transaction_report"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/compromised_cards_uploader(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cards_compromised_cards_uploader_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"compromised_cards_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/categorized_budget_categories/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categorized_budget_category_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"categorized_budget_categories"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/categorized_budget_timelines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categorized_budget_timeline_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"categorized_budget_timelines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/categorized_budget_timelines/:categorized_budget_timeline_id/categorized_budget_categories(.:format)
 * @param {any} categorized_budget_timeline_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const categorized_budget_timeline_categorized_budget_categories_path = __jsr.r({"categorized_budget_timeline_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"categorized_budget_timelines"],[2,[7,"/"],[2,[3,"categorized_budget_timeline_id"],[2,[7,"/"],[2,[6,"categorized_budget_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fees/charge_ach(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charge_ach_account_fees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fees"],[2,[7,"/"],[2,[6,"charge_ach"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/charge_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charge_all_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"charge_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fees/charge_cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charge_cards_account_fees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fees"],[2,[7,"/"],[2,[6,"charge_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id/charge_fees_to_cards(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const charge_fees_to_cards_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"charge_fees_to_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/check_configurations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_configuration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"check_configurations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/check_configurations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const check_configurations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"check_configurations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/deposits/cleared(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cleared_dashboard_client_deposits_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cleared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/deposits/cleared(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cleared_dashboard_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cleared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/deposits/cleared(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cleared_dashboard_organization_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cleared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/deposits/cleared(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const cleared_dashboard_portfolio_deposits_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"cleared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/in_product_ads/:id/clicked(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const clicked_dashboard_in_product_ad_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"in_product_ads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"clicked"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /client/:client_id(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_beneficiary_home_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[3,"client_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /client/:client_id/disbursements(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_disbursements_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /client/:client_id/payees(.:format)
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_payees_path = __jsr.r({"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /client/sign-in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[6,"sign-in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /client/sign-in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const client_signin_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"client"],[2,[7,"/"],[2,[6,"sign-in"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/:id/close_card(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_card_dashboard_card_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"close_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:id/close_sub_account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const close_sub_account_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"close_sub_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/daily_portfolio_closeouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const closeout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/daily_portfolio_closeouts/:closeout_id/holding_events/:id(.:format)
 * @param {any} closeout_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const closeout_holding_event_path = __jsr.r({"closeout_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[3,"closeout_id"],[2,[7,"/"],[2,[6,"holding_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/daily_portfolio_closeouts/:closeout_id/holding_events(.:format)
 * @param {any} closeout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const closeout_holding_events_path = __jsr.r({"closeout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[3,"closeout_id"],[2,[7,"/"],[2,[6,"holding_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/daily_portfolio_closeouts/:closeout_id/rollback(.:format)
 * @param {any} closeout_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const closeout_rollback_path = __jsr.r({"closeout_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[3,"closeout_id"],[2,[7,"/"],[2,[6,"rollback"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/daily_portfolio_closeouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const closeouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ephemeral_csvs/:id/commit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const commit_ephemeral_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ephemeral_csvs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"commit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/signups/card_account_signups/:id/complete(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const complete_api_v1_signups_card_account_signup_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"card_account_signups"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"complete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/official_statement/completed_transfers_report(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const completed_transfers_report_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[6,"completed_transfers_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/compromised_cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compromised_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"compromised_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/compromised_cards_uploader(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const compromised_cards_uploader_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"compromised_cards_uploader"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/bank_accounts/:id/connect_fee_new_bank_account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const connect_fee_new_bank_account_dashboard_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"connect_fee_new_bank_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /contact(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /contact_us(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const contact_us_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_us"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signups/continue(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const continue_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"continue"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/copy(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_bank_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[6,"copy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/bank_accounts/:id/copy_bank_account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_bank_account_dashboard_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy_bank_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/merchant_exceptions/:id/copy_to(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_to_api_v2_merchant_exception_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"merchant_exceptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy_to"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/spending_settings/:id/copy_to(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_to_api_v2_spending_setting_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"spending_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"copy_to"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/copy_to_organization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const copy_to_organization_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[6,"copy_to_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/external_checks/count(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_dashboard_external_checks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"external_checks"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/external_checks/count(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const count_dashboard_organization_external_checks_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"external_checks"],[2,[7,"/"],[2,[6,"count"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/able_act_programs_dda_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_able_act_programs_dda_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"able_act_programs_dda_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /activate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_activation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/balance_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_balance_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"balance_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/create_bank_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_bank_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"create_bank_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id/create_child_organization(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_child_organization_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_child_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:id/create_daily_portfolio_closeout(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_closeout_for_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_daily_portfolio_closeout"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_files/create_daily_funding_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_daily_funding_ach_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_files"],[2,[7,"/"],[2,[6,"create_daily_funding_files"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ephemeral_csvs/:id/create_disbursements(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_disbursements_ephemeral_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ephemeral_csvs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"create_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/create_family_funding_user_and_sub_org(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_family_funding_user_and_sub_org_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"create_family_funding_user_and_sub_org"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/manual_jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_manual_jobs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"manual_jobs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/create_plaid_authorization(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_plaid_authorization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"create_plaid_authorization"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/create_plaid_link_token(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_plaid_link_token_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"create_plaid_link_token"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/create_user_and_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const create_user_and_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"create_user_and_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/credits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const credit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"credits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/:id/credit_funds_now(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const credit_funds_now_ach_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"credit_funds_now"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases/csv(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const csv_dispute_cases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[2,[7,"/"],[2,[6,"csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_banners/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_banner_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_banners"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_banners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_banners_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_banners"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes/:notable_type/:notable_id(.:format)
 * @param {any} notable_type
 * @param {any} notable_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_note_get_by_type_and_id_path = __jsr.r({"notable_type":{"r":true},"notable_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[2,[7,"/"],[2,[3,"notable_type"],[2,[7,"/"],[2,[3,"notable_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes/:notable_type/:notable_id(.:format)
 * @param {any} notable_type
 * @param {any} notable_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_note_update_by_type_and_id_path = __jsr.r({"notable_type":{"r":true},"notable_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[2,[7,"/"],[2,[3,"notable_type"],[2,[7,"/"],[2,[3,"notable_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes/stream(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_notes_stream_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[2,[7,"/"],[2,[6,"stream"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes/toggle_importance/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const customer_service_notes_toggle_importance_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[2,[7,"/"],[2,[6,"toggle_importance"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/daily_portfolio_closeouts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const daily_portfolio_closeout_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/daily_portfolio_closeouts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const daily_portfolio_closeouts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/add_warn_rules/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_add_warn_rules_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"add_warn_rules"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/account_fee_settings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_fee_setting_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"account_fee_settings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/accounts/:account_id/find_organization(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_find_organization_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"find_organization"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/notification_preferences/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_notification_preferences_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"notification_preferences"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/remove_warn_rules/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_remove_warn_rules_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"remove_warn_rules"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/accounts/:account_id/ruleset/warn_rules(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_ruleset_warn_rules_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"ruleset"],[2,[7,"/"],[2,[6,"warn_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/accounts/:account_id/statements_to_show(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_account_statements_to_show_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"statements_to_show"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/add_warn_rules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_add_warn_rules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"add_warn_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/:account_id/additional_cards(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_additional_cards_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"additional_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/additional_emails/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_additional_emails_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"additional_emails"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/auto_transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_auto_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"auto_transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/auto_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_auto_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"auto_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/bank_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/bank_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_bank_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"bank_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/card_close/:card_id(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_card_close_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"card_close"],[2,[7,"/"],[2,[3,"card_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/:dashboard_card_id/link_trust_beneficiary(.:format)
 * @param {any} dashboard_card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_card_link_trust_beneficiary_path = __jsr.r({"dashboard_card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"dashboard_card_id"],[2,[7,"/"],[2,[6,"link_trust_beneficiary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/:account_id/card_status_details(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_card_status_details_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"card_status_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/card_transactions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_card_transaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/card_transaction_notes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_card_transaction_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"card_transaction_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/card_transaction_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_card_transaction_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"card_transaction_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/calculate_draft_recurring_disbursement_total(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_calculate_draft_recurring_disbursement_total_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"calculate_draft_recurring_disbursement_total"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/cards/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_card_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/categorized_budget_timelines(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_categorized_budget_timelines_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"categorized_budget_timelines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/categorized_budgets(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_categorized_budgets_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"categorized_budgets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/connections/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_connection_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"connections"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/connection_relationships/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_connection_relationship_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"connection_relationships"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/connection_relationships(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_connection_relationships_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"connection_relationships"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/connections(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_connections_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"connections"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/deposits/:deposit_id/cancel(.:format)
 * @param {any} dashboard_client_id
 * @param {any} deposit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_deposit_cancel_path = __jsr.r({"dashboard_client_id":{"r":true},"deposit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[3,"deposit_id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/deposits(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_deposits_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/disbursements(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_disbursements_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/notification_icon(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_disbursements_notification_icon_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/draft_disbursements/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_draft_disbursement_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/draft_disbursements(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_draft_disbursements_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/draft_notification_icon(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_draft_disbursements_notification_icon_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"draft_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/effective_balance(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_effective_balance_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"effective_balance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/fetch_documents_and_folders(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_fetch_documents_and_folders_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"fetch_documents_and_folders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/holding_events(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_holding_events_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"holding_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/holdings(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_holdings_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"holdings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/link_card(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_link_card_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"link_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/dashboard_notes/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_note_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"dashboard_notes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/dashboard_notes(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_notes_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"dashboard_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/on_hold_notification_icon(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_on_hold_disbursements_notification_icon_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"on_hold_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/payees(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_payees_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/positions(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_positions_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/primary_note(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_primary_note_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"primary_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/quarterly_statement(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_quarterly_statement_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"quarterly_statement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/recurring_deposits(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_recurring_deposits_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/recurring_disbursements(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_recurring_disbursements_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/recurring_notification_icon(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_recurring_disbursements_notification_icon_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"recurring_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/show_investment_details(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_show_investment_details_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"show_investment_details"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/trust_remainder_beneficiaries(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_trust_remainder_beneficiaries_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"trust_remainder_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/trust_remainder_beneficiaries/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_trust_remainder_beneficiary_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"trust_remainder_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/trust_statement_recipients/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_trust_statement_recipient_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"trust_statement_recipients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/trust_statement_recipients(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_trust_statement_recipients_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"trust_statement_recipients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/unique_assets/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_unique_asset_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"unique_assets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/unique_assets(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_unique_assets_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"unique_assets"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/wealth_account_events/:id(.:format)
 * @param {any} dashboard_client_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_wealth_account_event_path = __jsr.r({"dashboard_client_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/wealth_account_events/:use/:filename(.:format)
 * @param {any} dashboard_client_id
 * @param {any} use
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_client_wealth_account_events_path = __jsr.r({"dashboard_client_id":{"r":true},"use":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[2,[7,"/"],[2,[3,"use"],[2,[7,"/"],[2,[3,"filename"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_clients_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/create_address_change_request/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_create_address_change_request_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"create_address_change_request"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/delete_family_funding_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_delete_family_funding_invite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"delete_family_funding_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/deposits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_deposit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/deposits/:dashboard_deposit_id/cancel(.:format)
 * @param {any} dashboard_deposit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_deposit_cancel_path = __jsr.r({"dashboard_deposit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[3,"dashboard_deposit_id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/disbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/disbursements/:dashboard_disbursement_id/approve(.:format)
 * @param {any} dashboard_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_disbursement_approve_path = __jsr.r({"dashboard_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"dashboard_disbursement_id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/disbursements/:dashboard_disbursement_id/authorized_users(.:format)
 * @param {any} dashboard_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_disbursement_authorized_users_path = __jsr.r({"dashboard_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"dashboard_disbursement_id"],[2,[7,"/"],[2,[6,"authorized_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/disbursements/:dashboard_disbursement_id/deny(.:format)
 * @param {any} dashboard_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_disbursement_deny_path = __jsr.r({"dashboard_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"dashboard_disbursement_id"],[2,[7,"/"],[2,[6,"deny"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/disbursements/:disbursement_id/return_for_revision(.:format)
 * @param {any} disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_disbursement_return_for_revision_path = __jsr.r({"disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"disbursement_id"],[2,[7,"/"],[2,[6,"return_for_revision"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/disbursements/:dashboard_disbursement_id/view_check(.:format)
 * @param {any} dashboard_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_disbursement_view_check_path = __jsr.r({"dashboard_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"dashboard_disbursement_id"],[2,[7,"/"],[2,[6,"view_check"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/disbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/draft_disbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_draft_disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"draft_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/draft_disbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_draft_disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"draft_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/external_checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_external_checks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"external_checks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/family_funder_invites(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_family_funder_invites_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"family_funder_invites"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/folders/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_folder_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"folders"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/folders(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_folders_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"folders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/holidays/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_holiday_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"holidays"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard_notes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_notes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/notification_preferences(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_notification_preferences_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"notification_preferences"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/cards(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_cards_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/clients/:id(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_client_path = __jsr.r({"dashboard_organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/clients/:client_id/card_balance(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_client_card_balance_path = __jsr.r({"dashboard_organization_id":{"r":true},"client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"client_id"],[2,[7,"/"],[2,[6,"card_balance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/clients(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_clients_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/deposits/:deposit_id/cancel(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} deposit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_deposit_cancel_path = __jsr.r({"dashboard_organization_id":{"r":true},"deposit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[3,"deposit_id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/deposits(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/disbursements/:id(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_disbursement_path = __jsr.r({"dashboard_organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/disbursements(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_disbursements_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/notification_icon(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_disbursements_notification_icon_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/draft_disbursements/:id(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_draft_disbursement_path = __jsr.r({"dashboard_organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/draft_disbursements(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_draft_disbursements_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/draft_notification_icon(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_draft_disbursements_notification_icon_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"draft_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/external_checks(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_external_checks_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"external_checks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/multiple_deposits(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_multiple_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"multiple_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/on_hold_notification_icon(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_on_hold_disbursements_notification_icon_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"on_hold_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/pending(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_pending_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/positions(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_positions_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/recurring_deposits(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_recurring_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/recurring_disbursements/:id(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_recurring_disbursement_path = __jsr.r({"dashboard_organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/recurring_disbursements(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_recurring_disbursements_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/recurring_notification_icon(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_recurring_disbursements_notification_icon_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"recurring_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/wealth_account_events/:id(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_organization_wealth_account_event_path = __jsr.r({"dashboard_organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/payees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_payee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_payees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/:account_id/pending_backup_cards(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_pending_backup_cards_path = __jsr.r({"account_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"pending_backup_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/clients(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_clients_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/deposits/:deposit_id/cancel(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {any} deposit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_deposit_cancel_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"deposit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[3,"deposit_id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/deposits(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_deposits_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/disbursements(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_disbursements_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/notification_icon(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_disbursements_notification_icon_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/draft_disbursements/:id(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_draft_disbursement_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/draft_disbursements(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_draft_disbursements_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/draft_notification_icon(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_draft_disbursements_notification_icon_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"draft_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/holding_events(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_holding_events_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"holding_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/holdings(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_holdings_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"holdings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/on_hold_notification_icon(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_on_hold_disbursements_notification_icon_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"on_hold_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/positions(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_positions_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/recurring_deposits(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_recurring_deposits_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/recurring_disbursements(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_recurring_disbursements_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/recurring_notification_icon(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_recurring_disbursements_notification_icon_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"recurring_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/wealth_account_events(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_wealth_account_events_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/wealth_accounts(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolio_wealth_accounts_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"wealth_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/reactivate_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_reactivate_card_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"reactivate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_deposits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_deposit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_deposits/:recurring_deposit_id/cancel(.:format)
 * @param {any} recurring_deposit_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_deposit_cancel_path = __jsr.r({"recurring_deposit_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[3,"recurring_deposit_id"],[2,[7,"/"],[2,[6,"cancel"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/recurring_disbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_disbursements/:dashboard_recurring_disbursement_id/approve(.:format)
 * @param {any} dashboard_recurring_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_disbursement_approve_path = __jsr.r({"dashboard_recurring_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"dashboard_recurring_disbursement_id"],[2,[7,"/"],[2,[6,"approve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_disbursements/:dashboard_recurring_disbursement_id/authorized_users(.:format)
 * @param {any} dashboard_recurring_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_disbursement_authorized_users_path = __jsr.r({"dashboard_recurring_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"dashboard_recurring_disbursement_id"],[2,[7,"/"],[2,[6,"authorized_users"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_disbursements/:dashboard_recurring_disbursement_id/deny(.:format)
 * @param {any} dashboard_recurring_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_disbursement_deny_path = __jsr.r({"dashboard_recurring_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"dashboard_recurring_disbursement_id"],[2,[7,"/"],[2,[6,"deny"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_disbursements/:dashboard_recurring_disbursement_id/request_cancellation(.:format)
 * @param {any} dashboard_recurring_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_disbursement_request_cancellation_path = __jsr.r({"dashboard_recurring_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"dashboard_recurring_disbursement_id"],[2,[7,"/"],[2,[6,"request_cancellation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/recurring_disbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_recurring_disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/remove_warn_rules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_remove_warn_rules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"remove_warn_rules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/resend_family_funding_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_resend_family_funding_invite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"resend_family_funding_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/send_family_funding_invite(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_send_family_funding_invite_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"send_family_funding_invite"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/toggle_hide_card_close_transaction(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_toggle_hide_card_close_transaction_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"toggle_hide_card_close_transaction"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_beneficiaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries/:trust_beneficiary_id/government_benefits(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_beneficiary_government_benefits_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"government_benefits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries/:trust_beneficiary_id/trust_beneficiaries/send_destroy_request(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_beneficiary_send_destroy_request_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[6,"send_destroy_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries/:trust_beneficiary_id/trust_beneficiaries/send_new_portal_user_request(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_beneficiary_send_new_portal_user_request_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[6,"send_new_portal_user_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/clients/:id(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_client_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/clients(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_clients_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"clients"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/disbursements/:id(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_disbursement_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/disbursements(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_disbursements_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/notification_icon(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_disbursements_notification_icon_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/draft_disbursements/:id(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_draft_disbursement_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/draft_disbursements(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_draft_disbursements_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"draft_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/draft_notification_icon(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_draft_disbursements_notification_icon_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"draft_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/holdings(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_holdings_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"holdings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/on_hold_notification_icon(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_on_hold_disbursements_notification_icon_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"on_hold_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/portfolios/:id(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_portfolio_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/portfolios(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_portfolios_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/positions(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_positions_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/recurring_disbursements/:id(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_recurring_disbursement_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/recurring_disbursements(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_recurring_disbursements_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/recurring_notification_icon(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_recurring_disbursements_notification_icon_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"recurring_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/trust_beneficiaries(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_trust_beneficiaries_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/wealth_account_events/:id(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_wealth_account_event_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/wealth_account_events(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trust_wealth_account_events_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_trusts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/update_cardholder/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_update_cardholder_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"update_cardholder"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/update_mobile/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_update_mobile_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"update_mobile"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/user_access_invitations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_user_access_invitation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"user_access_invitations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/user_access_invitations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dashboard_user_access_invitations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"user_access_invitations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/data_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"data_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/data_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const data_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"data_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/direct_deposit_admin_credits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dd_admin_credits_table_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"direct_deposit_admin_credits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/deactivate_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deactivate_card_dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"deactivate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/deactivate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deactivate_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deactivate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/deactivate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deactivate_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"deactivate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/debits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const debit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"debits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/debit_direct_deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const debit_direct_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"debit_direct_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/decisions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decision_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"decisions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/decisions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const decisions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"decisions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/delete_authorizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_authorizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"delete_authorizations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/merchant_exceptions/:id/delete_from(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_from_api_v2_merchant_exception_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"merchant_exceptions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"delete_from"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/disbursement_categories/delete(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const delete_organization_disbursement_categories_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"disbursement_categories"],[2,[7,"/"],[2,[6,"delete"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/deposits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deposit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:id/destroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"destroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const destroy_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/official_statement/:account_id/detailed_transaction_report(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const detailed_transaction_report_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"detailed_transaction_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_ach_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursement_ach_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_ach_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_ach_templates/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursement_ach_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_ach_templates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_ach_templates(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursement_ach_templates_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_ach_templates"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_nacha_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursement_nacha_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_nacha_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_nacha_files/:disbursement_nacha_file_id/transfers(.:format)
 * @param {any} disbursement_nacha_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursement_nacha_file_transfers_path = __jsr.r({"disbursement_nacha_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_nacha_files"],[2,[7,"/"],[2,[3,"disbursement_nacha_file_id"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_nacha_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursement_nacha_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_nacha_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:id/disbursements_for(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursements_for_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"disbursements_for"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/notification_icon(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursements_notification_icon_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/disbursements_overview(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disbursements_overview_portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[6,"disbursements_overview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/discharge_family_funder/:sub_org_id(.:format)
 * @param {any} sub_org_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const discharge_family_funder_path = __jsr.r({"sub_org_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"discharge_family_funder"],[2,[7,"/"],[2,[3,"sub_org_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/disconnect_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const disconnect_card_dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"disconnect_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/in_product_ads/:id/dismissed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dismissed_dashboard_in_product_ad_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"in_product_ads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"dismissed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dispute_case_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases/assignees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dispute_case_assignees_dispute_cases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[2,[7,"/"],[2,[6,"assignees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases/:dispute_case_id/nightly_posts(.:format)
 * @param {any} dispute_case_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dispute_case_nightly_posts_path = __jsr.r({"dispute_case_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[2,[7,"/"],[2,[3,"dispute_case_id"],[2,[7,"/"],[2,[6,"nightly_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases/statuses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dispute_case_statuses_dispute_cases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[2,[7,"/"],[2,[6,"statuses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const dispute_cases_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/disbursements/download_checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_checks_dashboard_disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[6,"download_checks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/download_csv(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_csv_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/data_files/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_data_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"data_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_ach_files/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_disbursement_ach_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_ach_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/positive_pay_check_files/:id/download(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_positive_pay_check_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"positive_pay_check_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"download"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/external_checks/download_range(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_range_dashboard_external_checks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"external_checks"],[2,[7,"/"],[2,[6,"download_range"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/external_checks/download_range(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const download_range_dashboard_organization_external_checks_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"external_checks"],[2,[7,"/"],[2,[6,"download_range"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/draft_notification_icon(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const draft_disbursements_notification_icon_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"draft_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_activations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_account_activation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_activations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/card_transactions/:id/edit(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_account_card_transaction_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_account_fee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/misc_notifications/:id/edit(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_account_misc_notification_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"misc_notifications"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/nightly_posts/:id/edit(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_account_nightly_post_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"nightly_posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/transfers/:id/edit(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_account_transfer_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_ach_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/addresses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"addresses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/address_change_requests/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_address_change_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"address_change_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/agreements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_agreement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_transfers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_auto_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/calls/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_call_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calls"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_card_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_fee_defaults/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_card_fee_default_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_fee_defaults"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_statuses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_card_status_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_statuses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_transactions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_card_transaction_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_customer_service_note_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/recurring_disbursements/:id/edit(.:format)
 * @param {any} dashboard_organization_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_dashboard_organization_recurring_disbursement_path = __jsr.r({"dashboard_organization_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/disbursements/:id/edit(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_dashboard_trust_disbursement_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/recurring_disbursements/:id/edit(.:format)
 * @param {any} dashboard_trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_dashboard_trust_recurring_disbursement_path = __jsr.r({"dashboard_trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/decisions/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_decision_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"decisions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_ach_templates/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_disbursement_ach_template_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_ach_templates"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_dispute_case_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/incoming_smses/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_incoming_sms_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"incoming_smses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_merchant_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/cards/:id/edit(.:format)
 * @param {any} merchant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_merchant_card_path = __jsr.r({"merchant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_category_codes/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_merchant_category_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_category_codes"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/decisions/:id/edit(.:format)
 * @param {any} merchant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_merchant_decision_path = __jsr.r({"merchant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"decisions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_name_clusters/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_merchant_name_cluster_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_name_clusters"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_files/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_nightly_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_posts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_nightly_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organization_activations/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_organization_activation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organization_activations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_payee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:payee_id/disbursements/:id/edit(.:format)
 * @param {any} payee_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_payee_disbursement_path = __jsr.r({"payee_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"payee_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:payee_id/recurring_disbursements/:id/edit(.:format)
 * @param {any} payee_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_payee_recurring_disbursement_path = __jsr.r({"payee_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"payee_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/people/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/disbursements/:id/edit(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_portfolio_disbursement_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_deposits/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_recurring_deposit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_recurring_disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements/:recurring_disbursement_id/disbursements/:id/edit(.:format)
 * @param {any} recurring_disbursement_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_recurring_disbursement_disbursement_path = __jsr.r({"recurring_disbursement_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"recurring_disbursement_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/benificiary_trust_accounts/:id/edit(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_beneficiary_benificiary_trust_account_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"benificiary_trust_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/disbursements/:id/edit(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_beneficiary_disbursement_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/payees/:id/edit(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_beneficiary_payee_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/recurring_disbursements/:id/edit(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_beneficiary_recurring_disbursement_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/disbursements/:id/edit(.:format)
 * @param {any} trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_disbursement_path = __jsr.r({"trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/trust_accounts/:id/edit(.:format)
 * @param {any} trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_trust_trust_account_path = __jsr.r({"trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"trust_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/edit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/password/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const edit_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/eligible_for_implementable(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eligible_for_implementable_fee_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[6,"eligible_for_implementable"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/eligible_inheritors(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eligible_inheritors_fee_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[6,"eligible_inheritors"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/eligible_owners(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eligible_owners_fee_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[6,"eligible_owners"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/eligible_payers(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const eligible_payers_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"eligible_payers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /email_form_submit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const email_form_submit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"email_form_submit"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /member/mfa/verifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_mfa_verification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"mfa"],[2,[7,"/"],[2,[6,"verifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/mfa/verifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const enable_mfa_verifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"mfa"],[2,[7,"/"],[2,[6,"verifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ephemeral_csvs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ephemeral_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ephemeral_csvs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ephemeral_csv_rows/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ephemeral_csv_row_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ephemeral_csv_rows"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ephemeral_csvs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ephemeral_csvs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ephemeral_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/expiring_cards(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const expiring_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"expiring_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:fee_schedule_id/breakpoint_lines(.:format)
 * @param {any} fee_schedule_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fee_schedule_breakpoint_lines_path = __jsr.r({"fee_schedule_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"fee_schedule_id"],[2,[7,"/"],[2,[6,"breakpoint_lines"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:fee_schedule_implementation_id/fees(.:format)
 * @param {any} fee_schedule_implementation_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fee_schedule_implementation_fees_path = __jsr.r({"fee_schedule_implementation_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"fee_schedule_implementation_id"],[2,[7,"/"],[2,[6,"fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fee_schedule_implementations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fee_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /flipper
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const flipper_path = __jsr.r({}, [2,[7,"/"],[6,"flipper"]]);

/**
 * Generates rails route to
 * /www(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const force_redirect_to_www_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"www"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/fraud_watch_events/:fraud_watch_event_id/transaction_report_csv(.:format)
 * @param {any} fraud_watch_event_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fraud_watch_event_transaction_report_csv_path = __jsr.r({"fraud_watch_event_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fraud_watch_events"],[2,[7,"/"],[2,[3,"fraud_watch_event_id"],[2,[7,"/"],[2,[6,"transaction_report_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fraud_watch_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fraud_watch_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fraud_watch_events"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/FROM_(:date).txt(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const from_text_file_ach_actions_path = __jsr.r({"date":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"FROM_"],[2,[1,[3,"date"]],[2,[8,"."],[2,[6,"txt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/transfers/:id/fund_now(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const fund_now_dashboard_transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"fund_now"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/generate_account_fee_refunds_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_account_fee_refunds_file_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"generate_account_fee_refunds_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fees/generate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_account_fees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fees"],[2,[7,"/"],[2,[6,"generate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id/generate_fees(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_fees_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"generate_fees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/generate_issue_checks_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_issue_checks_file_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"generate_issue_checks_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/generate_special_debits_file(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const generate_special_debits_file_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"generate_special_debits_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/spending_settings/:id/history(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const history_api_v2_spending_setting_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"spending_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holdings/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holding_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holdings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holding_csvs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holding_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holding_csvs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holding_csvs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holding_csvs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holding_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/holding_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holding_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holding_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holding_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holding_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holding_events"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/holdings(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holdings_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holdings"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/holidays/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holiday_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holidays"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holidays/:holiday_id/holiday_impacted_dates/:id(.:format)
 * @param {any} holiday_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holiday_holiday_impacted_date_path = __jsr.r({"holiday_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holidays"],[2,[7,"/"],[2,[3,"holiday_id"],[2,[7,"/"],[2,[6,"holiday_impacted_dates"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holidays/:holiday_id/holiday_impacted_dates(.:format)
 * @param {any} holiday_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holiday_holiday_impacted_dates_path = __jsr.r({"holiday_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holidays"],[2,[7,"/"],[2,[3,"holiday_id"],[2,[7,"/"],[2,[6,"holiday_impacted_dates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holidays(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const holidays_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holidays"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/i2c_xml(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const i2c_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"i2c_xml"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/i2c_xml/echo(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const i2c_xml_echo_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"i2c_xml"],[2,[7,"/"],[2,[6,"echo"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/i2c_xml/get_card_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const i2c_xml_get_card_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"i2c_xml"],[2,[7,"/"],[2,[6,"get_card_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/i2c_xml/get_cardholder_profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const i2c_xml_get_cardholder_profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"i2c_xml"],[2,[7,"/"],[2,[6,"get_cardholder_profile"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/i2c_xml/index(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const i2c_xml_index_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"i2c_xml"],[2,[7,"/"],[2,[6,"index"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/i2c_xml/transaction_history(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const i2c_xml_transaction_history_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"i2c_xml"],[2,[7,"/"],[2,[6,"transaction_history"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/users/:id/identity_signup(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const identity_signup_api_v1_user_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"identity_signup"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/idv_responses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const idv_response_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"idv_responses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/idv_responses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const idv_responses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"idv_responses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/in_product_ads/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const in_product_ad_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"in_product_ads"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/in_product_ads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const in_product_ads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"in_product_ads"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/incentives/:code(.:format)
 * @param {any} code
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incentive_path = __jsr.r({"code":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"incentives"],[2,[7,"/"],[2,[3,"code"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/incoming_smses/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incoming_sms_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"incoming_smses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/incoming_smses(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const incoming_smses_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"incoming_smses"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fee_settings/:organization_id/index_by_org(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const index_by_org_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fee_settings"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"index_by_org"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/accounts/:account_id/transfers/:id/instant_fund(.:format)
 * @param {any} account_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const instant_fund_api_v2_account_transfer_path = __jsr.r({"account_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"instant_fund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invest/api/v1/trust_beneficiaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invest_api_v1_trust_beneficiaries_path = __jsr.r({"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"invest"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invest/api/v1/trust_beneficiaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invest_api_v1_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"invest"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invest/api/v1/trust_beneficiaries/:trust_beneficiary_id/addresses/:id(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invest_api_v1_trust_beneficiary_address_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"invest"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"addresses"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invest/api/v1/trust_beneficiaries/:trust_beneficiary_id/addresses(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invest_api_v1_trust_beneficiary_addresses_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"invest"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"addresses"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /invest/api/v1/trust_beneficiaries/:trust_beneficiary_id/cash_raise_requests(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invest_api_v1_trust_beneficiary_cash_raise_requests_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"invest"],[2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"cash_raise_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/invest_strategies(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invest_strategies_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invest_strategies"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/invest_strategies/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const invest_strategy_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"invest_strategies"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/iso_messages/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const iso_message_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"iso_messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/iso_messages/:id/toggle_hidden(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const iso_message_toggle_hidden_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"iso_messages"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hidden"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/iso_messages(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const iso_messages_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"iso_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/issue_checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const issue_checks_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"issue_checks"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /ivr(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /ivr/activation_flow_card_last_4(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_activation_flow_card_last_4_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"activation_flow_card_last_4"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/activation_flow_card_last_4_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_activation_flow_card_last_4_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"activation_flow_card_last_4_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/activation_flow_cardholder_dob_year(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_activation_flow_cardholder_dob_year_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"activation_flow_cardholder_dob_year"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/activation_flow_cardholder_dob_year_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_activation_flow_cardholder_dob_year_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"activation_flow_cardholder_dob_year_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/after_idv_check(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_after_idv_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"after_idv_check"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/after_idv_check_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_after_idv_check_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"after_idv_check_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/cardholder_start_menu(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_cardholder_start_menu_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"cardholder_start_menu"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/cardholder_start_menu_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_cardholder_start_menu_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"cardholder_start_menu_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_card_last_4(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_card_last_4_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_card_last_4"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_card_last_4_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_card_last_4_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_card_last_4_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_cardholder_dob_date(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_cardholder_dob_date_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_cardholder_dob_date"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_cardholder_dob_date_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_cardholder_dob_date_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_cardholder_dob_date_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_cardholder_dob_month(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_cardholder_dob_month_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_cardholder_dob_month"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_cardholder_dob_month_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_cardholder_dob_month_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_cardholder_dob_month_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_cardholder_dob_year(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_cardholder_dob_year_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_cardholder_dob_year"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_cardholder_dob_year_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_cardholder_dob_year_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_cardholder_dob_year_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_ssn_last_4(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_ssn_last_4_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_ssn_last_4"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/collect_ssn_last_4_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_collect_ssn_last_4_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"collect_ssn_last_4_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/continue_authentication(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_continue_authentication_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"continue_authentication"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/couldnt_verify_user(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_couldnt_verify_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"couldnt_verify_user"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/couldnt_verify_user_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_couldnt_verify_user_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"couldnt_verify_user_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ivr_emergency_message(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_emergency_message_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ivr_emergency_message"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/last_ten_transactions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_last_ten_transactions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"last_ten_transactions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/last_ten_transactions_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_last_ten_transactions_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"last_ten_transactions_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/next_scheduled_transfer(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_next_scheduled_transfer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"next_scheduled_transfer"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/next_scheduled_transfer_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_next_scheduled_transfer_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"next_scheduled_transfer_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/no_pin_menu(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_no_pin_menu_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"no_pin_menu"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/no_pin_menu_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_no_pin_menu_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"no_pin_menu_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/pin_menu(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_pin_menu_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"pin_menu"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/pin_menu_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_pin_menu_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"pin_menu_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/post_authentication_action(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_post_authentication_action_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"post_authentication_action"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/post_report_lost_stolen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_post_report_lost_stolen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"post_report_lost_stolen"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/post_report_lost_stolen_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_post_report_lost_stolen_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"post_report_lost_stolen_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_address(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_address_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_address"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_address_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_address_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_address_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_balance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_balance_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_balance"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_balance_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_balance_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_balance_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_declined_transaction(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_declined_transaction_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_declined_transaction"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_declined_transaction_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_declined_transaction_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_declined_transaction_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_status_of_open_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_status_of_open_card_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_status_of_open_card"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_transaction(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_transaction_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_transaction"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/read_transaction_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_read_transaction_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"read_transaction_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/recent_declined_transactions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_recent_declined_transactions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"recent_declined_transactions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/recent_declined_transactions_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_recent_declined_transactions_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"recent_declined_transactions_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/report_lost_stolen(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_report_lost_stolen_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"report_lost_stolen"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/report_lost_stolen_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_report_lost_stolen_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"report_lost_stolen_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/ssn_full_9(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_ssn_full_9_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"ssn_full_9"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /ivr/ssn_full_9_handler(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ivr_ssn_full_9_handler_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ivr"],[2,[7,"/"],[2,[6,"ssn_full_9_handler"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/land(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const land_fee_schedules_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[6,"land"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/daily_portfolio_closeouts/latest(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const latest_dashboard_portfolio_closeouts_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[6,"latest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/daily_portfolio_closeouts/latest(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const latest_portfolio_closeouts_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[6,"latest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /legal/:doc_name(.:format)
 * @param {any} doc_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legal_doc_path = __jsr.r({"doc_name":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"legal"],[2,[7,"/"],[2,[3,"doc_name"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /legal/:doc_name/:version(.:format)
 * @param {any} doc_name
 * @param {any} version
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legal_doc_version_path = __jsr.r({"doc_name":{"r":true},"version":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"legal"],[2,[7,"/"],[2,[3,"doc_name"],[2,[7,"/"],[2,[3,"version"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /legal/prepaid-rule-letter(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legal_prepaid_rule_letter_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"legal"],[2,[7,"/"],[2,[6,"prepaid-rule-letter"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /legal/schedule-of-fees-and-charges(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const legal_schedule_of_fees_and_charges_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"legal"],[2,[7,"/"],[2,[6,"schedule-of-fees-and-charges"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/mailer_preview/funding_digest(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mailer_preview_funding_digest_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"mailer_preview"],[2,[7,"/"],[2,[6,"funding_digest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/mailer_preview/funding_initiated(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mailer_preview_funding_initiated_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"mailer_preview"],[2,[7,"/"],[2,[6,"funding_initiated"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/mailer_preview/funding_landed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mailer_preview_funding_landed_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"mailer_preview"],[2,[7,"/"],[2,[6,"funding_landed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/mailer_preview/misc_notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mailer_preview_misc_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"mailer_preview"],[2,[7,"/"],[2,[6,"misc_notification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/mailer_preview/transaction_digest(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mailer_preview_transaction_digest_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"mailer_preview"],[2,[7,"/"],[2,[6,"transaction_digest"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/mailer_preview/transaction_notification(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mailer_preview_transaction_notification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"mailer_preview"],[2,[7,"/"],[2,[6,"transaction_notification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/manual_jobs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const manual_jobs_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"manual_jobs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_ach_files/:id/mark_as_submitted(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_as_submitted_disbursement_ach_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_ach_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_submitted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/positive_pay_check_files/:id/mark_as_submitted(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_as_submitted_positive_pay_check_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"positive_pay_check_files"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"mark_as_submitted"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/mark_as_verified(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_organization_as_verified_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"mark_as_verified"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/deposits/mark_selected_as_cleared(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mark_selected_as_cleared_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"mark_selected_as_cleared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/cards/:id(.:format)
 * @param {any} merchant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_card_path = __jsr.r({"merchant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/cards(.:format)
 * @param {any} merchant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_cards_path = __jsr.r({"merchant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_category_codes/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_category_code_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_category_codes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_category_codes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_category_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_category_codes"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/decisions/:id(.:format)
 * @param {any} merchant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_decision_path = __jsr.r({"merchant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"decisions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/decisions(.:format)
 * @param {any} merchant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_decisions_path = __jsr.r({"merchant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"decisions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/iso_messages/:id(.:format)
 * @param {any} merchant_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_iso_message_path = __jsr.r({"merchant_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"iso_messages"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/iso_messages(.:format)
 * @param {any} merchant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_iso_messages_path = __jsr.r({"merchant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"iso_messages"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_name_clusters/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_name_cluster_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_name_clusters"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_name_clusters(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchant_name_clusters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_name_clusters"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const merchants_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /contact_us/message_received(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const message_received_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"contact_us"],[2,[7,"/"],[2,[6,"message_received"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sign-in/verifications/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mfa_verification_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"sign-in"],[2,[7,"/"],[2,[6,"verifications"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sign-in/verifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const mfa_verifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign-in"],[2,[7,"/"],[2,[6,"verifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/multiple_deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const multiple_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"multiple_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/card_transactions/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_account_card_transaction_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/misc_notifications/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_account_misc_notification_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"misc_notifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:account_id/transfers/new(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_account_transfer_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_ach_action_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /activate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_activation_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"activate"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signups/additional_users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_additional_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"additional_users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/addresses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_address_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"addresses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/agreements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_agreement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"agreements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/auto_transfers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_auto_transfer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"auto_transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_bank_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_fee_defaults/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_card_fee_default_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_fee_defaults"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_statuses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_card_status_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_statuses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/card_transactions/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_card_transaction_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"card_transactions"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/check_configurations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_check_configuration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"check_configurations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/customer_service_notes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_customer_service_note_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"customer_service_notes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/card_transaction_notes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_card_transaction_note_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"card_transaction_notes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/cards/new(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_client_card_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/multiple_deposits/new(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_organization_multiple_deposit_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"multiple_deposits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/disbursements/new(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dashboard_trust_disbursement_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/disbursement_ach_templates/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_disbursement_ach_template_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"disbursement_ach_templates"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/dispute_cases/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_dispute_case_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dispute_cases"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_fee_schedule_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/incoming_smses/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_incoming_sms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"incoming_smses"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/new-invited-family-funding/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_invited_family_funding_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"new-invited-family-funding"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_merchant_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchants/:merchant_id/cards/new(.:format)
 * @param {any} merchant_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_merchant_card_path = __jsr.r({"merchant_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchants"],[2,[7,"/"],[2,[3,"merchant_id"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_category_codes/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_merchant_category_code_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_category_codes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_name_clusters/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_merchant_name_cluster_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_name_clusters"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sign-in/verifications/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_mfa_verification_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign-in"],[2,[7,"/"],[2,[6,"verifications"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/multiple_deposits/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_multiple_deposit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"multiple_deposits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_files/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_nightly_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_files"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_posts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_nightly_post_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_posts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_organization_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_payee_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/people/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_person_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_portfolio_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/portfolio_capital_changes/new(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_portfolio_capital_change_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"portfolio_capital_changes"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/deposits/new(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_portfolio_deposit_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/disbursements/new(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_portfolio_disbursement_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/trust_beneficiaries/new(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_portfolio_trust_beneficiary_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_rails_conductor_inbound_email_source_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_deposits/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_recurring_deposit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_recurring_disbursement_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements/:recurring_disbursement_id/disbursements/new(.:format)
 * @param {any} recurring_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_recurring_disbursement_disbursement_path = __jsr.r({"recurring_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"recurring_disbursement_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id/new_signups_flags(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_signups_flags_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"new_signups_flags"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_transfer_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_beneficiary_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/benificiary_trust_accounts/new(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_beneficiary_benificiary_trust_account_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"benificiary_trust_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/disbursements/new(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_beneficiary_disbursement_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/fee_schedule_implementations/new(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_beneficiary_fee_schedule_implementation_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/payees/new(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_beneficiary_payee_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/recurring_disbursements/new(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_beneficiary_recurring_disbursement_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/disbursements/new(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_disbursement_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/fee_schedules/new(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_fee_schedule_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/portfolios/new(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_portfolio_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/trust_accounts/new(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_trust_account_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"trust_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/trust_beneficiaries/new(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_trust_trust_beneficiary_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /user/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_registration_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sign-in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign-in"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/unlock/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"unlock"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/wealth_accounts/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const new_wealth_account_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wealth_accounts"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_files/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nightly_file_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_files"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_files/:nightly_file_id/transfer_recon(.:format)
 * @param {any} nightly_file_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nightly_file_transfer_recon_path = __jsr.r({"nightly_file_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_files"],[2,[7,"/"],[2,[3,"nightly_file_id"],[2,[7,"/"],[2,[6,"transfer_recon"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nightly_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_posts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nightly_post_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_posts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_posts/:id/toggle_hidden(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nightly_post_toggle_hidden_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_posts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_hidden"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/nightly_posts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const nightly_posts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"nightly_posts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/dashboard/no_idv_register_and_activate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const no_idv_register_and_activate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"no_idv_register_and_activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/notifications(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const notifications_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/official_statement/:card_id/:month(.:format)
 * @param {any} card_id
 * @param {any} month
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const official_statement_path = __jsr.r({"card_id":{"r":true},"month":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[3,"card_id"],[2,[7,"/"],[2,[3,"month"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/on_hold_notification_icon(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const on_hold_disbursements_notification_icon_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"on_hold_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/transfers/one_time_transfer_agreement(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const one_time_transfer_agreement_dashboard_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"one_time_transfer_agreement"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/order_personalized_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_personalized_card_dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"order_personalized_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/valued_customer_card_ordering(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const order_valued_customer_cards_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"valued_customer_card_ordering"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/accounts(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_accounts_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organization_activations/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_activation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organization_activations"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/add_notes(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_add_notes_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"add_notes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/assign_customer_types(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_assign_customer_types_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"assign_customer_types"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/card_orders_csv(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_card_orders_csv_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"card_orders_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/cards(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_cards_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/clear_all_bad_transfers(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_clear_all_bad_transfers_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"clear_all_bad_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/clear_all_proposed_bulk_transfers(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_clear_all_proposed_bulk_transfers_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"clear_all_proposed_bulk_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/customer_service_note(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_customer_service_note_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"customer_service_note"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/disbursement_categories(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_disbursement_categories_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"disbursement_categories"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/expiring_cards/:expiring_card_id/toggle_reissue_rejected(.:format)
 * @param {any} organization_id
 * @param {any} expiring_card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_expiring_card_toggle_reissue_rejected_path = __jsr.r({"organization_id":{"r":true},"expiring_card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"expiring_cards"],[2,[7,"/"],[2,[3,"expiring_card_id"],[2,[7,"/"],[2,[6,"toggle_reissue_rejected"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/imported_bulk_card_orders(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_imported_bulk_card_orders_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"imported_bulk_card_orders"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/manually_process_expiring_cards(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_manually_process_expiring_cards_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"manually_process_expiring_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/process_expiring_cards(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_process_expiring_cards_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"process_expiring_cards"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/process_transfers(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_process_transfers_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"process_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/process_transfers_csv(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_process_transfers_csv_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"process_transfers_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/process_transfers_csv(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_review_csv_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"process_transfers_csv"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organization_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organization_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organization_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const organizations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/outbound_statement_requests/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const outbound_statement_request_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"outbound_statement_requests"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/outbound_statement_requests(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const outbound_statement_requests_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"outbound_statement_requests"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/multiple_deposits/parse(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parse_dashboard_organization_multiple_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"multiple_deposits"],[2,[7,"/"],[2,[6,"parse"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/multiple_deposits/parse(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const parse_multiple_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"multiple_deposits"],[2,[7,"/"],[2,[6,"parse"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:payee_id/disbursements/:id(.:format)
 * @param {any} payee_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payee_disbursement_path = __jsr.r({"payee_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"payee_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:payee_id/disbursements(.:format)
 * @param {any} payee_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payee_disbursements_path = __jsr.r({"payee_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"payee_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:payee_id/recurring_disbursements/:id(.:format)
 * @param {any} payee_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payee_recurring_disbursement_path = __jsr.r({"payee_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"payee_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees/:payee_id/recurring_disbursements(.:format)
 * @param {any} payee_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payee_recurring_disbursements_path = __jsr.r({"payee_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"payee_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/payees(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const payees_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/pending(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/deposits/pending(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_client_deposits_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/clients/:dashboard_client_id/recurring_deposits/pending(.:format)
 * @param {any} dashboard_client_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_client_recurring_deposits_path = __jsr.r({"dashboard_client_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"clients"],[2,[7,"/"],[2,[3,"dashboard_client_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/deposits/pending(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/deposits/pending(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_organization_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/organizations/:dashboard_organization_id/recurring_deposits/pending(.:format)
 * @param {any} dashboard_organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_organization_recurring_deposits_path = __jsr.r({"dashboard_organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"dashboard_organization_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/deposits/pending(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_portfolio_deposits_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/recurring_deposits/pending(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_portfolio_recurring_deposits_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_deposits/pending(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_dashboard_recurring_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/deposits/pending(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pending_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"deposits"],[2,[7,"/"],[2,[6,"pending"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/people(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const people_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/people/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/person_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"person_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/people/:id/send_sms(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_send_sms_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"send_sms"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/people/:id/sync_ssn(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_sync_ssn_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sync_ssn"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/people/:id/validate_ssn(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const person_validate_ssn_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"validate_ssn"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /ping(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ping_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"ping"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/portfolio_book_income_events(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_book_income_events_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"portfolio_book_income_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/portfolio_capital_changes/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_capital_change_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"portfolio_capital_changes"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/portfolio_capital_changes/:capital_change_id/holding_capital_changes(.:format)
 * @param {any} portfolio_id
 * @param {any} capital_change_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_capital_change_holding_capital_changes_path = __jsr.r({"portfolio_id":{"r":true},"capital_change_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"portfolio_capital_changes"],[2,[7,"/"],[2,[3,"capital_change_id"],[2,[7,"/"],[2,[6,"holding_capital_changes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/portfolio_capital_changes(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_capital_changes_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"portfolio_capital_changes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/daily_portfolio_closeouts/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_closeout_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/daily_portfolio_closeouts(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_closeouts_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/deposits(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_deposits_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/disbursements/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_disbursement_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/disbursements(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_disbursements_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/holdings/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_holding_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"holdings"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/holding_events/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_holding_event_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"holding_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/holding_events(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_holding_events_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"holding_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/holdings(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_holdings_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"holdings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/payees(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_payees_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/positions(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_positions_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/trust_beneficiaries(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_trust_beneficiaries_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/wealth_account_events/:id(.:format)
 * @param {any} portfolio_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_wealth_account_event_path = __jsr.r({"portfolio_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/wealth_account_events(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolio_wealth_account_events_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const portfolios_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/positions(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const positions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/positive_pay_check_files(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const positive_pay_check_files_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"positive_pay_check_files"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/organization_activations/pre_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const pre_order_organization_activations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organization_activations"],[2,[7,"/"],[2,[6,"pre_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/prepare_for_ach(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_for_ach_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"prepare_for_ach"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/prepare(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepare_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"prepare"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/prepared(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const prepared_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"prepared"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/portfolios/:portfolio_id/portfolio_book_income_events/preview(.:format)
 * @param {any} portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const preview_portfolio_book_income_events_path = __jsr.r({"portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"portfolio_id"],[2,[7,"/"],[2,[6,"portfolio_book_income_events"],[2,[7,"/"],[2,[6,"preview"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/primed(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const primed_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"primed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/balance_files/process(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const process_balance_file_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"balance_files"],[2,[7,"/"],[2,[6,"process"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/process_bulk_notifications(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const process_bulk_notifications_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"process_bulk_notifications"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/profile(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const profile_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"profile"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/provisional_credits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const provisional_credits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"provisional_credits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/redirect/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/representations/proxy/:signed_blob_id/:variation_key/*filename(.:format)
 * @param {any} signed_blob_id
 * @param {any} variation_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_blob_representation_proxy_path = __jsr.r({"signed_blob_id":{"r":true},"variation_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"representations"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_blob_id"],[2,[7,"/"],[2,[3,"variation_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/incinerate(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_incinerate_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"incinerate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/:inbound_email_id/reroute(.:format)
 * @param {any} inbound_email_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_reroute_path = __jsr.r({"inbound_email_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[3,"inbound_email_id"],[2,[7,"/"],[2,[6,"reroute"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails/sources(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_email_sources_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"sources"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/conductor/action_mailbox/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_conductor_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"conductor"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/direct_uploads(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_direct_uploads_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"direct_uploads"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_key/*filename(.:format)
 * @param {any} encoded_key
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_disk_service_path = __jsr.r({"encoded_key":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_key"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/properties(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_properties_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"properties"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/info/routes(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_info_routes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"info"],[2,[7,"/"],[2,[6,"routes"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/mailers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"mailers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mailgun/inbound_emails/mime(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mailgun_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mailgun"],[2,[7,"/"],[2,[6,"inbound_emails"],[2,[7,"/"],[2,[6,"mime"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/mandrill/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_mandrill_inbound_health_check_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"mandrill"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/postmark/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_postmark_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"postmark"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/relay/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_relay_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"relay"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/action_mailbox/sendgrid/inbound_emails(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_sendgrid_inbound_emails_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"action_mailbox"],[2,[7,"/"],[2,[6,"sendgrid"],[2,[7,"/"],[2,[6,"inbound_emails"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/redirect/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"redirect"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/blobs/proxy/:signed_id/*filename(.:format)
 * @param {any} signed_id
 * @param {any} filename
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rails_service_blob_proxy_path = __jsr.r({"signed_id":{"r":true},"filename":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"blobs"],[2,[7,"/"],[2,[6,"proxy"],[2,[7,"/"],[2,[3,"signed_id"],[2,[7,"/"],[2,[5,[3,"filename"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/reactivate_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reactivate_card_dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"reactivate_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/ready(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"ready"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/ready_for_ach(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_for_ach_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"ready_for_ach"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/ready_not_flagged(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ready_not_flagged_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"ready_not_flagged"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/recent(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recent_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"recent"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_deposits/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recurring_deposit_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_deposits"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_deposits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recurring_deposits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_deposits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recurring_disbursement_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements/:recurring_disbursement_id/disbursements/:id(.:format)
 * @param {any} recurring_disbursement_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recurring_disbursement_disbursement_path = __jsr.r({"recurring_disbursement_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"recurring_disbursement_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements/:recurring_disbursement_id/disbursements(.:format)
 * @param {any} recurring_disbursement_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recurring_disbursement_disbursements_path = __jsr.r({"recurring_disbursement_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"recurring_disbursement_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/recurring_disbursements(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recurring_disbursements_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/recurring_notification_icon(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const recurring_disbursements_notification_icon_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"recurring_notification_icon"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const redirect_to_www_path = __jsr.r({"path":{"r":true},"format":{}}, [2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /share/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const referral_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"share"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/referral_incentives/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const referral_incentive_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"referral_incentives"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/referral_incentives(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const referral_incentives_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"referral_incentives"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/referrals/:slug(.:format)
 * @param {any} slug
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const referral_preview_path = __jsr.r({"slug":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"referrals"],[2,[7,"/"],[2,[3,"slug"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/refresh_balance(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_balance_dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"refresh_balance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/refresh_status(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refresh_status_dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"refresh_status"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fees/:id/refund(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const refund_account_fee_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fees"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"refund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/register_cardholder_and_activate(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const register_cardholder_and_activate_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"register_cardholder_and_activate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/register_cardholder_and_activate_reissue(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const register_cardholder_and_activate_reissue_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"register_cardholder_and_activate_reissue"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /member/cards/reissue_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reissue_card_dashboard_cards_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[6,"reissue_card"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/reissue_transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const reissue_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"reissue_transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/spending_settings/:id/relationships/:relationship_name(.:format)
 * @param {any} id
 * @param {any} relationship_name
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_from_relationship_api_v2_spending_setting_path = __jsr.r({"id":{"r":true},"relationship_name":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"spending_settings"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"relationships"],[2,[7,"/"],[2,[3,"relationship_name"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_activations/:id/remove_from_self_signup_table(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_from_self_signup_table_account_activation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_activations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"remove_from_self_signup_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/remove_from_table(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const remove_organization_from_table_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"remove_from_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/:id/replace_and_fund_failed_credit(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const replace_and_fund_failed_credit_ach_action_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"replace_and_fund_failed_credit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v1/report_generation_requests/:id/report(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_api_v1_report_generation_request_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v1"],[2,[7,"/"],[2,[6,"report_generation_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/portfolios/:dashboard_portfolio_id/daily_portfolio_closeouts/report_for_date(.:format)
 * @param {any} dashboard_portfolio_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_for_date_dashboard_portfolio_closeouts_path = __jsr.r({"dashboard_portfolio_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"portfolios"],[2,[7,"/"],[2,[3,"dashboard_portfolio_id"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[6,"report_for_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/trusts/:dashboard_trust_id/daily_portfolio_closeouts/report_for_date(.:format)
 * @param {any} dashboard_trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const report_for_date_dashboard_trust_closeouts_path = __jsr.r({"dashboard_trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"dashboard_trust_id"],[2,[7,"/"],[2,[6,"daily_portfolio_closeouts"],[2,[7,"/"],[2,[6,"report_for_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/request_additional_card(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const request_additional_card_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"request_additional_card"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/requested(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const requested_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"requested"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/address_change_requests/:id/resolve(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const resolve_address_change_request_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"address_change_requests"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"resolve"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_activations/:id/restore_activation_and_account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_activation_and_account_account_activation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_activations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"restore_activation_and_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/:id/restore(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const restore_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/bank_accounts/:id/retrieve_plaid_balance(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retrieve_plaid_balance_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"retrieve_plaid_balance"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/retry_card_order(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retry_card_order_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"retry_card_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/retry_transfer_request(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const retry_transfer_request_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"retry_transfer_request"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const root_path = __jsr.r({}, [7,"/"]);

/**
 * Generates rails route to
 * /admin/ephemeral_csvs/:id/row_to_remediate(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const row_to_remediate_ephemeral_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ephemeral_csvs"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"row_to_remediate"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_api_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /api-docs
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rswag_ui_path = __jsr.r({}, [2,[7,"/"],[6,"api-docs"]]);

/**
 * Generates rails route to
 * /admin/rulesets/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ruleset_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rulesets"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/rulesets/:id/compare/:comparison(.:format)
 * @param {any} id
 * @param {any} comparison
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ruleset_compare_path = __jsr.r({"id":{"r":true},"comparison":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rulesets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"compare"],[2,[7,"/"],[2,[3,"comparison"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/rulesets/:id/recalculate_budget(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ruleset_recalculate_budget_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rulesets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"recalculate_budget"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/rulesets/:id/version_at/:timestamp(.:format)
 * @param {any} id
 * @param {any} timestamp
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ruleset_version_at_path = __jsr.r({"id":{"r":true},"timestamp":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rulesets"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"version_at"],[2,[7,"/"],[2,[3,"timestamp"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/rulesets(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const rulesets_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"rulesets"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/auto_transfers/schedule(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const schedule_dashboard_auto_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"auto_transfers"],[2,[7,"/"],[2,[6,"schedule"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/:account_id(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const select_account_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[3,"account_id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_activations/self_signup_table(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const self_signup_table_account_activations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_activations"],[2,[7,"/"],[2,[6,"self_signup_table"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sessions/password/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sessions_password_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sessions"],[2,[7,"/"],[2,[6,"password"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:id/setup_family_funding(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const setup_family_funding_organization_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"setup_family_funding"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:id/shipping_address(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const shipping_address_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"shipping_address"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /activate/:card_id(.:format)
 * @param {any} card_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_activation_path = __jsr.r({"card_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"activate"],[2,[7,"/"],[2,[3,"card_id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/organization_activations/:id/show_pre_order(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const show_pre_order_organization_activation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organization_activations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"show_pre_order"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /sidekiq
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sidekiq_path = __jsr.r({}, [2,[7,"/"],[6,"sidekiq"]]);

/**
 * Generates rails route to
 * /sign-in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_in_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign-in"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:id/sign_in_as_beneficiary(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_in_as_beneficiary_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_in_as_beneficiary"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/people/:id/sign_in_as_cardholder(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_in_as_cardholder_person_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_in_as_cardholder"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/sign_in_as_user(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_in_as_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"sign_in_as_user"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_out_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_up_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/questionnaire(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sign_up_questionnaire_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"questionnaire"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signup(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signup"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /signups/card/new/*path(.:format)
 * @param {any} path
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signups_card_path = __jsr.r({"path":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"card"],[2,[7,"/"],[2,[6,"new"],[2,[7,"/"],[2,[5,[3,"path"]],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/card/new(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const signups_card_new_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"card"],[2,[7,"/"],[2,[6,"new"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /sms(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const sms_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sms"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/transfers/soonest_transfer_date(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const soonest_transfer_date_dashboard_transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[6,"soonest_transfer_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /signups/continue_application(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const special_continue_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"continue_application"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/special_credits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const special_credits_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"special_credits"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/special_debits(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const special_debits_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"special_debits"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/special_issue_checks(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const special_issue_checks_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"special_issue_checks"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /signups/create_account(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const special_signup_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"signups"],[2,[7,"/"],[2,[6,"create_account"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries/:id/ssn(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const ssn_dashboard_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"ssn"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/submit_for_ach(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const submit_for_ach_ach_actions_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"submit_for_ach"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/budget_item_csvs/template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const template_budget_item_csvs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"budget_item_csvs"],[2,[7,"/"],[2,[6,"template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/holding_csvs/template(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const template_holding_csvs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"holding_csvs"],[2,[7,"/"],[2,[6,"template"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/old/TO_(:date).txt(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_cards_text_file_ach_actions_path = __jsr.r({"date":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"old"],[2,[7,"/"],[2,[6,"TO_"],[2,[1,[3,"date"]],[2,[8,"."],[2,[6,"txt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/TO_(:date).txt(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_master_text_file_ach_actions_path = __jsr.r({"date":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"TO_"],[2,[1,[3,"date"]],[2,[8,"."],[2,[6,"txt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/ach_actions/old/TO_MICRO(:date).txt(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const to_microdeposits_text_file_ach_actions_path = __jsr.r({"date":{},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"ach_actions"],[2,[7,"/"],[2,[6,"old"],[2,[7,"/"],[2,[6,"TO_MICRO"],[2,[1,[3,"date"]],[2,[8,"."],[2,[6,"txt"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/toggle_ban(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_ban_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_ban"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/transfers/:id/toggle_cancellation(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_cancellation_dashboard_transfer_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_cancellation"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/toggle_user_notification_opt_out(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const toggle_user_notification_opt_out_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"toggle_user_notification_opt_out"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/accounts/:id/transaction_list(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transaction_list_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transaction_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/official_statement/:account_id/transaction_report(.:format)
 * @param {any} account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transaction_report_path = __jsr.r({"account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"official_statement"],[2,[7,"/"],[2,[3,"account_id"],[2,[7,"/"],[2,[6,"transaction_report"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/cards/:id/transfer_funds(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_funds_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"cards"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"transfer_funds"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/:transfer_id/instant_fund(.:format)
 * @param {any} transfer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_instant_fund_path = __jsr.r({"transfer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"transfer_id"],[2,[7,"/"],[2,[6,"instant_fund"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/:transfer_id/skip(.:format)
 * @param {any} transfer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_skip_path = __jsr.r({"transfer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"transfer_id"],[2,[7,"/"],[2,[6,"skip"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers/:transfer_id/unskip(.:format)
 * @param {any} transfer_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfer_unskip_path = __jsr.r({"transfer_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[2,[7,"/"],[2,[3,"transfer_id"],[2,[7,"/"],[2,[6,"unskip"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/transfers(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const transfers_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"transfers"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_ach_csvs/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_ach_csv_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_ach_csvs"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_ach_csvs(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_ach_csvs_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_ach_csvs"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiaries_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/benificiary_trust_accounts/:id(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_benificiary_trust_account_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"benificiary_trust_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/benificiary_trust_accounts(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_benificiary_trust_accounts_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"benificiary_trust_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/disbursements/:id(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_disbursement_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/disbursements(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_disbursements_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/fee_schedule_implementations(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_fee_schedule_implementations_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/holding_events/:id(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_holding_event_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"holding_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/holding_events(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_holding_events_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"holding_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/payees/:id(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_payee_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"payees"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/payees(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_payees_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"payees"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/recurring_disbursements/:id(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_recurring_disbursement_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/recurring_disbursements(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_recurring_disbursements_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"recurring_disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/wealth_account_events/:id(.:format)
 * @param {any} trust_beneficiary_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_wealth_account_event_path = __jsr.r({"trust_beneficiary_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:trust_beneficiary_id/wealth_account_events(.:format)
 * @param {any} trust_beneficiary_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_beneficiary_wealth_account_events_path = __jsr.r({"trust_beneficiary_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"trust_beneficiary_id"],[2,[7,"/"],[2,[6,"wealth_account_events"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/disbursements/:id(.:format)
 * @param {any} trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_disbursement_path = __jsr.r({"trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/disbursements(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_disbursements_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"disbursements"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/fee_schedules(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_fee_schedules_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"fee_schedules"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/portfolios(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_portfolios_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"portfolios"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/positions(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_positions_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/trust_accounts/:id(.:format)
 * @param {any} trust_id
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_trust_account_path = __jsr.r({"trust_id":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"trust_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/trust_accounts(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_trust_accounts_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"trust_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts/:trust_id/trust_beneficiaries(.:format)
 * @param {any} trust_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trust_trust_beneficiaries_path = __jsr.r({"trust_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[2,[7,"/"],[2,[3,"trust_id"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trusts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const trusts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trusts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/unapprove_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unapprove_all_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unapprove_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/unapprove(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unapprove_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unapprove"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/trust_beneficiaries/:id/undestroy(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const undestroy_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"undestroy"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/unwaive_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unwaive_all_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unwaive_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/unwaive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const unwaive_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unwaive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fee_settings/:organization_id/update_all_by_org(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_all_by_org_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fee_settings"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"update_all_by_org"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_category_codes/update_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_all_merchant_category_codes_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_category_codes"],[2,[7,"/"],[2,[6,"update_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/merchant_name_clusters/update_all(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_all_merchant_name_clusters_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"merchant_name_clusters"],[2,[7,"/"],[2,[6,"update_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/subaccounts/update_all(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_all_user_trust_beneficiary_permissions_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"subaccounts"],[2,[7,"/"],[2,[6,"update_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/account_fee_settings/:organization_id/update_default_account_fee_settings(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_default_account_fee_settings_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"account_fee_settings"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"update_default_account_fee_settings"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/import_file_lines/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_file_line_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"import_file_lines"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/update_jaffe_roles(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_jaffe_roles_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_jaffe_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/update_next_charge_date(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_next_charge_date_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_next_charge_date"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/update_next_charge_dates(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_next_charge_dates_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_next_charge_dates"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/update_organization_roles(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_organization_roles_user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_organization_roles"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /rails/active_storage/disk/:encoded_token(.:format)
 * @param {any} encoded_token
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_rails_disk_service_path = __jsr.r({"encoded_token":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"rails"],[2,[7,"/"],[2,[6,"active_storage"],[2,[7,"/"],[2,[6,"disk"],[2,[7,"/"],[2,[3,"encoded_token"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/trust_beneficiaries/:id/update_ssn(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const update_ssn_dashboard_trust_beneficiary_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"trust_beneficiaries"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"update_ssn"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/upload_card_batch_file(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_card_batch_file_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"upload_card_batch_file"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/shipment_informations/upload(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const upload_shipment_informations_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"shipment_informations"],[2,[7,"/"],[2,[6,"upload"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/account_list(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_account_list_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"account_list"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_home_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/password(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_password_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"password"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /users/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_registration_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/restore(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_restore_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"restore"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/user_search(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_search_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"user_search"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /sign-in(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_session_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"sign-in"],[1,[2,[8,"."],[3,"format"]]]]]);

/**
 * Generates rails route to
 * /user/sign_out(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_sign_out_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"sign_out"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:user_id/subaccounts(.:format)
 * @param {any} user_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_trust_beneficiary_permissions_path = __jsr.r({"user_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"user_id"],[2,[7,"/"],[2,[6,"subaccounts"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /user/unlock(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const user_unlock_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"user"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/users(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /member/users/edit(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_edit_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"edit"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/organization_select(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_organization_select_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[6,"organization_select"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/users/:id/unlock(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const users_unlock_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"users"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"unlock"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/registration_jobs/validate_identity(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const validate_identity_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"registration_jobs"],[2,[7,"/"],[2,[6,"validate_identity"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/organizations/:organization_id/valued_customer_card_ordering(.:format)
 * @param {any} organization_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const valued_customer_card_ordering_path = __jsr.r({"organization_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"organizations"],[2,[7,"/"],[2,[3,"organization_id"],[2,[7,"/"],[2,[6,"valued_customer_card_ordering"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/addresses/:id/verification(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verification_address_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"addresses"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"verification"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /api/v2/people/:id/verify(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_api_v2_person_path = __jsr.r({"id":{"r":true},"format":{"d":"json"}}, [2,[7,"/"],[2,[6,"api"],[2,[7,"/"],[2,[6,"v2"],[2,[7,"/"],[2,[6,"people"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"verify"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/bank_accounts/:id/verify_bank_account(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const verify_bank_account_dashboard_bank_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"bank_accounts"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"verify_bank_account"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/versions/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const version_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/versions/:model/:id(.:format)
 * @param {any} model
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const version_for_model_path = __jsr.r({"model":{"r":true},"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"versions"],[2,[7,"/"],[2,[3,"model"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /member/dashboard/in_product_ads/:id/viewed(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const viewed_dashboard_in_product_ad_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"member"],[2,[7,"/"],[2,[6,"dashboard"],[2,[7,"/"],[2,[6,"in_product_ads"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"viewed"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedules/:id/waive_all(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const waive_all_fee_schedule_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedules"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"waive_all"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/fee_schedule_implementations/:id/waive(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const waive_fee_schedule_implementation_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"fee_schedule_implementations"],[2,[7,"/"],[2,[3,"id"],[2,[7,"/"],[2,[6,"waive"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/wealth_accounts/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wealth_account_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wealth_accounts"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/wealth_account_events/:id(.:format)
 * @param {any} id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wealth_account_event_path = __jsr.r({"id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wealth_account_events"],[2,[7,"/"],[2,[3,"id"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/wealth_account_events(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wealth_account_events_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wealth_account_events"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/wealth_accounts/:wealth_account_id/positions(.:format)
 * @param {any} wealth_account_id
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wealth_account_positions_path = __jsr.r({"wealth_account_id":{"r":true},"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wealth_accounts"],[2,[7,"/"],[2,[3,"wealth_account_id"],[2,[7,"/"],[2,[6,"positions"],[1,[2,[8,"."],[3,"format"]]]]]]]]]]]);

/**
 * Generates rails route to
 * /admin/wealth_accounts(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const wealth_accounts_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"wealth_accounts"],[1,[2,[8,"."],[3,"format"]]]]]]]);

/**
 * Generates rails route to
 * /admin/calls/who_is_on_the_phone(.:format)
 * @param {object | undefined} options
 * @returns {string} route path
 */
export const who_is_on_the_phone_path = __jsr.r({"format":{}}, [2,[7,"/"],[2,[6,"admin"],[2,[7,"/"],[2,[6,"calls"],[2,[7,"/"],[2,[6,"who_is_on_the_phone"],[1,[2,[8,"."],[3,"format"]]]]]]]]]);

