import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { createRoot } from 'react-dom/client';
import DashboardDisbursementApprovalActions from './DashboardDisbursementApprovalActions';
import DisbursementButtons from './DisbursementButtons';
import DashboardDisbursementDetails from 'react/member/components/dashboard/disbursements/disbursement_details/DashboardDisbursementDetails';
import DashboardRecurringDisbursementDetails from 'react/member/components/dashboard/disbursements/recurring_disbursement_details/DashboardRecurringDisbursementDetails';
import userRoleShape from 'react/member/shapes/UserRoleShape';
import DashboardBudgetItemStore from 'react/member/stores/DashboardBudgetItemStore';
import DashboardDisbursementStore from 'react/member/stores/DashboardDisbursementStore';
import DashboardDraftDisbursementStore from 'react/member/stores/DashboardDraftDisbursementStore';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import Datatable from 'react/shared/components/Datatable';
import SubSectionHeader from 'react/shared/components/SubSectionHeader';
import InfoTooltip from 'react/shared/components/tooltips/InfoTooltip';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import PALETTE from 'react/shared/theme/palette';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardPaginatedDisbursementList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isEmpty: true,
      needsRefresh: false,
    };

    this._isMounted = false;

    bindAll(this);
  }

  componentDidMount() {
    if (this.props.isRecurring) {
      RecurringDisbursementStore.on('recurringDisbursement.update', this.setNeedsRefresh);
      RecurringDisbursementStore.on('recurringDisbursement.destroy', this.setNeedsRefresh);
    } else {
      DashboardBudgetItemStore.on('budgetItem.create', this.setNeedsRefresh);
      DashboardDisbursementStore.on('disbursement.update', this.setNeedsRefresh);
      DashboardDisbursementStore.on('disbursement.delete', this.setNeedsRefresh);
      DashboardDraftDisbursementStore.on('draftDisbursement.update', this.setNeedsRefresh);
    }

    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;

    DashboardBudgetItemStore.off('budgetItem.create', this.setNeedsRefresh);
    DashboardDisbursementStore.off('disbursement.update', this.setNeedsRefresh);
    DashboardDraftDisbursementStore.off('draftDisbursement.update', this.setNeedsRefresh);
    RecurringDisbursementStore.off('recurringDisbursement.update', this.setNeedsRefresh);
    RecurringDisbursementStore.off('recurringDisbursement.destroy', this.setNeedsRefresh);
  }

  afterRefresh() {
    if (this._isMounted) {
      this.setState({
        needsRefresh: false,
      });
    }
  }

  setNeedsRefresh() {
    if (this._isMounted) {
      this.setState({
        needsRefresh: true,
      });
    }
  }

  isClientView() {
    return !!this.props.clientSlug;
  }

  isOrganizationView() {
    return !this.isClientView() && !this.isPortfolioView() && !this.isTrustView();
  }

  isPortfolioView() {
    return !!this.props.portfolioId;
  }

  isTrustView() {
    return !!this.props.trustSlug;
  }

  isApprovedView() {
    return this.props.statusFilter === 'approved';
  }

  isApprovalRequiredView() {
    return [
      'additional_approval',
      'pending',
      'proposed',
      'proposed_beneficiary_requested',
    ].includes(this.props.statusFilter);
  }

  isCancelledView() {
    return this.props.statusFilter === 'cancelled';
  }

  isOnHoldView() {
    return ['proposed_on_hold', 'pending_on_hold', 'additional_approval_on_hold'].includes(
      this.props.statusFilter,
    );
  }

  isDraftView() {
    return this.props.statusFilter === 'draft';
  }

  associationId() {
    if (this.isClientView()) {
      return this.props.clientSlug;
    } else if (this.isTrustView()) {
      return this.props.trustSlug;
    } else if (this.isPortfolioView()) {
      return this.props.portfolioId;
    }
    return this.props.organizationSlug;
  }

  indexRoute() {
    const route = this.getRoute();

    if (this.associationId()) {
      return route(this.associationId(), { format: 'json' });
    }

    return route({ format: 'json' });
  }

  getRoute() {
    if (this.props.isRecurring) {
      return this.recurringIndexRoute();
    }
    if (this.isDraftView()) {
      return this.draftIndexRoute();
    }
    return this.oneTimeIndexRoute();
  }

  oneTimeIndexRoute() {
    if (this.isClientView()) {
      return RailsRoutes.dashboard_client_disbursements_path;
    }
    if (this.isTrustView()) {
      return RailsRoutes.dashboard_trust_disbursements_path;
    }
    if (this.isPortfolioView()) {
      return RailsRoutes.dashboard_portfolio_disbursements_path;
    }
    if (this.props.organizationSlug) {
      return RailsRoutes.dashboard_organization_disbursements_path;
    }
    return RailsRoutes.dashboard_disbursements_path;
  }

  recurringIndexRoute() {
    if (this.isClientView()) {
      return RailsRoutes.dashboard_client_recurring_disbursements_path;
    }
    if (this.isTrustView()) {
      return RailsRoutes.dashboard_trust_recurring_disbursements_path;
    }
    if (this.isPortfolioView()) {
      return RailsRoutes.dashboard_portfolio_recurring_disbursements_path;
    }
    if (this.props.organizationSlug) {
      return RailsRoutes.dashboard_organization_recurring_disbursements_path;
    }
    return RailsRoutes.dashboard_recurring_disbursements_path;
  }

  draftIndexRoute() {
    if (this.isClientView()) {
      return RailsRoutes.dashboard_client_draft_disbursements_path;
    }
    if (this.isTrustView()) {
      return RailsRoutes.dashboard_trust_draft_disbursements_path;
    }
    if (this.isPortfolioView()) {
      return RailsRoutes.dashboard_portfolio_draft_disbursements_path;
    }
    if (this.props.organizationSlug) {
      return RailsRoutes.dashboard_organization_draft_disbursements_path;
    }
    return RailsRoutes.dashboard_draft_disbursements_path;
  }

  showClientRoute(disbursement) {
    const { beneficiary } = disbursement;

    return RailsRoutes.dashboard_trust_client_path(beneficiary.trust.slug, beneficiary.slug);
  }

  shouldShowDraftColumns() {
    return this.isDraftView();
  }

  columns() {
    const amountStyle = this.shouldShowDraftColumns() ? {} : { width: 100 };
    return this.primaryColumns()
      .concat(this.clearedOnColumn())
      .concat(this.daysPendingColumns())
      .concat(this.clientColumns())
      .concat(this.trustColumns())
      .concat([
        <th className="js-datatable-col-payee" key="datatable-payee">
          Payee
        </th>,
        <th className="js-datatable-col-category" key="datatable-category">
          Category
        </th>,
        <th className="js-datatable-col-amount" key="datatable-amount" style={amountStyle}>
          Amount
        </th>,
      ])
      .concat(this.approvalColumns())
      .concat(this.endDateColumn())
      .concat(this.draftColumns())
      .concat([
        <th className="js-datatable-col-details" key="datatable-details" style={{ width: 75 }}>
          Details
        </th>,
      ]);
  }

  primaryColumns() {
    if (this.isCancelledView()) {
      return [
        <th
          className="js-datatable-col-process-date"
          key="datatable-process-date"
          style={{ width: 135 }}
        >
          Cancellation Date
        </th>,
      ];
    }
    if (this.props.isRecurring) {
      return [
        <th
          className="js-datatable-col-start-date"
          key="datatable-start-date"
          style={{ width: 125 }}
        >
          Start Date
        </th>,
      ];
    }
    const processDateStyle = this.shouldShowDraftColumns() ? { width: 100 } : { width: 135 };
    const tooltipText = `
      The date the disbursement was sent out - e.g. the date the check was put in the mail, the date the EFT was initiated.
    `;

    return [
      <th
        className="js-datatable-col-process-date"
        key="datatable-process-date"
        style={processDateStyle}
      >
        <InfoTooltip
          children={
            <span style={{ borderBottom: `1px dashed ${PALETTE.grey2}` }}>Process Date</span>
          }
          containerClass="process-date-tooltip"
          placement="bottom"
          tooltipStyle="width: 375px"
          tooltipText={tooltipText}
          trigger="hover"
        />
      </th>,
    ];
  }

  clearedOnColumn() {
    if (!this.isApprovedView()) {
      return [];
    }

    const tooltipText = `
      The date the funds left the account - e.g. the date the check was cashed, the date the EFT was removed from the account.
    `;

    return [
      <th
        className="js-datatable-col-cleared-date"
        key="datatable-cleared-date"
        style={{ width: 100 }}
      >
        <InfoTooltip
          children={<span style={{ borderBottom: `1px dashed ${PALETTE.grey2}` }}>Cleared On</span>}
          containerClass="process-date-tooltip"
          placement="bottom"
          tooltipStyle="width: 400px"
          tooltipText={tooltipText}
          trigger="hover"
        />
      </th>,
    ];
  }

  clientColumns() {
    if (this.isClientView()) {
      return [];
    }
    return [
      <th className="js-datatable-col-client" key="datatable-client">
        Client
      </th>,
    ];
  }

  daysPendingColumns() {
    if (this.isApprovalRequiredView() || this.isOnHoldView()) {
      return [
        <th
          className="js-datatable-col-days-pending"
          key="datatable-days-pending"
          style={{ width: 135 }}
        >
          Days Pending
        </th>,
      ];
    }
    return [];
  }

  trustColumns() {
    if (this.isTrustView() || this.isOrganizationView()) {
      return [
        <th className="js-datatable-col-portfolio" key="datatable-portfolio">
          Portfolio
        </th>,
      ];
    }
    return [];
  }

  endDateColumn() {
    if (!this.props.isRecurring) {
      return [];
    }

    return [
      <th className={`js-datatable-col-end-date`} key={`datatable-end-date`} style={{ width: 135 }}>
        End Date
      </th>,
    ];
  }

  approvalColumns() {
    if (this.isApprovalRequiredView()) {
      return [
        <th className="js-datatable-col-approval" key="datatable-approval">
          Approval
        </th>,
      ];
    }
    return [];
  }

  draftColumns() {
    if (this.shouldShowDraftColumns()) {
      return [
        <th className="js-datatable-col-expires" key="datatable-expires">
          Expires
        </th>,
        <th className="js-datatable-col-actions" key="datatable-actions" style={{ minWidth: 220 }}>
          Resume/Delete
        </th>,
      ];
    }
  }

  formatClient(data, _type, record) {
    return this.isClientView()
      ? `${data}`
      : `<a href="${this.showClientRoute(record)}" target="_blank">${data}</a>`;
  }

  handleDetailClick(e) {
    const $target = $(e.target);
    const $tr = $target.closest('tr');

    if ($target.hasClass('angle-down--open')) {
      $target.removeClass('angle-down--open');
      $target.text('View');
      $tr.next().hide();
      $tr.removeClass('row-highlight');
    } else {
      $target.addClass('angle-down--open');
      $target.text('Close');
      $tr.next().show();
      $tr.addClass('row-highlight');
    }
  }

  formatPayeeDescription(data, _type, _record) {
    return `<div>${data}</div>`;
  }

  formatPaymentMethod(paymentMethod) {
    return $tlf.domains.paymentMethodsMap[paymentMethod];
  }

  formatMoney(amount) {
    if (amount) {
      return asMoney(Number(amount));
    }
  }

  formatDate(date) {
    if (date) {
      return moment(date).format('L');
    }
  }

  formatExpires(days) {
    if (days) {
      const expiresIn = days == 0 ? 'Today' : `${days} Day(s)`;
      const expiresInDiv =
        days <= 7 ? `<div class="red">${expiresIn}</div>` : `<div>${expiresIn}</div>`;

      return `${expiresInDiv}`;
    }
  }

  formatProcessDate(date, _for, disbursement) {
    if (date) {
      return this.formatDate(date);
    }
    if (disbursement.payment_method === 'Card') {
      return 'Immediately on Approval';
    }
    return 'On Approval';
  }

  formatClearedOnDate(date) {
    if (date) {
      return this.formatDate(date);
    }
  }

  formatFromNow(date) {
    if (date) {
      return moment(date).fromNow(true);
    }
  }

  columnDefs() {
    let primaryColumn;
    if (this.isCancelledView()) {
      primaryColumn = {
        targets: ['js-datatable-col-process-date'],
        data: 'cancelled_at',
        render: this.formatProcessDate,
      };
    } else {
      primaryColumn = {
        targets: ['js-datatable-col-process-date'],
        data: 'delivery_date',
        render: this.formatProcessDate,
      };
    }

    return [
      primaryColumn,
      {
        targets: ['js-datatable-col-cleared-date'],
        data: 'cleared_date',
        render: this.formatClearedOnDate,
      },
      {
        targets: ['js-datatable-col-client'],
        data: 'beneficiary.name',
        render: this.formatClient,
        className: 'limited-width',
      },
      {
        targets: ['js-datatable-col-days-pending'],
        data: 'created_at',
        render: this.formatFromNow,
      },
      {
        targets: ['js-datatable-col-portfolio'],
        data: 'portfolio.name',
        className: 'limited-width',
      },
      {
        targets: ['js-datatable-col-payee'],
        data: 'payee_description',
        render: this.formatPayeeDescription,
        className: 'limited-width',
      },
      {
        targets: ['js-datatable-col-category'],
        data: 'category',
      },
      {
        targets: ['js-datatable-col-amount'],
        data: 'amount',
        render: this.formatMoney,
      },
      {
        targets: ['js-datatable-col-payment-method'],
        data: 'payment_method',
        render: this.formatPaymentMethod,
      },
      {
        targets: ['js-datatable-col-start-date'],
        data: 'start_date',
        render: this.formatDate,
      },
      {
        targets: ['js-datatable-col-status'],
        data: 'display_status',
      },
      {
        targets: ['js-datatable-col-approval'],
        orderable: false,
        data: null,
        className: 'nowrap min-width-170',
        defaultContent: '<div class="js-approval-actions"></div>',
      },
      {
        targets: ['js-datatable-col-end-date'],
        data: 'end_date',
        render: this.formatDate,
      },
      {
        targets: ['js-datatable-col-expires'],
        data: 'expires_in',
        render: this.formatExpires,
      },
      {
        targets: ['js-datatable-col-actions'],
        orderable: false,
        data: null,
        defaultContent: '<div class="draft-disbursement-buttons"></div>',
      },
      {
        targets: ['js-datatable-col-details'],
        orderable: false,
        data: null,
        defaultContent: '<div class="js-details-actions"></div>',
      },
    ];
  }

  handleDeletingDraft() {
    this.setState({
      needsRefresh: true,
    });
  }

  serverParams() {
    const statusFilterParam = this.props.statusFilter || 'approved';
    return {
      status_filter: statusFilterParam.toLowerCase(),
    };
  }

  initialSort() {
    return this.props.defaultSort || [[0, 'desc']];
  }

  approvalActionsComponent(obj) {
    if (this.props.isRecurring) {
      return <DashboardDisbursementApprovalActions recurringDisbursement={obj} />;
    }
    return <DashboardDisbursementApprovalActions disbursement={obj} />;
  }

  detailsComponent(obj) {
    if (this.props.isRecurring) {
      return (
        <DashboardRecurringDisbursementDetails
          recurringDisbursement={obj}
          statusFilter={this.props.statusFilter}
          userRoles={this.props.userRoles}
        />
      );
    }
    return (
      <DashboardDisbursementDetails
        disbursement={obj}
        editExternalCheckDisposition={this.props.editExternalCheckDisposition}
        payees={this.props.payees}
        renderOnHoldDisbursements={this.props.renderOnHoldDisbursements}
        showSendDisbursementBackToPending={this.props.showSendDisbursementBackToPending}
        statusFilter={this.props.statusFilter}
        userRoles={this.props.userRoles}
      />
    );
  }

  beforeDraw(settings) {
    // The DataTables API does not provide a safe way of accessing this information, so we must extract it manually.
    const totalCount = settings.json ? settings.json.recordsTotal : 0;

    if (this._isMounted) {
      this.setState({
        isEmpty: !totalCount,
      });
    }
  }

  afterDraw(settings) {
    const api = new $.fn.DataTable.Api(settings);
    if (!api.data().length) {
      return;
    }

    return api
      .rows()
      .nodes()
      .each((row, i) => {
        const $row = $(row);
        const $approvalActionsContainer = $row.find('.js-approval-actions');
        const $detailsActionsContainer = $row.find('.js-details-actions');
        const $disbursementButtonsContainer = $row.find('.draft-disbursement-buttons');
        const $detailsCell = $('<td>').attr('colspan', this.columns().length);
        const data = api.data()[i];

        $detailsCell.addClass('nopadding');

        $row.after($('<tr>').addClass('table-slidedown-tr').hide().append($detailsCell));

        createRoot($detailsCell.get(0)).render(this.detailsComponent(data));

        if ($disbursementButtonsContainer.length) {
          createRoot($disbursementButtonsContainer.get(0)).render(
            <DisbursementButtons
              id={data.id}
              onDeleteDraft={this.handleDeletingDraft}
              onResumeDraft={this.props.onResumeDraft}
            />,
          );
        }

        if ($approvalActionsContainer.length) {
          createRoot($approvalActionsContainer.get(0)).render(this.approvalActionsComponent(data));
        }

        if ($detailsActionsContainer.length) {
          createRoot($detailsActionsContainer.get(0)).render(
            <TrueLinkButton
              className="js-view-details"
              onClick={this.handleDetailClick}
              size="small"
              variant="neutral"
            >
              View
            </TrueLinkButton>,
          );
        }
      });
  }

  getTitleText() {
    switch (this.props.statusFilter) {
      case 'additional_approval':
        if (this.props.isRecurring) {
          return 'Recurring Disbursements Pending Additional Approval';
        }
        return 'Disbursements Pending Additional Approval';

      case 'approved':
        if (this.props.isRecurring) {
          return 'Approved Recurring Disbursements';
        }
        return 'Approved Disbursements';

      case 'cancelled':
        if (this.props.isRecurring) {
          return 'Cancelled Recurring Disbursements';
        }
        return 'Cancelled Disbursements';

      case 'pending':
        if (this.props.isRecurring) {
          return 'Recurring Disbursements Pending Approval';
        }
        return 'Disbursements Pending Approval';

      case 'proposed':
        if (this.props.isRecurring) {
          return 'Proposed Recurring Disbursements';
        }
        return 'Proposed Disbursements';

      case 'proposed_beneficiary_requested':
        return 'Beneficiary Requested Disbursements';
      case 'pending_on_hold':
        return 'Pending On Hold Disbursements';
      case 'additional_approval_on_hold':
        return 'Additional Approval On Hold Disbursements';
      case 'proposed_on_hold':
        return 'Proposed On Hold Disbursements';
      case 'draft':
        return 'Draft Disbursements';
    }
  }

  handleRender(table) {
    const text = this.getTitleText();
    return (
      <div>
        {text && <SubSectionHeader noMarginBottom>{text}</SubSectionHeader>}
        {table}
      </div>
    );
  }

  render() {
    const containerClass =
      this.state.isEmpty && (this.isApprovalRequiredView() || this.isCancelledView())
        ? 'hidden'
        : '';

    return (
      <div className={containerClass} style={{ marginBottom: 10 }}>
        <Datatable
          afterDraw={this.afterDraw}
          afterRefresh={this.afterRefresh}
          beforeDraw={this.beforeDraw}
          columnDefs={this.columnDefs()}
          columns={this.columns()}
          handleRender={this.handleRender}
          initialSort={this.initialSort()}
          needsRefresh={this.state.needsRefresh}
          refreshFullReset={false}
          route={this.indexRoute()}
          rowIdPrefix={this.props.statusFilter || 'approved'}
          serverParams={this.serverParams()}
        />
      </div>
    );
  }
}

DashboardPaginatedDisbursementList.propTypes = {
  clientSlug: PropTypes.string,
  organizationSlug: PropTypes.string,
  portfolioId: PropTypes.number,
  trustSlug: PropTypes.string,
  isRecurring: PropTypes.bool,
  statusFilter: PropTypes.string,
  editExternalCheckDisposition: PropTypes.bool,
  defaultSort: PropTypes.arrayOf(PropTypes.array),
  renderOnHoldDisbursements: PropTypes.bool,
  showSendDisbursementBackToPending: PropTypes.bool,
  payees: PropTypes.array,
  onResumeDraft: PropTypes.func,
  userRoles: userRoleShape.isRequired,
};

DashboardPaginatedDisbursementList.defaultProps = {
  defaultSort: [[0, 'desc']],
  onResumeDraft: () => {
    //do nothing
  },
};
