import PropTypes from 'prop-types';
import React from 'react';
import CardDetails from 'react/member/card/components/account_tab/card_status/CardDetails';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { organizationShape } from 'react/member/card/shapes/OrganizationShape';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';

export default function Open({
  account,
  card,
  cardholder,
  cardBalance,
  cardBreached,
  cardLast4,
  cardExpiryDate,
  onCardReissueSuccess,
  organization,
  showExpiringCardBox,
  status,
}) {
  const note = 'Active and can be used for purchases.';
  return (
    <CardDetails
      account={account}
      card={card}
      cardBalance={cardBalance}
      cardBreached={cardBreached}
      cardLast4={cardLast4}
      cardholder={cardholder}
      expiryDate={cardExpiryDate}
      note={note}
      onCardReissueSuccess={onCardReissueSuccess}
      organization={organization}
      showExpiringCardBox={showExpiringCardBox}
      status={status}
    />
  );
}

Open.propTypes = {
  account: accountShape,
  card: cardShape,
  cardholder: cardholderShape,
  cardBalance: PropTypes.number,
  cardBreached: PropTypes.bool,
  cardExpiryDate: PropTypes.string,
  cardLast4: PropTypes.string.isRequired,
  onCardReissueSuccess: PropTypes.func.isRequired,
  organization: organizationShape.isRequired,
  showExpiringCardBox: PropTypes.bool,
  status: PropTypes.oneOf([CARD_STATUSES.OPEN_CARD_STATUS]).isRequired,
};
