import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import _extend from 'underscore/modules/extend';
import SaveOrCancelButtons from './SaveOrCancelButtons';
import TrashCan from 'images/trash-can.png';
import DashboardActions from 'react/member/actions/dashboard_actions';
import DashboardCardTransactionNoteStore from 'react/member/stores/DashboardCardTransactionNoteStore';
import AreYouSurePopUp from 'react/shared/components/popups/AreYouSurePopUp';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';
import bindAll from 'react/shared/utils/bind_all';

export default class TransactionNote extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      transactionNote: null,
      showForm: false,
      showDestroyModal: false,
    };

    bindAll(this);
  }

  componentDidMount() {
    DashboardCardTransactionNoteStore.on('cardTransactionNotes.fetch', this.onFetchNotes);
    DashboardCardTransactionNoteStore.on('cardTransactionNote.create', this.onCreateNote);
    DashboardActions.fetchCardTransactionNotes(this.props.cardTransactionId);
  }

  componentWillUnmount() {
    DashboardCardTransactionNoteStore.off('cardTransactionNotes.fetch', this.onFetchNotes);
    DashboardCardTransactionNoteStore.off('cardTransactionNote.create', this.onCreateNote);
  }

  onFetchNotes(notes) {
    const note = notes[0];
    if (note && note.card_transaction_id === Number(this.props.cardTransactionId)) {
      this.setState({ transactionNote: notes[0] });
    }
  }

  onCreateNote(note) {
    if (note && note.card_transaction_id === Number(this.props.cardTransactionId)) {
      this.setState({ transactionNote: note });
      this.hideForm();
    }
  }

  updateNoteContent(evt) {
    const updatedNote = {
      note_content: evt.target.value,
      card_transaction_id: this.props.cardTransactionId,
    };
    this.setState({ transactionNote: _extend(this.state.transactionNote, updatedNote) });
  }

  saveNoteContent(_evt) {
    const { card_transaction_id, note_content } = this.state.transactionNote;
    const cardTransactionNote = { card_transaction_id, note_content };
    if (
      this.state.transactionNote.card_transaction_id === null ||
      this.state.transactionNote.note_content == ''
    ) {
      Truelink.flash('alert', 'Please enter a value');
    } else if (this.state.transactionNote.updated_at) {
      Truelink.flash('success', 'Updated the note.');
      this.hideForm();
      DashboardActions.updateCardTransactionNote(this.state.transactionNote.id, {
        card_transaction_note: cardTransactionNote,
      });
    } else {
      Truelink.flash('success', 'Added the note.');
      DashboardActions.createCardTransactionNote({ card_transaction_note: cardTransactionNote });
    }
  }

  destroyNote(_evt) {
    Truelink.flash('success', 'Deleted the note.');
    this.resetTransactionNote();
    this.hideDestroyModal();
    DashboardActions.destroyCardTransactionNote(this.state.transactionNote.id);
  }

  showDestroyModal(e) {
    e.preventDefault();
    this.setState({ showDestroyModal: true });
  }

  hideDestroyModal() {
    this.setState({ showDestroyModal: false });
  }

  newNote(e) {
    e.preventDefault();
    this.setState(
      {
        transactionNote: {
          note_content: '',
          card_transaction_id: this.props.cardTransactionId,
        },
        showForm: true,
      },
      () => {
        $(`#transaction_note_content${this.props.cardTransactionId}`).focus();
      },
    );
  }

  showForm(e) {
    e.preventDefault();
    this.setState({ showForm: true }, () => {
      $(`#transaction_note_content${this.props.cardTransactionId}`).focus();
    });
  }

  hideForm() {
    this.setState({ showForm: false });
  }

  resetTransactionNote() {
    this.setState({ transactionNote: null });
  }

  saveOnEnter(e) {
    // Enter key = keycode 13
    if (e.which === 13 && e.target.value.length > 0) {
      this.saveNoteContent();
    }
  }

  render() {
    let buttonsSection, contentSection;
    if (this.state.showForm) {
      contentSection = (
        <input
          defaultValue={this.state.transactionNote.note_content}
          id={`transaction_note_content${this.props.cardTransactionId}`}
          name="card_transaction_note[note_content]"
          onChange={this.updateNoteContent}
          onKeyUp={this.saveOnEnter}
          style={{ width: '100%' }}
          type="text"
        />
      );
      buttonsSection = (
        <div className="nowrap" style={{ paddingLeft: '36px' }}>
          <SaveOrCancelButtons onCancel={this.hideForm} onSave={this.saveNoteContent} />
        </div>
      );
    } else if (!this.state.transactionNote?.updated_at) {
      contentSection = this.props.canEdit && (
        <TrueLinkLink ariaLabel="Add a transaction note" onClick={this.newNote}>
          + Add note
        </TrueLinkLink>
      );
      buttonsSection = null;
    } else {
      contentSection = (
        <div className="flex-1">
          <div className="attachment__text">{this.state.transactionNote.note_content}</div>
          <div className="attachment__meta flex flex--space-between">
            <div>
              {this.props.canEdit && <TrueLinkLink onClick={this.showForm}>Edit</TrueLinkLink>}
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;
              {moment(this.state.transactionNote.updated_at).format('M/D/YY h:mm A')}
              &nbsp;&nbsp;&bull;&nbsp;&nbsp;
              {this.state.transactionNote.author}
            </div>
            {this.props.canEdit && (
              <TrueLinkButton
                onClick={this.showDestroyModal}
                style={{ border: 0, background: 'none' }}
                variant="none"
              >
                <img alt="Delete note" height={23} src={TrashCan} width={23} />
              </TrueLinkButton>
            )}
          </div>
        </div>
      );
      buttonsSection = null;
    }

    return (
      <div className="new-form__section new-form--compact new-form__section--inset transaction_note">
        <div className="new-form-field">
          <div className="new-form__label">Notes:</div>
          <div className="new-form__data flex flex--space-between">
            {contentSection}
            {buttonsSection}
          </div>
        </div>
        {this.state.showDestroyModal && (
          <AreYouSurePopUp
            key={this.state.transactionNote.id}
            messageText={'Are you sure you want to delete this note?'}
            onClose={this.hideDestroyModal}
            onSubmit={this.destroyNote}
            openModal={this.state.showDestroyModal}
            submitText={'Yes, delete'}
          />
        )}
      </div>
    );
  }
}

TransactionNote.propTypes = {
  canEdit: PropTypes.bool.isRequired,
  cardTransactionId: PropTypes.string.isRequired,
};
