import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import childrenShape from 'react/shared/shapes/children';
import {
  blue,
  blueLight,
  blueDark,
  emerald,
  emeraldLight,
  evergreen,
  white,
} from 'react/shared/theme/palette';
import { pressedEnterOrSpaceButton } from 'react/shared/utils/KeyboardEvents';

const useStyles = makeStyles({
  root: {
    textDecoration: 'none',
    color: blue,
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:visited': {
      color: blueDark,
    },
    '&.info': {
      textDecoration: 'none',
      background: blue,
      color: white,
      margin: '12px 7px 10px 5px',
      padding: '8px 15px 11px',
      borderRadius: '3px',
      '&:hover': {
        background: blue,
        textDecoration: 'none',
      },
      '&:visited': {
        color: white,
      },
    },
    '&.infoOutline': {
      textDecoration: 'none',
      border: `1px solid ${blue}`,
      background: white,
      color: blue,
      margin: '12px 7px 10px 5px',
      padding: '8px 15px 10px',
      borderRadius: '3px',
      fontWeight: 'bold',
      lineHeight: '130%',
      '&:hover': {
        background: blueLight,
        textDecoration: 'none',
      },
      '&:visited': {
        color: blueDark,
      },
    },
    '&.primary': {
      textDecoration: 'none',
      background: emerald,
      color: white,
      margin: '12px 7px 10px 5px',
      padding: '8px 15px 11px',
      borderRadius: '3px',
      '&:hover': {
        background: evergreen,
        textDecoration: 'none',
      },
      '&:visited': {
        color: white,
      },
    },
    '&.primaryOutline': {
      textDecoration: 'none',
      border: `1px solid ${emerald}`,
      color: emerald,
      margin: '12px 7px 10px 5px',
      padding: '8px 15px 10px',
      borderRadius: '3px',
      fontWeight: 'bold',
      lineHeight: '130%',
      '&:hover': {
        background: emeraldLight,
        textDecoration: 'none',
      },
      '&:visited': {
        color: evergreen,
      },
    },
  },
});

export default function TrueLinkLink({
  children,
  className,
  href,
  id,
  ariaLabel,
  onClick,
  target,
}) {
  const classes = useStyles();

  const handleClick = useCallback(
    (ev) => {
      // prevent attempt to navigate to fragment
      ev.preventDefault();
      onClick(ev);
    },
    [onClick],
  );

  const handleKeyDown = useCallback(
    (ev) => {
      // prevent attempt to scroll
      if (pressedEnterOrSpaceButton(ev)) {
        ev.preventDefault();
        onClick(ev);
      }
    },
    [onClick],
  );

  const combinedClassName = classNames(classes.root, { [className]: !!className });
  if (href && target === '_blank') {
    return (
      <a
        aria-label={ariaLabel}
        className={combinedClassName}
        href={href}
        id={id}
        rel="noreferrer"
        target={target}
      >
        {children}
      </a>
    );
  }
  if (href) {
    return (
      <a aria-label={ariaLabel} className={combinedClassName} href={href} id={id} target={target}>
        {children}
      </a>
    );
  }

  return (
    <a
      aria-label={ariaLabel}
      className={combinedClassName}
      href="#"
      id={id}
      onClick={handleClick}
      onKeyDown={handleKeyDown}
      role="button"
    >
      {children}
    </a>
  );
}

TrueLinkLink.propTypes = {
  ariaLabel: PropTypes.string,
  children: childrenShape.isRequired,
  className: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  target: PropTypes.string,
};
