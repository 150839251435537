import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ReportLostOrStolenModal from 'react/member/card/components/account_tab/card_status/actions/order_replacement_card/ReportLostOrStolenModal';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { organizationShape } from 'react/member/card/shapes/OrganizationShape';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';

const useStyles = makeStyles({
  orange_section_header: {
    backgroundColor: '#bf5a00',
    borderRadius: '6px 6px 0 0',
    boxSizing: 'border-box',
    color: '#fff0e2',
    marginBottom: 0,
    marginTop: '10px',
    padding: '12px 18px 9px',
  },
  orange_section_body: {
    backgroundColor: '#fff0e2',
    marginTop: 0,
    padding: '6px 12px 6px',
  },
});

export default function ExpiringCard({
  account,
  card,
  cardholder,
  expiryDate,
  onCardReissueSuccess,
  organization,
  status,
}) {
  const classes = useStyles();
  const isStableOrAble = [8, 9, 12].includes(account?.tl_card_program);
  const expiryDateLong = moment(expiryDate).format('MM/DD/YYYY');
  const expiryDateNumbers = moment(expiryDate).format('MM/YYYY');
  const tlHelpUrl =
    'https://help.admin.truelinkfinancial.com/hc/en-us/articles/27704688578715-Upcoming-Card-Expiration';
  const ableHelpUrl =
    'https://help.able.truelinkfinancial.com/hc/en-us/articles/31773881884955-Upcoming-ABLE-Card-Expiration';
  const helpUrl = isStableOrAble ? ableHelpUrl : tlHelpUrl;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleYesClick = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div className="expiring-card-box">
      <div className={`${classes.orange_section_header} header`}>
        <b>Card Expiring {expiryDateNumbers}</b>
      </div>
      <div className={`${classes.orange_section_body}`}>
        <p>
          <b>This True Link card will expire {expiryDateLong}. </b>
          Order a replacement now to ensure uninterrupted spending.
        </p>
        <p>
          {isStableOrAble &&
            'Please note that the current card will be deactivated when you order the replacement card. '}
          {!isStableOrAble &&
            'The current card will continue to work until it expires or the replacement card is activated. '}
          <a href={helpUrl} rel="noreferrer" target="_blank">
            Learn more about replacing an expiring card.
          </a>
        </p>
        <p>
          <b>Would you like to order a replacement for this expiring card?</b>
        </p>
        <p>
          <TrueLinkButton onClick={handleYesClick} variant="primary">
            Yes
          </TrueLinkButton>
        </p>
      </div>
      <div className="expiring-card-order-form">
        {isModalOpen && (
          <ReportLostOrStolenModal
            accountSlug={account.slug}
            adminMobile={account.administrator.mobile}
            allowAutomatedPayment={account.allow_automated_payment}
            cardBreached={card.is_breached}
            cardholderAddress={cardholder.address_as_string}
            cardholderCountryOfCitizenship={cardholder.countryOfCitizenship}
            cardholderMobile={cardholder.mobile}
            cardholderName={cardholder.name}
            disableSelectingLastKnownAddress={account.disable_selecting_last_known_address}
            forExpiringCard
            isStableOrAble={isStableOrAble}
            onClose={handleCloseModal}
            onSuccess={onCardReissueSuccess}
            organizationAddress={organization.address_as_string}
            otherShippingDestinationDefault={cardholder.address}
            paymentMethods={account.payment_method_options_for_card_fee}
            shippingAddress={account.shipping_address_as_string}
            shippingFee={account.shipping_fees}
            shippingSettings={account.shipping_settings}
            status={status}
          />
        )}
      </div>
    </div>
  );
}

ExpiringCard.propTypes = {
  account: accountShape.isRequired,
  card: cardShape.isRequired,
  cardholder: cardholderShape.isRequired,
  expiryDate: PropTypes.string.isRequired,
  onCardReissueSuccess: PropTypes.func.isRequired,
  organization: organizationShape.isRequired,
  status: PropTypes.oneOf([CARD_STATUSES.OPEN_CARD_STATUS, CARD_STATUSES.INACTIVE_CARD_STATUS])
    .isRequired,
};
