import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import api from './api.js';
import CardInInvestCardholderInfo from './card_in_invest/CardInInvestCardholderInfo';
import ClientDisconnectSection from './card_in_invest/client_disconnect_section/ClientDisconnectSection';
import CardStatus from './card_status/CardStatus';
import DashboardActions from 'react/member/actions/dashboard_actions';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import DashboardCardStore from 'react/shared/stores/DashboardCardStore';

export default function CardInInvestAccountTabContainer({ accountId, personName }) {
  const [cardBalance, setCardBalance] = useState(null);
  const [cardStatusDetails, setCardStatusDetails] = useState(null);
  const [backupCards, setBackupCards] = useState(null);
  const [pendingReissues, setPendingReissues] = useState(null);

  const handleCurrentCardRefresh = useCallback(() => {
    api
      .getCardStatusDetails(accountId)
      .then((response) => {
        if (response?.data?.account) {
          setCardStatusDetails(response.data);
          // refresh the balance in the dashboard header, too
          DashboardActions.refreshCurrentCardStatus(accountId);
        } else {
          Truelink.flash('error', 'There was an error retrieving card status information.');
        }
      })
      .catch(() =>
        Truelink.flash('error', 'There was an error retrieving card status information.'),
      );
  }, [accountId]);

  const handlePendingBackupCardRefresh = useCallback(() => {
    api.getPendingBackupCards(accountId).then((response) => setPendingReissues(response.data));
  }, [accountId]);

  const handleBackupCardRefresh = useCallback(() => {
    api.getBackupCards(accountId).then((response) => setBackupCards(response.data));
  }, [accountId]);

  const handleRefreshAll = useCallback(() => {
    handleCurrentCardRefresh();
    handlePendingBackupCardRefresh();
    handleBackupCardRefresh();
  }, [handleCurrentCardRefresh, handlePendingBackupCardRefresh, handleBackupCardRefresh]);

  useEffect(() => handleRefreshAll(), [accountId, handleRefreshAll]);

  // borrow card balance fetched by dashboard header
  useEffect(() => {
    const setCurrentCardBalance = (accountSlug, refreshedCardStatus) => {
      if (accountSlug !== accountId) return;
      setCardBalance(refreshedCardStatus.card_balance_unformatted);
    };
    DashboardCardStore.on('cards.refreshedCurrentCardStatus', setCurrentCardBalance);
    return function cleanup() {
      DashboardCardStore.off('cards.refreshedCurrentCardStatus', setCurrentCardBalance);
    };
  }, [accountId]);

  if (cardStatusDetails === null || backupCards === null || pendingReissues === null)
    return <LoadingIndicator />;

  const { account, card, cardholder, organization } = cardStatusDetails;
  const { locale_props: localeProps } = account;
  const { card_reissues: cardReissues } = pendingReissues;
  return (
    <div className="text-left">
      <div id="dashboard_account">
        <CardStatus
          account={account}
          backupCards={backupCards}
          card={card}
          cardBalance={cardBalance}
          cardReissues={cardReissues}
          cardholder={cardholder}
          localeProps={localeProps}
          onBackupCardActivationSuccess={handleRefreshAll}
          onBackupCardCloseSuccess={handleBackupCardRefresh}
          onCardActivationSuccess={handleCurrentCardRefresh}
          onCardCloseSuccess={handleCurrentCardRefresh}
          onCardDeactivateReactivateSuccess={handleCurrentCardRefresh}
          onCardReissueSuccess={handleRefreshAll}
          organization={organization}
        />
        <ClientDisconnectSection account={account} personName={personName} />
        <CardInInvestCardholderInfo card={card} cardholder={cardholder} />
      </div>
    </div>
  );
}

CardInInvestAccountTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
};
