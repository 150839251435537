import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import { asMoney } from 'react/shared/utils/Money';

export default function DepositRow(props) {
  const displayAmount = (amount) => {
    let amountVal = Number.parseFloat(amount).toFixed(2);
    if (!['-', '0'].includes(amountVal[0])) {
      amountVal = `+${amountVal}`;
    }
    return (
      <span>
        <p>{asMoney(amountVal)}</p>
      </span>
    );
  };

  const displayDate = (timestamp) => {
    try {
      const date = moment(timestamp);
      if (date.isBefore(moment().subtract(1, 'months'))) {
        return (
          <span>
            <p>{date.format('MMM D')}</p>
            <p>{date.fromNow()}</p>
          </span>
        );
      }
      return <p>{date.format('MMM D')}</p>;
    } catch (ex) {
      return <p>Unknown</p>;
    }
  };

  const { deposit, index } = props;
  const rowClass = 'transaction_row';

  return (
    <tbody>
      <tr className={rowClass} data-deposit-id={index} key={deposit.id}>
        <td className="sorting_1 nowrap">{displayDate(deposit.attributes.deliveryDate)}</td>
        <td className="vertical-align-middle">{deposit.attributes.merchant}</td>
        <td className="align-right">{displayAmount(deposit.attributes.amount?.amount)}</td>
      </tr>
    </tbody>
  );
}

DepositRow.propTypes = {
  index: PropTypes.number.isRequired,
  deposit: PropTypes.object.isRequired,
};
