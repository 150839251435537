/* eslint-disable no-empty-function */
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React from 'react';
import ActivateCardButton from './activate_card/ActivateCardButton';
import OrderReplacementCardButton from './order_replacement_card/OrderReplacementCardButton';
import PermanentlyCloseCardButton from './permanently_close_card/PermanentlyCloseCardButton';
import ReactivateCardButton from './temporarily_disable_card/ReactivateCardButton';
import TemporarilyDeactivateCardButton from './temporarily_disable_card/TemporarilyDeactivateCardButton';
import { accountShape } from 'react/member/card/shapes/AccountShape';
import { cardOrderShape } from 'react/member/card/shapes/CardOrderShape';
import { cardShape } from 'react/member/card/shapes/CardShape';
import { cardholderShape } from 'react/member/card/shapes/CardholderShape';
import { organizationShape } from 'react/member/card/shapes/OrganizationShape';
import { CARD_STATUSES } from 'react/member/utils/CardStatus';
import localeShape from 'react/shared/shapes/locale_shape';
import theme from 'react/shared/theme/Theme';

const useStyles = makeStyles(() => ({
  actionButtons: {
    display: 'flex',
    '& button': {
      marginRight: '15px',
    },
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '10px',
      '& button': {
        marginRight: '0px',
      },
    },
  },
}));

export default function CardStatusActions({
  account,
  backupCards,
  card,
  cardReissues,
  cardholder,
  localeProps,
  onCardActivationSuccess,
  onCardCloseSuccess,
  onCardDeactivateReactivateSuccess,
  onCardReissueSuccess,
  organization,
  showExpiringCardBox,
  status,
}) {
  const classes = useStyles();

  const showActivateCardButton =
    !card.is_breached &&
    [
      // first card order
      CARD_STATUSES.OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS,
      CARD_STATUSES.RELEASED_FIRST_CARD_ORDER_STATUS,
      // additional card order
      CARD_STATUSES.OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
      CARD_STATUSES.RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
      // legacy card order (reissue C - new account)
      CARD_STATUSES.OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS,
      CARD_STATUSES.RELEASED_LEGACY_CARD_ORDER_STATUS,
      // replacement card order (reissue A)
      CARD_STATUSES.OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
      CARD_STATUSES.RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
    ].includes(status);

  const showActivateReissueButton =
    !card.is_breached &&
    [
      // backup card order
      CARD_STATUSES.OVERDUE_RELEASED_BACKUP_CARD_ORDER_STATUS,
      CARD_STATUSES.RELEASED_BACKUP_CARD_ORDER_STATUS,
    ].includes(status);

  const showDisableButton = [CARD_STATUSES.OPEN_CARD_STATUS].includes(status);

  const isFraudBlock = status === CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS;
  const isFullyFunded = organization?.fullyFunded;
  const isSardineFraudBlock =
    isFraudBlock && card.fraud_block_type === 'transaction_risk_support_required';

  const hideButtonDueToFraudBlock = isSardineFraudBlock && !isFullyFunded;

  const showReactivateButton =
    !card.is_breached &&
    !hideButtonDueToFraudBlock &&
    [CARD_STATUSES.INACTIVE_CARD_STATUS].includes(status);

  const showOrderReplacementButton =
    !showExpiringCardBox &&
    backupCards.length === 0 &&
    cardReissues.length === 0 &&
    !!card &&
    !card.card_reissue &&
    !hideButtonDueToFraudBlock &&
    [
      // card
      CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS,
      CARD_STATUSES.INACTIVE_CARD_STATUS,
      CARD_STATUSES.LOST_CARD_STATUS,
      CARD_STATUSES.OPEN_CARD_STATUS,
    ].includes(status);

  const showPermanentlyCloseButton = [
    // first card order
    CARD_STATUSES.OVERDUE_RELEASED_FIRST_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_FIRST_CARD_ORDER_STATUS,
    // additional card order
    CARD_STATUSES.OVERDUE_RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_ADDITIONAL_CARD_ORDER_STATUS,
    // backup card order
    CARD_STATUSES.OVERDUE_RELEASED_BACKUP_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_BACKUP_CARD_ORDER_STATUS,
    // legacy card order (reissue C - new account)
    CARD_STATUSES.OVERDUE_RELEASED_LEGACY_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_LEGACY_CARD_ORDER_STATUS,
    // replacement card order (reissue A)
    CARD_STATUSES.OVERDUE_RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
    CARD_STATUSES.RELEASED_REPLACEMENT_CARD_ORDER_STATUS,
    // card
    CARD_STATUSES.INACTIVE_CARD_STATUS,
    CARD_STATUSES.LOST_CARD_STATUS,
    CARD_STATUSES.OPEN_CARD_STATUS,
    CARD_STATUSES.FRAUD_BLOCK_CARD_STATUS,
  ].includes(status);

  const isStableOrAble = [8, 9, 12].includes(account?.tl_card_program);

  return (
    <div className={classes.actionButtons}>
      {showActivateCardButton && (
        <ActivateCardButton
          accountSlug={account.slug}
          adminId={account.administrator?.id}
          cardOrderType={card.card_order?.type}
          cardSlug={card.slug}
          cardholder={cardholder}
          last4CardDigits={card.card_last_4}
          localeProps={localeProps}
          onCardActivationSuccess={onCardActivationSuccess}
          vcc={!!account.bulk_card_ordered}
        />
      )}

      {showActivateReissueButton && (
        <ActivateCardButton
          accountSlug={account.slug}
          adminId={account.administrator?.id}
          cardSlug={card.slug}
          cardholder={cardholder}
          last4CardDigits={card.card_last_4}
          localeProps={localeProps}
          onCardActivationSuccess={onCardActivationSuccess}
          vcc={false}
        />
      )}
      {showDisableButton && (
        <TemporarilyDeactivateCardButton
          accountSlug={account.slug}
          cardBreached={card.is_breached}
          onCardDeactivateReactivateSuccess={onCardDeactivateReactivateSuccess}
        />
      )}
      {showReactivateButton && (
        <ReactivateCardButton
          accountSlug={account.slug}
          onCardDeactivateReactivateSuccess={onCardDeactivateReactivateSuccess}
        />
      )}
      {showOrderReplacementButton && (
        <OrderReplacementCardButton
          accountSlug={account.slug}
          adminMobile={account.administrator.mobile}
          allowAutomatedPayment={account.allow_automated_payment}
          cardBreached={card.is_breached}
          cardholderAddress={cardholder.address_as_string}
          cardholderCountryOfCitizenship={cardholder.countryOfCitizenship}
          cardholderMobile={cardholder.mobile}
          cardholderName={cardholder.name}
          disableSelectingLastKnownAddress={account.disable_selecting_last_known_address}
          isStableOrAble={isStableOrAble}
          onCardReissueSuccess={onCardReissueSuccess}
          organizationAddress={organization.address_as_string}
          otherShippingDestinationDefault={cardholder.address}
          paymentMethods={account.payment_method_options_for_card_fee}
          shippingAddress={account.shipping_address_as_string}
          shippingFee={account.shipping_fees}
          shippingSettings={account.shipping_settings}
          status={status}
        />
      )}
      {showPermanentlyCloseButton && (
        <PermanentlyCloseCardButton card={card} onCardCloseSuccess={onCardCloseSuccess} />
      )}
    </div>
  );
}

CardStatusActions.propTypes = {
  account: accountShape,
  backupCards: PropTypes.arrayOf(cardShape),
  card: cardShape,
  cardReissues: PropTypes.arrayOf(cardOrderShape),
  cardholder: cardholderShape,
  localeProps: localeShape,
  onCardActivationSuccess: PropTypes.func,
  onCardCloseSuccess: PropTypes.func,
  onCardDeactivateReactivateSuccess: PropTypes.func,
  onCardReissueSuccess: PropTypes.func,
  organization: organizationShape,
  showExpiringCardBox: PropTypes.bool,
  status: PropTypes.oneOf(Object.values(CARD_STATUSES)).isRequired,
};

CardStatusActions.defaultProps = {
  backupCards: [],
  cardReissues: [],
  localeProps: {},
  onCardActivationSuccess: () => {},
  onCardCloseSuccess: () => {},
  onCardDeactivateReactivateSuccess: () => {},
  onCardReissueSuccess: () => {},
  showExpiringCardBox: false,
};
