import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import api from './api.js';
import DashboardInProductAd from 'react/member/components/in_product_ads/DashboardInProductAd';
import Promotions from 'react/member/components/referrals/Promotions';
import InProductAdShape from 'react/member/shapes/in_product_ad_shape';
import TransactionList from 'react/shared/card/components/transaction_list/TransactionList';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import theme from 'react/shared/theme/Theme';
import { isRepPayee } from 'react/shared/utils/account_card_program';
export default function CardTransactionsTabContainer(props) {
  const [account, setAccount] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.getAccount(props.accountId).then((response) => {
      setAccount(response.data.data);
      setLoading(false);
    });
  }, [props.accountId]);

  useEffect(() => {
    setAccount({});
    setLoading(true);
  }, [props.accountId]);

  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Promotions context="transactionsTab" />
          {props.inProductAd && <DashboardInProductAd inProductAd={props.inProductAd} />}
          {loading ? (
            <LoadingIndicator />
          ) : (
            <TransactionList
              accountId={account.id}
              cardCount={account.meta.cardCount}
              hideClosedCardTransactions={account.attributes.hideClosedCardTransactions}
              hideClosedCardTransactionsEnabled
              isRepPayee={isRepPayee(account.attributes.cardProgramNumber)}
              ruleSetId={props.ruleSetId}
              showNotes={account.meta.showTransactionNotes}
              showQDE={account.meta.showTransactionQde}
              showReceipts={account.meta.showTransactionReceipts}
              supportEmail={account.meta.locale.supportEmail}
            />
          )}
        </ThemeProvider>
      </StyledEngineProvider>
    </QueryClientProvider>
  );
}

CardTransactionsTabContainer.propTypes = {
  accountId: PropTypes.string.isRequired,
  inProductAd: InProductAdShape,
  ruleSetId: PropTypes.string,
};
