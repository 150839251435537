import Typography from '@mui/material/Typography';
import makeStyles from '@mui/styles/makeStyles';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import CalendarSvg from 'images/calendar.svg';
import TrueLinkIcon from 'react/shared/components/true_link/main/TrueLinkIcon';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import PALETTE from 'react/shared/theme/palette';
import { ordinalize } from 'react/shared/utils/Numbers';

const useStyles = makeStyles({
  transferInfoBox: {
    background: PALETTE.emeraldLight,
    borderRadius: '5px',
    padding: '15px 20px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: '15px',
  },
  transferInfoBoxContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '15px',
  },
  textSection: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '10px',
  },
  tooltipText: {
    display: 'flex',
    gap: '10px',
  },
  calendarIcon: {
    width: '23px',
    paddingTop: '5px',
  },
  transferInfoText: {
    width: '100%',
  },
});

export default function TransferFundsInfoBox({
  availableOnDate,
  bomorrow,
  cardStatus,
  dayOfMonth,
  dayOfMonth2,
  dayOfWeek,
  emergencyFundingEnabled,
  fundingType,
  transferAdjusted,
  transferAdjustedReason,
  transferAmount,
  transferThreshold,
  oneTimeTransferDate,
  transferStartDay,
  transferDatePlus1BDay,
  transferDatePlus5BDay,
  transferType,
}) {
  const classes = useStyles();
  const formatOptions = ['dddd MMM DD YYYY', 'YYYY MM DD'];
  const availableOnDateFormatted = moment(availableOnDate, 'MMM DD YYYY').format('dddd, MMMM Do');
  const bomorrowFormatted = moment(bomorrow, 'YYYY MM DD').format('dddd, MMMM Do');
  const transferStartDateFormatted = moment(transferStartDay, formatOptions).format(
    'dddd, MMMM Do',
  );
  const transferDatePlus1BDayFormatted = moment(transferDatePlus1BDay, 'YYYY MM DD').format(
    'dddd, MMMM Do',
  );
  const transferDatePlus5BDayFormatted = moment(transferDatePlus5BDay, 'YYYY MM DD').format(
    'dddd, MMMM Do',
  );
  const nextDayOfWeek = moment.weekdays(Number((dayOfWeek % 5) + 1));
  const dayOfWeekFormatted = moment.weekdays(Number(dayOfWeek));
  const dayOfMonthFormatted = ordinalize(dayOfMonth);
  const dayOfMonth2Formatted = ordinalize(dayOfMonth2);
  const partialAmount = fundingType === 'Partial ($500)' ? 500 : 250;
  const isPartialFunding = fundingType === 'Partial ($250)' || fundingType === 'Partial ($500)';
  const isPartialFundingOverTheLimit = isPartialFunding && transferAmount > partialAmount;
  const isPartialFundingUnderTheLimit = isPartialFunding && transferAmount <= partialAmount;
  const isFullyFunded =
    fundingType === 'Fully fund' ||
    fundingType === 'Cautious fund' ||
    isPartialFundingUnderTheLimit;
  const isWaitToFund = fundingType === 'Wait to fund';
  const isWeeklyTransfer = transferType === 'every_week';
  const isMonthlyTransfer = transferType === 'every_month' || transferType === 'twice_monthly';
  const isRecurringTransfer = isWeeklyTransfer || isMonthlyTransfer;
  const cardOpenOrInactive = cardStatus == 'OPEN' || cardStatus == 'ISSUED_INACTIVE';
  const cardInactiveRecurring = isRecurringTransfer && cardStatus == 'ISSUED_INACTIVE';
  const cardActivated = cardOpenOrInactive || cardStatus == 'CLOSED';
  const showCardNotActivatedMessage = !cardActivated || cardInactiveRecurring;
  const tomorrow = moment().add(1, 'days').format('dddd, MMMM Do');
  const now = moment().tz('America/Los_Angeles'); //current time in Pacific Time
  const today = moment().startOf('day');
  const sevenDaysFromToday = moment().add(7, 'days').startOf('day');
  const transferStartFormatted = moment(transferStartDay, 'dddd MMM DD YYYY').startOf('day');
  const transferWithin7days = transferStartFormatted.isBetween(
    today,
    sevenDaysFromToday,
    null,
    '[]',
  );
  const fourPM = moment() //4 PM Pacific Time
    .tz('America/Los_Angeles')
    .set({ hour: 16, minute: 0, second: 0, millisecond: 0 });
  const isAfterFourPM = now.isAfter(fourPM);
  const isSameDayTransfer =
    (isWeeklyTransfer && now.day() == dayOfWeek) ||
    (isMonthlyTransfer && now.date() == dayOfMonth) ||
    (transferType === 'twice_monthly' && now.date() == dayOfMonth2);

  const isSameDayTransferBefore4PM = !isAfterFourPM && isSameDayTransfer;
  const isSameDayTransferAfter4PM =
    isAfterFourPM && moment(oneTimeTransferDate).isSame(moment(today));

  const cardNotActivatedMessage = (
    <>
      <div className={classes.textSection}>
        Scheduled transfers will initiate once the card is activated. You must activate this card
        today for the schedule below to take effect.
      </div>
      <br />
    </>
  );

  const afterFourPmMessage = (
    <span>
      Because it’s currently after 4 PM PT, your transfer will be initiated the next business day.{' '}
      {!emergencyFundingEnabled && (
        <span>
          If you need funds sooner, please email{' '}
          <a href="mailto:support@truelinkfinancial.com">support@truelinkfinancial.com</a>.
        </span>
      )}
    </span>
  );

  const sameDayMessage = (
    <div>
      <br />
      {isWeeklyTransfer && dayOfWeek == 1 ? (
        <div className={classes.textSection}>
          If you need funds tomorrow, {tomorrow}, make a one time transfer today. If you want funds
          to land on the card every Monday, schedule your transfer for Friday, allowing for 1
          business day.
        </div>
      ) : (
        <div className={classes.textSection}>
          If you need funds the next-business day, {bomorrowFormatted}, make a one time transfer
          today.
        </div>
      )}
    </div>
  );

  const sameDayMessageWaitFund = (
    <>
      <br />
      <div className={classes.textSection}>
        If you need funds sooner, make a one time transfer today.
      </div>
    </>
  );

  const sameDayMessageRecurringMonthly = (
    <>
      <br />
      <div>If you need funds sooner, make a one time transfer.</div>
    </>
  );

  const emergencyFundingMessage = (
    <>
      <br />
      <div>
        If you need funds sooner, chat with our support team or email{' '}
        <a href="mailto:support@truelinkfinancial.com">support@truelinkfinancial.com</a> to request
        Emergency Funding.
      </div>
    </>
  );

  const tooltipTextFF = (
    <Typography variant="caption">
      If you need funds immediately, chat with our support team or email{' '}
      <a href="mailto:support@truelinkfinancial.com">support@truelinkfinancial.com</a> to request
      Emergency Funding.
    </Typography>
  );

  const tooltipTextMonthlyFF = (
    <Typography variant="caption">
      When a transfer initiation date falls on a weekend or holiday, the transfer will initiate the
      next business day, and funds will be available 1 business day later. Click the “Show transfer
      schedule” link to see the initiation dates for future transfers.
    </Typography>
  );

  const tooltipTextThresholdFF = (
    <Typography variant="caption">
      To learn how to set alerts on when the balance drops below a certain amount,{' '}
      <a href="https://help.admin.truelinkfinancial.com/hc/en-us/articles/28251353269019-Customize-Alerts">
        review this Help Center article.
      </a>
    </Typography>
  );

  const tooltipTextRec = (
    <Typography variant="caption">
      Click the “Show transfer schedule” link to see the upcoming transfer initiation dates.
    </Typography>
  );

  const tooltipTextMonthlyWF = (
    <Typography variant="caption">
      When a transfer initiation date falls on a weekend or holiday, the transfer will initiate the
      next business day, and funds will be available 5 business days later. Click the “Show transfer
      schedule” link to see the initiation dates for future transfers.{' '}
    </Typography>
  );

  const tooltipTextThresholdWF = (
    <Typography variant="caption">
      When the balance drops below the threshold amount after 3 PM PT during the week, or anytime on
      a weekend or holiday, the transfer will initiate the following business day, and funds will be
      available 5 business days later.
    </Typography>
  );

  const tooltipTextMonthlyPF = (
    <>
      <Typography variant="caption">
        When the transfer date falls on a weekend or holiday, the transfer will initiate the next
        business day. This means the first part of the transfer will be available 2 business days
        after the transfer date, and the remaining funds will be available 4 business days after
        that.
      </Typography>
      <br />
      <br />
      <Typography variant="caption">
        Click the “Show transfer schedule” link to see the upcoming transfer initiation dates.
      </Typography>
    </>
  );

  const tooltipTextThresholdPF = (
    <Typography variant="caption">
      When the balance drops below the threshold amount after 3 PM PT during the week, or anytime on
      a weekend or holiday, the transfer will initiate the next business day. This means the first
      part of the transfer will be available 2 business days after the initiation date, and the
      remaining funds will be available 4 business days after that.
    </Typography>
  );

  const waitToFundClauseTooltipText = (
    <div>
      <Typography variant="caption">
        If you are a new True Link customer, funding timelines may vary. For example, while we are
        establishing a relationship with you and your bank, it may take up to five business days for
        the requested funds to be available on the card.{' '}
      </Typography>
      <br />
      <br />
      <Typography variant="caption">
        While your requested transfer is in progress, you may notice that the funds have been
        debited from your account, even though they have not yet become available on the card. This
        is a normal part of the transfer process. The delay occurs because the funds are in transit
        from your bank and have not yet settled on our end.{' '}
      </Typography>
      <br />
      <br />
      <Typography variant="caption">
        Please check out this{' '}
        <a href="https://help.admin.truelinkfinancial.com/hc/en-us/articles/27714175511195-Funding-Timelines-and-Limits">
          Help Center
        </a>{' '}
        article if you have any questions about funding timelines and availability.{' '}
      </Typography>
    </div>
  );

  const transferImpactedByWeekend = transferAdjusted && transferAdjustedReason == 'weekend';
  const transferImpactedByHoliday = transferAdjusted && transferAdjustedReason == 'holiday';
  const holidayDelayedPreText = transferImpactedByHoliday
    ? 'The first transfer will be delayed by the holiday. '
    : '';
  const holidayDelayedPostText =
    transferImpactedByHoliday && transferWithin7days
      ? 'You can adjust the first transfer to avoid the delay after you click "Submit".'
      : '';
  const weekendDelayedText = transferImpactedByWeekend ? 'be delayed by the weekend, and will' : '';
  const firstTransferText = `The first transfer on ${transferStartDateFormatted} will ${weekendDelayedText} be available ${transferDatePlus1BDayFormatted}. ${holidayDelayedPostText}`;
  const recurringFrequency = transferType === 'every_week' ? 'weekly' : 'monthly';

  let recurringFrequencyDay;
  if (transferType === 'every_week') {
    recurringFrequencyDay = dayOfWeekFormatted;
  } else if (transferType === 'every_month') {
    recurringFrequencyDay = dayOfMonthFormatted;
  } else {
    recurringFrequencyDay = `${dayOfMonthFormatted} and ${dayOfMonth2Formatted}`;
  }

  let tooltipText = '';
  if (isFullyFunded && !emergencyFundingEnabled && transferType === 'one_time') {
    tooltipText = tooltipTextFF;
  } else if (
    isFullyFunded &&
    (transferType === 'every_month' || transferType === 'twice_monthly')
  ) {
    tooltipText = tooltipTextMonthlyFF;
  } else if (
    isFullyFunded &&
    !isPartialFundingUnderTheLimit &&
    transferType === 'when_funds_drop_below'
  ) {
    tooltipText = tooltipTextThresholdFF;
  } else if (isWaitToFund && isRecurringTransfer) {
    tooltipText = transferType === 'every_week' ? tooltipTextRec : tooltipTextMonthlyWF;
  } else if (isWaitToFund && transferType === 'when_funds_drop_below') {
    tooltipText = tooltipTextThresholdWF;
  } else if (isPartialFundingOverTheLimit && isRecurringTransfer) {
    tooltipText = transferType === 'every_week' ? tooltipTextRec : tooltipTextMonthlyPF;
  } else if (isPartialFundingOverTheLimit && transferType === 'when_funds_drop_below') {
    tooltipText = tooltipTextThresholdPF;
  }

  const one_time = (
    <div className={classes.textSection}>
      <div>
        {isSameDayTransferAfter4PM && cardOpenOrInactive && afterFourPmMessage} Funds will be
        available {availableOnDateFormatted}
      </div>
    </div>
  );

  const every_week = (
    <div className={classes.textSection}>
      <div>
        <div>
          {holidayDelayedPreText} Funds will land every {nextDayOfWeek}. The first transfer on{' '}
          {transferStartDateFormatted} will be available {transferDatePlus1BDayFormatted}.{' '}
          {holidayDelayedPostText}
        </div>
        {isSameDayTransferBefore4PM && sameDayMessage}
      </div>
    </div>
  );

  const every_month = (
    <div className={classes.textSection}>
      <div>
        <div>
          {holidayDelayedPreText} Funds will land 1 business day after the {dayOfMonthFormatted}.{' '}
          {firstTransferText}
        </div>
        {(transferImpactedByWeekend || isSameDayTransferBefore4PM) &&
          sameDayMessageRecurringMonthly}
      </div>
    </div>
  );

  const twice_monthly = (
    <div className={classes.textSection}>
      <div>
        <div>
          {holidayDelayedPreText} Funds will land 1 business day after the {dayOfMonthFormatted} and
          1 business day after the {dayOfMonth2Formatted}. {firstTransferText}
        </div>
        {(transferImpactedByWeekend || isSameDayTransferBefore4PM) &&
          sameDayMessageRecurringMonthly}
      </div>
    </div>
  );

  const when_funds_drop_below = (
    <div className={classes.textSection}>
      <div>
        <div>
          The transfer will initiate on weekdays at 3 PM PT when the balance is below $
          {transferThreshold}, and funds will be available 1 business day later.
        </div>
        {!emergencyFundingEnabled && !isPartialFunding && emergencyFundingMessage}
      </div>
    </div>
  );

  const wait_one_time = (
    <div className={classes.textSection}>
      <div>Funds will be available {transferDatePlus5BDayFormatted}</div>
    </div>
  );

  const wait_recurring = (
    <div className={classes.textSection}>
      <div>
        <div>
          {holidayDelayedPreText}
          {transferImpactedByWeekend ? 'The first transfer will be delayed by the weekend. ' : ''}
          The transfer will initiate {recurringFrequency} on {recurringFrequencyDay} and funds will
          be available 5 business days later. The first transfer on {transferStartDateFormatted}{' '}
          will be available {transferDatePlus5BDayFormatted}. {holidayDelayedPostText}
        </div>
        {isSameDayTransferBefore4PM && sameDayMessageWaitFund}
      </div>
    </div>
  );

  const wait_when_funds_drop_below = (
    <div className={classes.textSection}>
      <div>
        The transfer will initiate on weekdays at 3 PM PT when the balance is below $
        {transferThreshold}, and funds will be available 5 business day later.
      </div>
    </div>
  );

  const wait_to_fund_clause = (
    <div className={classes.textSection}>
      <div>
        <br />
        <div>
          Our Funding Team is reviewing your account to make sure everything looks ok, to help
          protect you and others from fraud.{' '}
          <TrueLinkTooltip
            maxWidth={'510px'}
            placement="top-start"
            tooltipContent={waitToFundClauseTooltipText}
          >
            <span style={{ color: PALETTE.blue }}>Learn More</span>
          </TrueLinkTooltip>
        </div>
      </div>
    </div>
  );

  const partial_one_time = (
    <div className={classes.textSection}>
      <div>
        {isSameDayTransferAfter4PM && cardOpenOrInactive && afterFourPmMessage} ${partialAmount}{' '}
        will be available {transferDatePlus1BDayFormatted}, and the remaining $
        {transferAmount - partialAmount} will be available on {transferDatePlus5BDayFormatted}.
      </div>
    </div>
  );

  const partial_recurring = (
    <div className={classes.textSection}>
      <div>
        <div>
          {holidayDelayedPreText}
          {transferImpactedByWeekend ? 'The first transfer will be delayed by the weekend. ' : ''}$
          {partialAmount} will be available {transferDatePlus1BDayFormatted}, and the remaining $
          {transferAmount - partialAmount} will be available on {transferDatePlus5BDayFormatted}.
        </div>
        <br />
        <div>
          Then, at least ${partialAmount} will be available{' '}
          {transferType === 'twice_monthly' ? 'twice-monthly' : recurringFrequency}, 1 business day
          after {transferType === 'every_week' ? dayOfWeekFormatted : `the ${dayOfMonthFormatted}`}
          {transferType === 'twice_monthly' ? ` and the ${dayOfMonth2Formatted}` : ''}, and the
          remaining funds will land 4 business days later. {holidayDelayedPostText}
        </div>
        {isSameDayTransferBefore4PM && sameDayMessage}
      </div>
    </div>
  );

  const partial_fund_clause = (
    <div className={classes.textSection}>
      <div>
        <br />
        <div>
          For more information about why this transfer will be made available in two separate parts,
          please review this{' '}
          <a href="https://help.truelinkfinancial.com/article/340-funding-timelines">Help Center</a>{' '}
          article.
        </div>
      </div>
    </div>
  );

  const tooltipIcon = (
    <TrueLinkTooltip tooltipContent={tooltipText}>
      <TrueLinkIcon className={classes.activityIcon} icon="circle-question fa-regular" />
    </TrueLinkTooltip>
  );

  const partial_when_funds_drop_below = (
    <div className={classes.textSection}>
      <div>
        The transfer will initiate on weekdays at 3 PM PT when the balance is below $
        {transferThreshold}, and funds will be available 5 business day later. At least $
        {partialAmount} will be available the next business day, and the remaining funds will be
        available 4 business days after that.
      </div>
    </div>
  );

  const fullyFund = (
    <div className={classes.transferInfoText}>
      {transferType === 'one_time' && one_time}
      {transferType === 'every_week' && every_week}
      {transferType === 'every_month' && every_month}
      {transferType === 'twice_monthly' && twice_monthly}
      {transferType === 'when_funds_drop_below' && when_funds_drop_below}
    </div>
  );

  const waitToFund = (
    <div className={classes.transferInfoText}>
      {transferType === 'one_time' && wait_one_time}
      {transferType === 'every_week' && wait_recurring}
      {transferType === 'every_month' && wait_recurring}
      {transferType === 'twice_monthly' && wait_recurring}
      {transferType === 'when_funds_drop_below' && wait_when_funds_drop_below}
      {wait_to_fund_clause}
    </div>
  );

  const partialFund = (
    <div className={classes.transferInfoText}>
      {transferType === 'one_time' && partial_one_time}
      {transferType === 'every_week' && partial_recurring}
      {transferType === 'every_month' && partial_recurring}
      {transferType === 'twice_monthly' && partial_recurring}
      {transferType === 'when_funds_drop_below' && partial_when_funds_drop_below}
      {partial_fund_clause}
    </div>
  );

  return (
    <div className={classes.transferInfoBox}>
      <img alt="calendar icon" className={classes.calendarIcon} src={CalendarSvg} />
      <div className={classes.transferInfoBoxContainer}>
        <div className={classes.transferInfoText}>
          {showCardNotActivatedMessage && cardNotActivatedMessage}
          {isWaitToFund && waitToFund}
          {isPartialFundingOverTheLimit && partialFund}
          {isFullyFunded && fullyFund}
        </div>
        {tooltipText && tooltipIcon}
      </div>
    </div>
  );
}

TransferFundsInfoBox.propTypes = {
  fundingType: PropTypes.string,
  transferType: PropTypes.oneOf([
    'one_time',
    'every_week',
    'every_month',
    'twice_monthly',
    'when_funds_drop_below',
  ]).isRequired,
  availableOnDate: PropTypes.string,
  cardStatus: PropTypes.string,
  dayOfWeek: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dayOfMonth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  dayOfMonth2: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transferThreshold: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  emergencyFundingEnabled: PropTypes.bool,
  transferAmount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  transferAdjusted: PropTypes.bool,
  transferAdjustedReason: PropTypes.string,
  oneTimeTransferDate: PropTypes.string,
  transferStartDay: PropTypes.string,
  transferDatePlus1BDay: PropTypes.string,
  transferDatePlus5BDay: PropTypes.string,
  bomorrow: PropTypes.string,
};
