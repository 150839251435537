import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import DashboardDisbursementAdminApprovalModal from './DashboardDisbursementAdminApprovalModal';
import DashboardActions from 'react/member/actions/dashboard_actions';
import TrueLinkButton from 'react/shared/components/true_link/main/TrueLinkButton';
import { red } from 'react/shared/theme/palette';

export default function DashboardDisbursementApprovalActions({
  disbursement,
  recurringDisbursement,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const entity = disbursement || recurringDisbursement;
  const isRecurringView = !!recurringDisbursement;
  const isApprovable = entity.can_be_approved;

  const closeModal = useCallback(() => {
    setModalOpen(false);
  }, []);

  const handleSubmitComplete = useCallback(() => {
    setIsSubmitting(false);
  }, []);

  const openDenyModal = useCallback(() => {
    if (isRecurringView) {
      return DashboardActions.openRecurringDisbursementDenialModal(entity.id, 'Recurring');
    }
    return DashboardActions.openDisbursementDenialModal(entity.id, 'OneTime');
  }, [entity, isRecurringView]);

  const approveEntity = useCallback(
    (userId) => {
      setIsSubmitting(true);
      if (isRecurringView) {
        DashboardActions.approveRecurringDisbursement(entity.id, userId, handleSubmitComplete);
      } else {
        DashboardActions.approveDisbursement(entity, userId, handleSubmitComplete);
      }
      setModalOpen(false);
      setIsSubmitting(false);
    },
    [entity, isRecurringView, handleSubmitComplete],
  );

  const approveOrOpenModal = useCallback(
    (e) => {
      e.preventDefault();

      if (entity.admin_approval) {
        setModalOpen(true);
      } else {
        approveEntity(null);
      }
    },
    [entity, approveEntity],
  );

  const buttonStyle = { padding: '2px 10px 3px' };

  const approveAction = isApprovable ? (
    <TrueLinkButton
      className={classNames('btn btn-sm btn-success', {
        disabled: isSubmitting,
      })}
      disabled={isSubmitting}
      onClick={approveOrOpenModal}
      style={buttonStyle}
      variant="none"
    >
      {isSubmitting ? 'Submitting...' : 'Approve'}
    </TrueLinkButton>
  ) : (
    <span>Waiting for final approval</span>
  );

  return (
    <div>
      {approveAction}&nbsp;
      {isApprovable && (
        <TrueLinkButton
          className={'btn btn-sm normal'}
          onClick={openDenyModal}
          style={{ ...buttonStyle, backgroundColor: red }}
          variant="none"
        >
          Deny
        </TrueLinkButton>
      )}
      {modalOpen && (
        <DashboardDisbursementAdminApprovalModal
          approveDisbursement={approveEntity}
          disbursement={disbursement}
          onClose={closeModal}
          recurringDisbursement={recurringDisbursement}
        />
      )}
    </div>
  );
}

DashboardDisbursementApprovalActions.propTypes = {
  disbursement: PropTypes.object,
  recurringDisbursement: PropTypes.object,
};
