import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import React from 'react';
import ReactDOM from 'react-dom';
import PayeeFields from './PayeeFields';
import RecurringDisbursementDetailActions from './RecurringDisbursementDetailActions';
import DashboardActions from 'react/member/actions/dashboard_actions';
import CategoryDropdown from 'react/member/components/dashboard/CategoryDropdown';
import ApproverInfoSection from 'react/member/components/dashboard/disbursements/ApproverInfoSection';
import DashboardBudgetItemAttachments from 'react/member/components/dashboard/disbursements/DashboardBudgetItemAttachments';
import DetailBalanceInfo from 'react/member/components/dashboard/disbursements/DetailBalanceInfo';
import InlineFrequencyInputs from 'react/member/components/dashboard/disbursements/InlineFrequencyInputs';
import RecurringDisbursementStore from 'react/member/stores/RecurringDisbursementStore';
import { recurringDisbursementShape } from 'react/shared/shapes/DisbursementsShape';
import { asMoney } from 'react/shared/utils/Money';
import bindAll from 'react/shared/utils/bind_all';

export default class DashboardRecurringDisbursementDetails extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      editing: false,
    };

    bindAll(this);
  }

  // In an ideal world, we wouldn't have to be repopulating context from props. But, because we do
  // weird stuff to render this (i.e., rendering it via datatables/jquery and forcing it to mount
  // via ReactDOM.render() from DashboardPaginatedDisbursements), we are breaking normal React-y flow here.
  // This should not be considered normal or expected behavior for context population, and should be
  // removed whenever we can refactor our front-end code to use proper React all the way through.
  getChildContext() {
    return {
      client: this.props.recurringDisbursement.beneficiary,
    };
  }

  componentDidMount() {
    RecurringDisbursementStore.on('recurringDisbursement.update', this.onUpdateSuccess);
  }

  componentWillUnmount() {
    RecurringDisbursementStore.off('recurringDisbursement.update', this.onUpdateSuccess);
  }

  isApprovalRequiredView() {
    return ['additional_approval', 'pending', 'proposed'].includes(this.props.statusFilter);
  }

  toggleEdit(e) {
    if (this.state.editing) {
      return this.updateEntity(e);
    }
    return this.setState({ editing: true });
  }

  updateEntity(e) {
    e.preventDefault();

    const { id } = this.props.recurringDisbursement;
    DashboardActions.updateRecurringDisbursement(id, this.getDisbursement(id));

    return this.setState({
      editing: false,
    });
  }

  getDisbursement(id) {
    return $(ReactDOM.findDOMNode(this)).find(`.js-edit-entity-${id}`).serializeArray();
  }

  onUpdateSuccess(updatedDisbursement) {
    if (this.props.recurringDisbursement.id === updatedDisbursement.id) {
      return this.setState({ editing: false });
    }
  }

  editableField(label, value, key, newLine) {
    let dataClass = 'new-form__data';
    let fieldClass = 'new-form-field';
    if (newLine) {
      dataClass += ' new-form__data--newline';
      fieldClass = ' block';
    }
    dataClass += newLine ? ' new-form__data--newline' : '';
    return (
      <div className={fieldClass} key={key}>
        <div className="new-form__label">{label}</div>
        <div className={dataClass}>{value}</div>
      </div>
    );
  }

  organizationSlug() {
    return this.props.recurringDisbursement.beneficiary.organization.slug;
  }

  getEditableFieldNaText(name) {
    switch (name) {
      case 'category':
        return 'Category not set';
      case 'memo':
        return 'No note';
    }
  }

  getEditableFieldFormattedValue(name, value) {
    if (value) {
      switch (name) {
        case 'amount':
          return <span>{asMoney(Number(value))}</span>;
        case 'frequency':
          return <span>{this.props.recurringDisbursement.description}</span>;
        default:
          return <span>{value}</span>;
      }
    } else {
      return <span className="italic">{this.getEditableFieldNaText(name)}</span>;
    }
  }

  // Careful: this function only renders the disbursement category level fields for editing. The other fields
  // (e.g., Payment Type, Payee) are rendered in other functions of this component
  renderEditableFields(entity) {
    const fields = [
      { amount: 'Amount:' },
      { category: 'Category:' },
      { memo: 'Notes:' },
      { frequency: 'Frequency:' },
    ];
    const inputNamePrefix = 'recurring_disbursement';

    return fields.map((field, i) => {
      let newLine;
      const [name] = Object.keys(field);
      const label = field[name];
      const value = entity[name];

      if (this.state.editing) {
        const formClass = `js-edit-entity-${entity.id}`;
        const symbol = name === 'amount' ? '$' : '';
        newLine = false;
        const slug = this.organizationSlug();
        const input = (() => {
          let inputName, style;
          switch (name) {
            case 'category':
              return (
                <CategoryDropdown
                  categoryNumber={entity.id}
                  hideLabel
                  initialValue={entity.category}
                  isExistingRecord
                  isRecurringDisbursement
                  organizationSlug={slug}
                />
              );
            case 'frequency':
              newLine = true;
              return (
                <InlineFrequencyInputs recurringDisbursement={this.props.recurringDisbursement} />
              );
            case 'start_date':
              return (
                <div className="datepicker-wrapper">
                  <input
                    autoComplete="off"
                    className="business_datepicker"
                    defaultValue={
                      this.props.recurringDisbursement.start_date ||
                      moment().format('YYYY-MM-DD').toString()
                    }
                    id="first_payment_date"
                    name="recurring_disbursement[start_date]"
                    type="text"
                  />
                </div>
              );
            case 'memo':
              inputName = `${inputNamePrefix}[${name}]`;
              return <textarea defaultValue={value} name={inputName} style={{ width: '100%' }} />;
            default:
              inputName = `${inputNamePrefix}[${name}]`;
              style = name === 'amount' ? { marginLeft: 5, width: 110 } : undefined;

              return <input defaultValue={value} name={inputName} style={style} type="text" />;
          }
        })();

        return this.editableField(
          label,
          <form className={formClass} onSubmit={this.updateEntity}>
            {symbol}
            {input}
          </form>,
          i,
          newLine,
        );
      }
      return this.editableField(label, this.getEditableFieldFormattedValue(name, value), i);
    });
  }

  render() {
    const { recurringDisbursement } = this.props;

    const { status } = recurringDisbursement;
    const requester = recurringDisbursement.requester;
    const seniorApprover = recurringDisbursement.senior_approver;
    const additionalApprover = recurringDisbursement.additional_approver;
    const client = recurringDisbursement.beneficiary;

    const fields = this.renderEditableFields(recurringDisbursement);

    const balanceInfo = {
      oneMonth: client.one_month_info,
      threeMonths: client.three_month_info,
      zeroDay: client.zero_day_info,
    };

    return (
      <div className="table-slidedown">
        <div className="table-slidedown__header">
          <span className="bold">Recurring Disbursement Details</span>
          <RecurringDisbursementDetailActions
            canCancel={
              recurringDisbursement.can_be_canceled && this.props.statusFilter === 'approved'
            }
            canEdit={recurringDisbursement.can_be_updated}
            isEditing={this.state.editing}
            onToggleEdit={this.toggleEdit}
            recurringDisbursement={recurringDisbursement}
          />
        </div>
        {client.three_month_info ? (
          <DetailBalanceInfo
            balanceInfo={balanceInfo}
            currentBalance={client.three_month_info.current_balance}
            effectiveBalance={client.three_month_info.effective_balance}
            isPooled={client.is_pooled}
            organizationSlug={recurringDisbursement.beneficiary.organization.slug}
          />
        ) : undefined}
        <div
          className="new-form__section new-form--compact new-form__section--inset"
          style={{ display: 'flex' }}
        >
          <div style={{ width: '50%', paddingRight: 20 }}>{fields}</div>

          <div style={{ width: '50%', paddingLeft: 20 }}>
            <PayeeFields
              checkMemoLine2={recurringDisbursement.check_memo_line_2}
              payee={recurringDisbursement.payee}
              paymentMethod={recurringDisbursement.payment_method}
              status={recurringDisbursement.status}
            />
          </div>
        </div>

        <DashboardBudgetItemAttachments
          allowInsert={recurringDisbursement.payment_method === 'Check'}
          disbursement={recurringDisbursement}
          readOnly={!this.state.editing}
          style="link"
        />
        {recurringDisbursement.payment_method === 'Card' && recurringDisbursement.card && (
          <div className="new-form-field">
            <div className="new-form__label">Card:</div>
            <div className="new-form__data">{recurringDisbursement.card.name}</div>
          </div>
        )}
        <div
          className="new-form__section new-form__section--last new-form--compact new-form__section--inset"
          style={{ display: 'flex', marginBottom: 0 }}
        >
          <div style={{ width: '50%', paddingRight: 20 }}>
            {status === 'Cancelled' && (
              <div className="new-form-field">
                <div className="new-form__label">Denied Reason:</div>
                <div className="new-form__data">{recurringDisbursement.denial_reason}</div>
              </div>
            )}
            {recurringDisbursement.proposer && (
              <ApproverInfoSection
                action={'proposed'}
                approvalLabel={'Proposed By:'}
                approvalTime={recurringDisbursement.proposed_at}
                personText={recurringDisbursement.proposer && recurringDisbursement.proposer.email}
              />
            )}
            <ApproverInfoSection
              action={'requested'}
              approvalLabel={'Requested By:'}
              approvalTime={recurringDisbursement.requested_at}
              personText={requester && (requester.email || requester.name)}
            />
            {status !== 'Cancelled' && (
              <ApproverInfoSection
                action={'approved'}
                approvalLabel={'Approved By:'}
                approvalTime={recurringDisbursement.approved_at}
                personText={recurringDisbursement.approver && recurringDisbursement.approver.email}
              />
            )}
            {seniorApprover && (
              <ApproverInfoSection
                action={'seniorApproved'}
                approvalLabel={'Additional Approval By:'}
                approvalTime={recurringDisbursement.senior_approved_at}
                personText={seniorApprover && seniorApprover.email}
              />
            )}
            {additionalApprover && (
              <ApproverInfoSection
                action={'additionalApproved'}
                approvalLabel={'Additional Approval By:'}
                approvalTime={recurringDisbursement.additional_approved_at}
                personText={additionalApprover && additionalApprover.email}
              />
            )}
          </div>

          <div style={{ width: '50%', paddingLeft: 20 }}>
            <div className="new-form-field">
              <div className="new-form__label">Recurring Disbursement ID:</div>
              <div className="new-form__data">{recurringDisbursement.id}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

DashboardRecurringDisbursementDetails.propTypes = {
  recurringDisbursement: recurringDisbursementShape.isRequired,
  statusFilter: PropTypes.string,
};

DashboardRecurringDisbursementDetails.childContextTypes = {
  client: PropTypes.object,
  togglePayeeForm: PropTypes.func,
};
