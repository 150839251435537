import makeStyles from '@mui/styles/makeStyles';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import _compact from 'underscore/modules/compact';
import _filter from 'underscore/modules/filter';
import _find from 'underscore/modules/find';
import _sortBy from 'underscore/modules/sortBy';
import DepositRow from './DepositRow';
import LoadingIndicator from 'react/shared/components/LoadingIndicator';
import Pagination from 'react/shared/components/forms/Pagination';

const useStyles = makeStyles(() => ({
  depositList: {
    maxWidth: '940px',
    fontSize: '18px',
    '.status-column': {
      width: '62px',
    },
    '& .date-column': {
      width: '105px',
    },
    '& .merchant-column': {
      width: '300px',
    },
    '& .location-column': {
      width: '148px',
    },
    '& .amount-column': {
      width: '120px',
    },
    '& .details-column': {
      width: '205px',
    },
  },
  depositListHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '20px',
    marginBottom: '20px',
  },
  searchInput: {
    '& input': {
      maxWidth: '175px',
      height: '40px',
      borderRadius: '20px',
      paddingLeft: '15px',
      marginBottom: '0px',
    },
  },
  zeroState: {
    padding: '50px 30px',
    textAlign: 'center',
  },
}));

export default function DepositList({ trustBeneficiaryId }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const [sortField, setSortField] = useState('deliveryDate');
  const [sortDirection, setSortDirection] = useState('desc');
  const [searchTerm, setSearchTerm] = useState('');
  const [page, setPage] = useState(1);
  const [pageSize] = useState(25);
  const [deposits, setDeposits] = useState([]);

  useEffect(() => {
    const fetchDeposits = () => {
      // We paginate and sort in memory so ask the server to send us back up to 300 deposits which, as of 11/20/2024, would cover each bene for 6 months
      // TODO: refactor our pagination, sorting and searching to be driven server side
      const url = `${RailsRoutes.api_v2_trust_beneficiary_deposits_path(trustBeneficiaryId)}?page[size]=300`;

      axios.get(url).then(({ data }) => {
        setDeposits(data.data);
        setLoading(false);
      });
    };

    fetchDeposits();
  }, [trustBeneficiaryId]);

  const queryDeposits = () => {
    // Defining the function here so that `searchTerm` is still within JS scope.
    const searchFilter = function (deposit) {
      // Early return if there isn't a search term. Would be ideal to not call this function in the first
      // place, but that makes our final _chain all the more complicated to generate
      if (searchTerm === undefined || searchTerm === '') {
        return true;
      }
      // Run this through _compact to get rid of falsy values
      const fields = _compact([
        deposit.attributes.merchant,
        String(deposit.attributes.amount?.amount),
      ]);
      return _find(fields, (field) => field.toLowerCase().includes(searchTerm.toLowerCase()));
    };

    const sortBySortField = (deposit) => deposit.attributes[sortField];

    const filteredDeposits = _filter(_sortBy(deposits, sortBySortField), searchFilter);

    if (sortDirection === 'desc') {
      filteredDeposits.reverse();
    }

    // We do not know the new page variables until we execute our query above, so calculate them now
    const total = filteredDeposits.length;

    // Finally, we need to return the page we're currently viewing
    const offset = (page - 1) * pageSize;

    return {
      filteredDeposits: filteredDeposits.slice(offset, offset + pageSize),
      total,
    };
  };

  const setSortMerchantAsc = () => {
    setSortField('merchant');
    setSortDirection('asc');
  };

  const setSortMerchantDesc = () => {
    setSortField('merchant');
    setSortDirection('desc');
  };

  const setSortDateAsc = () => {
    setSortField('deliveryDate');
    setSortDirection('asc');
  };

  const setSortDateDesc = () => {
    setSortField('deliveryDate');
    setSortDirection('desc');
  };

  const searchUpdated = (event) => {
    setSearchTerm(event.target.value);
  };

  const handlePageChange = (page) => {
    setPage(page);
  };

  const toggleDateSort = () => {
    if (sortDirection === 'desc') {
      setSortDateAsc();
    } else {
      setSortDateDesc();
    }
  };

  const toggleMerchantSort = () => {
    if (sortDirection === 'desc') {
      setSortMerchantAsc();
    } else {
      setSortMerchantDesc();
    }
  };

  const renderDepositRows = (deposits) =>
    deposits.map((deposit, index) => <DepositRow deposit={deposit} index={index} key={index} />);

  const getSortClass = (field) => {
    if (field === sortField) {
      if (sortDirection === 'desc') {
        return 'sorting_desc';
      }
      return 'sorting_asc';
    }
    return '';
  };

  if (loading) return <LoadingIndicator />;

  const dateSortClass = getSortClass('date');
  const merchantSortClass = getSortClass('status');

  const { filteredDeposits, total } = queryDeposits();

  return (
    <div className={classes.depositList}>
      <div className={classes.depositListHeader}>
        <div className={classes.searchInput}>
          <input onChange={searchUpdated} placeholder="Search" type="search" />
        </div>
      </div>

      <div className={classes.depositTable}>
        <table className="dataTable table table-hover" id="deposits_table">
          <thead>
            <tr role="row">
              <th className={`sorting ${dateSortClass} date-column`} onClick={toggleDateSort}>
                Process Date
              </th>
              <th
                className={`vertical-align-middle sorting ${merchantSortClass} status-column`}
                id="abc123"
                onClick={toggleMerchantSort}
              >
                Description
              </th>
              <th className="align-right cursor-default amount-column">Amount</th>
            </tr>
          </thead>
          {renderDepositRows(filteredDeposits)}
        </table>
        {filteredDeposits.length == 0 && (
          <div className={classes.zeroState}>No deposits to display</div>
        )}
        {filteredDeposits.length > 0 && (
          <Pagination
            itemsName="deposits"
            itemsTotal={total}
            onPageChange={handlePageChange}
            page={page}
            pageSize={pageSize}
          />
        )}
      </div>
    </div>
  );
}

DepositList.propTypes = {
  trustBeneficiaryId: PropTypes.string.isRequired,
};
