import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import TrueLinkTooltip from 'react/shared/components/true_link/main/TrueLinkTooltip';
import childrenShape from 'react/shared/shapes/children';
import PALETTE from 'react/shared/theme/palette';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function EffectiveBalancesTooltip({
  balanceInfo,
  children,
  organizationSlug,
  isPooled,
  showCashBalance,
}) {
  const getOrgUrl = RailsRoutes.api_v2_organization_path(organizationSlug);
  const [includeZeroDay, setIncludeZeroDay] = useState(false);

  useEffect(() => {
    // Pooled trusts will never enable zero day
    if (!isPooled) {
      axios
        .get(getOrgUrl, {
          params: {
            'fields[organization.analysis]': 'showZeroDayEffectiveBalance',
          },
        })
        .then(({ data }) => {
          setIncludeZeroDay(data.data.meta.showZeroDayEffectiveBalance);
        })
        .catch(() => {
          // Catch the error but do nothing
        });
    }
  }, [isPooled, getOrgUrl]);

  const descriptionText = {
    color: PALETTE.grey2,
    lineHeight: '26px',
  };

  const dividerStyle = {
    margin: '10px 0',
  };

  const typographyStyle = {
    fontFamily: '"Calibre", sans-serif',
    fontSize: '20px',
  };

  const effectiveBalanceLabel = showCashBalance ? 'Effective Cash Balance' : 'Effective Balance';

  const oneAndThreeMonthsBalance = (
    <>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography sx={{ ...typographyStyle }}>1 Month - {effectiveBalanceLabel} </Typography>
        <Typography sx={{ ...typographyStyle, display: 'inline' }}>
          {tlFieldTransformers.formatMoney(balanceInfo.oneMonth.effective_balance)}
        </Typography>
      </Box>
      <Divider sx={dividerStyle} />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
        <Typography sx={{ ...typographyStyle }}>3 Months - {effectiveBalanceLabel} </Typography>
        <Typography sx={{ ...typographyStyle, display: 'inline' }}>
          {tlFieldTransformers.formatMoney(balanceInfo.threeMonths.effective_balance)}
        </Typography>
      </Box>
      <Divider sx={dividerStyle} />
    </>
  );

  // Tooltip will never show zero-day balance for pooled trusts
  if (isPooled || !includeZeroDay) {
    return (
      <TrueLinkTooltip
        maxWidth="450px"
        tooltipContent={
          <>
            {oneAndThreeMonthsBalance}
            <Typography sx={{ ...typographyStyle, ...descriptionText }}>
              Your total {showCashBalance ? 'Cash Position' : 'account balance'} minus approved and
              processed disbursements.
            </Typography>
          </>
        }
      >
        {children}
      </TrueLinkTooltip>
    );
  }

  const oneAndThreeMonthsDescription = showCashBalance
    ? 'Your Cash Position minus one time disbursements of status approved and processed. If additional cash is needed, please reach out to us at trustsupport@truelinkfinancial.com'
    : 'Your total account balance minus one time disbursements of status approved and processed.';

  return (
    <TrueLinkTooltip
      maxWidth="450px"
      tooltipContent={
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
            <Typography sx={{ ...typographyStyle }}>Today's {effectiveBalanceLabel} </Typography>
            <Typography sx={{ ...typographyStyle, display: 'inline' }}>
              {tlFieldTransformers.formatMoney(balanceInfo.zeroDay.effective_balance)}
            </Typography>
          </Box>
          <Divider sx={dividerStyle} />
          <Typography sx={{ ...typographyStyle, ...descriptionText, marginBottom: '40px' }}>
            Your total {showCashBalance ? 'Cash Position' : 'account balance'} minus <em>only</em>{' '}
            processed disbursements.
          </Typography>
          {oneAndThreeMonthsBalance}
          <Typography sx={{ ...typographyStyle, ...descriptionText }}>
            {oneAndThreeMonthsDescription}
          </Typography>
        </>
      }
    >
      {children}
    </TrueLinkTooltip>
  );
}

EffectiveBalancesTooltip.propTypes = {
  balanceInfo: PropTypes.object.isRequired,
  children: childrenShape.isRequired,
  organizationSlug: PropTypes.string.isRequired,
  isPooled: PropTypes.bool,
  showCashBalance: PropTypes.bool,
};
