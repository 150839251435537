import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import PropTypes from 'prop-types';
import React from 'react';
import TrueLinkBanner from 'react/shared/components/true_link/lab/TrueLinkBanner';
import TrueLinkAppStoreButton from 'react/shared/components/true_link/main/TrueLinkAppStoreButton';
import TrueLinkLink from 'react/shared/components/true_link/main/TrueLinkLink';

const mobileCTAButtons = (
  <div
    style={{
      display: 'flex',
      width: '260px',
      height: '50px',
      paddingTop: '35px',
      alignItems: 'center',
    }}
  >
    <TrueLinkAppStoreButton
      linkProps={{ title: 'Apple Store Button' }}
      store="ios"
      style={{ height: '75px', width: '200px' }}
    />
    <TrueLinkAppStoreButton
      linkProps={{ title: 'Google Play Store Button' }}
      store="android"
      style={{ height: '75px', width: '200px', marginLeft: '5px' }}
    />
  </div>
);

const receiptsCTAButtons = (
  <div>
    <TrueLinkLink
      className="info"
      href="https://www.truelinkfinancial.com/receipts"
      id="ctaReceiptsActivate"
      target="_blank"
    >
      Activate
    </TrueLinkLink>
    <TrueLinkLink
      className="infoOutline"
      href="https://www.truelinkfinancial.com/receipts"
      id="ctaReceiptsLearnMore"
      target="_blank"
    >
      Learn More
    </TrueLinkLink>
  </div>
);

const PROMOTIONS = {
  MOBILE_APP: {
    callToActionElement: mobileCTAButtons,
    icon: 'mobile-screen',
    text: 'Check your balance, transactions, and upcoming transfers on the go!',
    title: 'Download the True Link mobile app today',
    variant: 'info',
  },
  RECEIPTS: {
    callToActionElement: receiptsCTAButtons,
    icon: 'paperclip',
    text: 'Save time on collecting receipts: Cardholders can now upload receipts to transactions straight from their App!',
    title: 'Receipt upload is now available',
    variant: 'info',
    context: 'transactionsTab',
    dismissable: true,
  },
};

const getPromotionBanner = (promo, index, context) => {
  const promoCode = promo.attributes.code.toUpperCase();
  const currentPromo = PROMOTIONS[promoCode];
  if (!currentPromo || (currentPromo.context && context !== currentPromo.context)) {
    return null;
  }

  return (
    <TrueLinkBanner callToAction={currentPromo.callToActionElement} key={index} {...currentPromo} />
  );
};

export default function Promotions({ context }) {
  const { data: promotions } = useQuery({
    queryKey: ['referral_promos'],
    queryFn: async () => {
      const response = await axios.get(RailsRoutes.api_v2_promotions_path());
      const filteredPromotions = response?.data?.data.filter(
        (promo) => promo.attributes.code.toUpperCase() in PROMOTIONS,
      );
      return filteredPromotions;
    },
  });

  return (
    <div>
      {promotions && (
        <div style={{ marginBottom: 15 }}>
          {promotions.map((promo, index) => getPromotionBanner(promo, index, context))}
        </div>
      )}
    </div>
  );
}

Promotions.propTypes = {
  context: PropTypes.string,
};
