import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import EffectiveBalancesTooltip from 'react/shared/components/tooltips/EffectiveBalancesTooltip';
import PALETTE from 'react/shared/theme/palette';
import tlFieldTransformers from 'react/shared/utils/tl_field_transformers';

export default function WmBalances({ balanceInfo, isPooled, organizationSlug }) {
  const showEffectiveBalancesTooltip = $tlf.flipperEnableEffectiveBalancesTooltip;

  return (
    <div className="trust-balance-wrapper">
      <div className="trust-balance-wrapper__top">
        {showEffectiveBalancesTooltip ? (
          <EffectiveBalancesTooltip
            balanceInfo={balanceInfo}
            isPooled={isPooled}
            organizationSlug={organizationSlug}
          >
            <Typography
              sx={{
                display: 'inline',
                fontWeight: 400,
                fontSize: '16px',
                paddingRight: '5px',
                borderBottom: `1px dashed ${PALETTE.grey3}`,
              }}
            >
              EFFECTIVE BALANCE:
            </Typography>
          </EffectiveBalancesTooltip>
        ) : (
          <span className="trust-balance-label"> EFFECTIVE BALANCE:</span>
        )}{' '}
        {tlFieldTransformers.formatMoney(balanceInfo.threeMonths.effective_balance)}
      </div>
      <div className="trust-balance-wrapper__bottom">
        <span className="trust-balance-label">TOTAL BALANCE:</span>{' '}
        {tlFieldTransformers.formatMoney(balanceInfo.threeMonths.current_balance)}
      </div>
    </div>
  );
}

WmBalances.propTypes = {
  balanceInfo: PropTypes.object.isRequired,
  isPooled: PropTypes.bool,
  organizationSlug: PropTypes.string.isRequired,
};
